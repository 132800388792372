import { combineReducers } from "redux";
import auth from "./auth";
import dropzone from "./dropzone";
import errors from "./errors";
import messages from "./messages";
import orders from "./orders";
import resultsdetailed from "./resultsdetailed";
import myforest from "./myforest";
import userpage from "./userpage";
import admin from './admin';

export default combineReducers({
  orders,
  errors,
  messages,
  auth,
  dropzone,
  resultsdetailed,
  myforest,
  userpage,
  admin,
});
