import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function DeleteForestModal({
  showDeleteUserFromOrgModal,
  handleDeleteUserFromOrgClose,
  handleDeleteUserFromOrgConfirm,
  user,
  group,
  }) {
  return (
    <Modal
      show={showDeleteUserFromOrgModal}
      onHide={handleDeleteUserFromOrgClose}
      backdrop="static"
      centered
      scrollable
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete {user.given_name} {user.family_name} from the group {group}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleDeleteUserFromOrgClose}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={handleDeleteUserFromOrgConfirm} 
          >
          Confirm and Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

