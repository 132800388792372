import axios from "axios";
import { tokenConfig } from "./auth";
import {
  DELETE_IMAGE,
  UPDATE_IMAGES,
  DELETE_ALL_IMAGES,
  GET_ALL_IMAGES,
  ADD_IMAGE,
  ADD_COORDS,
} from "./types";

const ip = process.env.REACT_APP_BACKEND_DNS;

// DELETE ALL IMAGES IN REDUX
export const deleteAllImages = () => (dispatch) => {
  dispatch({
    type: DELETE_ALL_IMAGES,
  });
};

// GET ALL IMAGES AVAILABLE IN REDUX
export const getImages = () => (dispatch) => {
  dispatch({
    type: GET_ALL_IMAGES,
  });
};

// DELETE IMAGE
export const deleteImage = (id) => (dispatch) => {
  dispatch({
    type: DELETE_IMAGE,
    payload: id,
  });
};

// UPDATE images
export const updateImages = (images) => (dispatch) => {
  dispatch({
    type: UPDATE_IMAGES,
    payload: images,
  });
};

// ADD IMAGE
export const addImage = (image) => (dispatch) => {
  dispatch({
    type: ADD_IMAGE,
    payload: image,
  });
};

// ADD Coords 
export const addCoords = (coords) => (dispatch) => {
  dispatch({
    type: ADD_COORDS,
    payload: coords,
  });
};

export const getElevation = (coords) => async (getState) => {
  // let baseUrl = 'https://api.open-elevation.com/api/v1/lookup?locations='
  let baseUrl = `${ip}/elevation/`;
  let url = baseUrl + '?locations=';
  // console.log(coords)
  for (const index in coords) {
    let coord = coords[index]
    // console.log(coord)
    url = url + coord['lat'] + ',' + coord['long']
    if (index < coords.length - 1 ) {
      url = url + '%7C'
    }
  }
  // console.log(url)
  // let url = baseUrl + coords['lat'] + ',' + coords['lng'];
  // let url = baseUrl;
  return await axios
    .get(url, tokenConfig(getState))
    .then((res) => {
      let elevation;
      try{
        elevation = res.data;
      } catch{
        elevation = 0;
      };
      return elevation;
    })
    .catch((e) => {
      console.log(e)
    })
}