import React, { Component } from "react";
import css from "../../styles/LandingPage.module.css";
import { Link } from "react-router-dom";

class LandingPage extends Component {
  render() {
    return (
      <div className={css.mainContent}>
        <IntroImage />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default LandingPage;

class IntroImage extends Component {
  render() {
    return (
        <div className={css.header}>
            <div className={css.headerContainer}>
                <h1>Welcome to Arboair Portal</h1>
            </div>
        </div>
    );
  }
}

class Content extends Component {
  render() {
    return (
            <>
                <div className={css.bodyContent}>
                    <div className={css.instructContainer}>
                        <h4>How It Works</h4>
                        <div className={css.heroLine}></div>
                        <p>Welcome to Arboair MyForest! You will be ready to go in just four steps.</p>
                    </div>

                    <div className={css.tuts}>
                        <img alt='' src={require("../../images/startSearchForest.png")}></img>
                        <div className={css.instructContainer}>
                            <i className="fas fa-search-location fa-2x icon"
                            style={{paddingTop: '3rem'}}></i>
                            <h4>Find your forest</h4>
                            <div className={css.line} />
                            <p>Lets start by locating your forest on the map.</p>
                        </div>
                    </div>

                    <div className={css.tuts}>
                        <div className={css.instructContainer}>
                            <div className={css.v1}>
                                <i className="fas fa-tools fa-2x icon"
                                style={{paddingTop: '3rem'}}></i>
                                <h4>Use the shape tool</h4>
                                <div className={css.line} />
                                <p>Create your forest either by drawing your forest on the map, or by uploading a shapefile containing the boundry of your forest. You can only create one forest per time.</p>
                            </div>
                        </div>
                        <img src={require("../../images/startDrawForest.png")} 
                        alt="bg-placeholder2"></img>
                        <div className={css.instructContainer}>
                            <div className={css.v2}>
                                <i className="fas fa-tools fa-2x icon"
                                style={{paddingTop: '3rem'}}></i>
                                <h4>Use the shape tool</h4>
                                <div className={css.line} />
                                <p>Create your forest either by drawing your forest on the map, or by uploading a shapefile containing the boundry of your forest. You can only create one forest per time.</p>
                            </div>
                        </div>
                    </div>

                    <div className={css.tuts}>
                        <img src={require("../../images/startSaveForest.png")} 
                        alt="bg-placeholder2"></img>
                        <div className={css.instructContainer}>
                            <i className="fas fa-share-square fa-2x"
                            style={{paddingTop: '3rem'}}></i>
                            <h4>Save forest</h4>
                            <div className={css.line} />
                            <p>After you have drawn, uploaded or found your forest in the system do not forget to save it. Once it is saved you can share your forest with your family, a collegue, a forest inspector or others you might trust your forest with.</p>
                        </div>
                    </div>

                    <div className={css.tuts}>
                        <div className={css.instructContainer}>
                            <div className={css.v1}>
                                <i className="fas fa-tools fa-2x icon"
                                style={{paddingTop: '3rem'}}></i>
                                <h4>Order analysis</h4>
                                <div className={css.line} />
                                <p>From Myforest you can order an analysis, either by pressing the button "order analysis" and order a drone pilote and analysis. Or you can just upload your images directly by pressing the upload button.</p>
                            </div>
                        </div>
                        <img src={require("../../images/startOrder.png")}
                        alt="bg-placeholder2"></img>
                        <div className={css.instructContainer}>
                            <div className={css.v2}>
                                <i className="fas fa-tools fa-2x icon"
                                style={{paddingTop: '3rem'}}></i>
                                <h4>Order Analysis</h4>
                                <div className={css.line} />
                                <p>From Myforest you can order an analysis, either by pressing the button "order analysis" and order a drone pilote. Or you can just upload your images directly by pressing the upload button.</p>
                            </div>
                        </div>
                    </div>


                    <div className={css.instructContainer}>
                        <p style={{paddingTop: '3rem', paddingBottom: 0}}>Add your own forest now!</p>
                    </div>

                    <Link to="/my-forests"
                    className={css.addForestButton}>
                        <h4>Go to My Forest</h4>
                    </Link>
            </div>
        </>
    );
  }
}

class Footer extends Component {
  render() {
    return (    
        <footer className={css.footerContainer}>
            <p>More information about Arboair can be found at 
                <a href="/"> Arboair.com</a>
                <br/>
                Copyright 2021. All Rights Reserved.</p>
        </footer>
    );
  }
}