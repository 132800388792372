import React, {
  useState,
  useEffect,
} from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import {getUserProfile} from "../../actions/userpage"
import SlideNav from '../layout/SlideNav';
// import { getCookie } from '../../actions/auth'
// import { Spinner } from "react-bootstrap";
// import useScript from '../hooks/useScript'
// const loggedIn = getCookie('loggedIn');
const loggedIn = localStorage.getItem('id');

const PrivateRoute = ({ component: Component, auth, getUserProfile, ...rest }) => {
  
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    getUserProfile().then((result) => {
        if (result.is_org_admin) {
            console.log("is admin")
            setIsAdmin(true);
          }
    })
  }, [getUserProfile])
  return (
  <>
  <SlideNav />
  <Route
    {...rest}
    render={(props) => {
      
      // console.log(loggedIn)
      // console.log(auth)
      if (auth.isLoading) {
        // console.log('Is Loading')
        return <h2>Loading...</h2>;
      } else if (auth.isAuthenticated === null && loggedIn === null) {
        console.log('To Login')
        let redirectUrl = `/login?returnUrl=${window.location.pathname}`
        return <Redirect to={redirectUrl} />;
      } else if (auth.user !== null) {
        if (props.match.path === "/admin") {
          if (isAdmin) {
            return <Component {...props} />;
          } else {
            return <rest.notFoundPage></rest.notFoundPage>
          }
        }
        return <Component {...props} />;
      }

      }
    }
  />
  </>
  )
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getUserProfile,
})(PrivateRoute);
