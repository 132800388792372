import React, {
  useEffect,
  // Component,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
// import styled from "styled-components";
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Nav } from 'react-bootstrap'
import * as AiIcons from 'react-icons/ai'
import * as FaIcons from 'react-icons/fa'
import * as RiIcons from 'react-icons/ri'
import { connect } from 'react-redux'
import { loadUser, logout } from '../../actions/auth'
import { getUserProfile } from '../../actions/userpage'
import css from '../../styles/layout/SlideNav.module.css'
// import * as GiIcons from "react-icons/gi";
import * as BsIcons from 'react-icons/bs'
import * as CgIcons from 'react-icons/cg'
import * as HiIcons from 'react-icons/hi'

import { getGroupsForUser, getRootGroup } from '../../actions/admin'

function SlideNav({
  auth,
  logout,
  loadUser,
  getUserProfile,
  getRootGroup,
  getGroupsForUser,
}) {
  const [sidebar, setSidebar] = useState(false)
  const {
    isAuthenticated,
    // user
  } = auth
  const [, setUsername] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)

  const onLogoutClicked = () => {
    logout()
  }

  useEffect(() => {
    getUserProfile().then((result) => {
      if (result.is_org_admin) {
        setIsAdmin(true)
      }
    })
    let payload = auth.user
    getRootGroup()
    getGroupsForUser()

    if (payload) {
      setUsername(payload['given_name'])
    } else {
      loadUser(window.location.pathname)
    }
  }, [auth.user, loadUser, getUserProfile, getRootGroup, getGroupsForUser])

  const showSideBar = () => setSidebar(!sidebar)
  return (
    <div className={css.topbar}>
      {isAuthenticated ? (
        <>
          {/* SlideNav  */}
          <nav className={css.wrapper}>
            <Link to='#'>
              <FaIcons.FaBars
                className={css.burgerIcon}
                onClick={showSideBar}
              />
            </Link>
          </nav>

          {/* Sidebar */}
          <nav className={sidebar ? css.navMenuActive : css.navMenu}>
            <ul className={css.navMenuItems} onClick={showSideBar}>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <li className={css.navbarToggle}>
                    <Link to='#' className={css.menuBarClose}>
                      <i className='fas fa-times'></i>
                    </Link>
                  </li>
                  <div className={css.profileContainer}>
                    <FontAwesomeIcon icon={faUser} size='3x' />
                    {/* <img
                      src={profilepicture}
                      className={css.profileAvatar}
                      alt='avatar'
                    /> */}
                    {/* <p className={css.profileName}>{username}</p> */}
                    <p className='mt-3'>
                      <Nav.Link href={'/profile'} className={css.myProfile}>
                        My Profile
                      </Nav.Link>
                    </p>
                  </div>
                  <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                    <hr
                      style={{ borderTop: '0.1px solid rgba(255,255,255,0.2)' }}
                    />
                  </div>

                  {SidebarData.map((item, index) => {
                    // Only show admin panel if the user is a group admin
                    if (item.title === 'Admin') {
                      if (!isAdmin) {
                        return ''
                      }
                    }
                    return (
                      <li key={index} className={css.navText}>
                        <Nav.Link href={item.path} target={item.target}>
                          {item.icon}
                          <span>{item.title}</span>
                        </Nav.Link>
                      </li>
                    )
                  }).filter(Boolean)}
                </div>
                <div>
                  <li key={10} className={css.navText}>
                    <Nav.Link onClick={onLogoutClicked}>
                      {<CgIcons.CgLogOut />}
                      <span>{'Sign Out'}</span>
                    </Nav.Link>
                  </li>
                </div>
              </div>
            </ul>
          </nav>
          {sidebar ? <div className={css.overlay} /> : ''}
        </>
      ) : (
        ''
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, {
  logout,
  loadUser,
  getUserProfile,
  getRootGroup,
  getGroupsForUser,
})(SlideNav)

const SidebarData = [
  // {
  //   title: "Start",
  //   path: "/start",
  //   icon: <AiIcons.AiFillHome />,
  // },
  {
    title: 'Forests',
    path: '/my-forests',
    icon: <FaIcons.FaTree />,
    target: '_self',
  },
  {
    title: 'Past Orders',
    path: '/results',
    icon: <RiIcons.RiPieChartLine />,
    target: '_self',
  },
  {
    title: 'Knowledge Hub',
    path: 'https://knowledge.arboair.com',
    icon: <AiIcons.AiOutlineInfoCircle />,
    target: '_blank',
  },
  {
    title: 'Admin',
    path: '/Admin',
    icon: <RiIcons.RiPieChartLine />,
    target: '_self',
  },
  // {
  //   title: "Drones",
  //   path: "/sup_drones",
  //   icon: <GiIcons.GiDeliveryDrone />,
  // },
  // {
  //   title: "About",
  //   path: "/about",
  //   icon: <AiIcons.AiOutlineInfoCircle />,
  // },
  {
    title: 'Terms and conditions',
    path: 'https://www.arboair.com/terms/',
    icon: <HiIcons.HiOutlineDocumentText />,
    target: '_blank',
  },
  {
    title: 'Privacy policy',
    path: 'https://www.arboair.com/privacy/',
    icon: <BsIcons.BsShieldLock />,
    target: '_blank',
  },
]
