import React, {useState} from "react";
import { Modal, Button } from "react-bootstrap";

export default function DeleteForestModal({
  showChangeUserInOrgModal,
  handleChangeUserInOrgClose,
  handleChangeUserInOrgConfirm,
  user,
  // group,
  role
  }) {

  // console.log(user, role)
  const [newRole, setNewRole] = useState(role);

  const roles = [
    'admin', 'user', 'external_viewer', 'internal_viewer'
  ];

  // const defaultOption = role;
  
  // let new_role;

  // const = options {

  const handleRadioChange = (e) => {
    let value = e.target.value;
    setNewRole(value);
  }
  // console.log('Dragons')

  const radioButtons = roles.map(function(key) {
    // console.log(key)

    return(
      <label>
      <input
          type="radio"
          value={key}
          checked={newRole === key}
          onChange={handleRadioChange}
      />
      {key}
    </label>
    )
  });

  return (
    <Modal
      show={showChangeUserInOrgModal}
      onHide={handleChangeUserInOrgClose}
      backdrop="static"
      centered
      scrollable
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Role of user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {radioButtons}
        <p>
          Are you sure you want to change the role of {user.hr_username}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleChangeUserInOrgClose}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={() => handleChangeUserInOrgConfirm(newRole)}
          >
          Update Role
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

