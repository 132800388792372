export const headerNames = {
  "my-forests": "myForests",
  upload: "Upload Images",
  sup_drones: "Supported Drones",
  results: "Your Analyses",
  about: "About",
};

export const stageNames = {
  0: "Waiting for upload",
  1: "Uploading images",
  2: "Creating mosaic",
  3: "",
  4: "Analysing health",
  5: "Finished",
};

export const legendNames = {
  1: "Ground (Mark)",
  2: "Spruce (Gran)",
  3: "Dead Spruce (Död Gran)",
  4: "Leaf Tree (Lövträd)",
  5: "Pine (Tall)",
  6: "Stressed Spruce (Stressad Gran)",
  7: "Damaged Spruce (Skadad Gran)",
};

export const legendColors = {
  1: "#9e9e9e",
  2: "#009113",
  3: "#bd0fe0",
  4: "#850035",
  5: "#e6e69c",
  6: "#0314a8",
  7: "#de001a",
};

export const stressedNames = {
  High_certain: "High certainty stressed",
  Low_certain: "Low certainty stressed",
};

export const stressedColors = {
  High_certain: "#000080",
  Low_certain: "#00FFFF",
};

export const supportedDrones = {
  "Hasselblad L1D-20c": 13.2,
  "DJI FC6360": 4.96,
  "DJI FC7303": 6.3,
  "DJI FC2204": 6.17,
  "DJI FC220": 6.17,
  "DJI FC300C": 6.17,
  "DJI FC300S": 6.17,
  "SONY DSC-WX220": 6.17,
  "DJI FC6310": 13.2,
  "DJI FC6520": 17.3,
  "DJI FC3170": 6.4,
  "DJI FC330": 6.17,
  "DJI ZenmuseP1": 35.9,
};

export const pricePerHectar = 40;

export const maxImages = 600;

export const maxHectars = 200;

export const ip = process.env.REACT_APP_BACKEND_DNS;
