// any actions are going to be here
import axios from "axios";
import { tokenConfig } from "./auth";

import {
  GET_ORDERS,
  DELETE_ORDER,
  ADD_IMAGES,
  FINISH_IMAGE_UPLOAD,
  GET_ORDER,
  ADD_IMAGES_FINSHED,
  UPLOADED_IMAGE,
  ADD_NEW_ORDER,
  ADD_NEW_ORDER_FAILED,
  CHANGE_PAGE,
  GET_ZERO_ORDERS,
  SHARING_LINK,
  REMOVE_SHARING_LINK,
  LINK_ACTIVATED,
  GET_FAILED_ORDERS,
  DELETE_FAILED_ORDERIMAGE,
  DEBUG,
  SUPPORTED_DRONES,
  EMAIL_NOT_VALID,
} from "./types";

const ip = process.env.REACT_APP_BACKEND_DNS;

function convertUTCDateToLocalDate(date) {
  let dateLocal = new Date(date);
  let newDate = new Date(
    dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000
  );
  return newDate.toISOString();
}

// GET ORDERS
export const getOrders = () => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/orders/?opp=${10000}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ORDERS,
        payload: res.data["orders"],
      });
      return res.data["orders"];
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

// GET zero ORDERS
export const getZeroOrders = () => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/zero_orders/`, tokenConfig(getState))
    .then((res) => {
      var arr = Array.prototype.map.call(res.data, function (el, i) {
        /* If the name of analysis is valid display that, otherwise display the date created */
        var display_name;
        var correct_time = convertUTCDateToLocalDate(el.date_created);

        el.analysis_name === "" || el.analysis_name === "Empty"
          ? (display_name = correct_time
              .split(".")[0]
              .split("T")
              .join(" ")
              .slice(0, -3))
          : (display_name = el.analysis_name);

        return [el.id, display_name, el.forest];
      });
      dispatch({
        type: GET_ZERO_ORDERS,
        payload: arr,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

// GET Failed ORDERS
export const getFailedOrders = () => async (dispatch, getState) => {
  //console.log("get failed orders");
  return await axios
    .get(`${ip}/order_failed/`, tokenConfig(getState))
    .then((res) => {
      var arr = Array.prototype.map.call(res.data, function (el, i) {
        /* If the name of analysis is valid display that, otherwise display the date created */
        var display_name;
        var correct_time = convertUTCDateToLocalDate(el.date_created);

        el.analysis_name === "" ||
        el.analysis_name === "Empty" ||
        el.analysis_name === null
          ? (display_name = correct_time
              .split(".")[0]
              .split("T")
              .join(" ")
              .slice(0, -3))
          : (display_name = el.analysis_name);

        return [el.id, display_name, el.forest];
      });
      dispatch({
        type: GET_FAILED_ORDERS,
        payload: arr,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

//DELETE images in failed ORDER
export const deleteFailedOrderImages =
  (orderID) => async (dispatch, getState) => {
    //console.log("remove failed images");
    return await axios
      .delete(`${ip}/order_failed/?order=${orderID}`, tokenConfig(getState), {
        data: orderID,
      })
      .then((res) => {
        //console.log(res.data);
        dispatch({
          type: DELETE_FAILED_ORDERIMAGE,
          payload: res.data,
        });
        return res;
      })
      .catch((err) => {
        console.log(err, ip);
        return err;
      });
  };

// finish order
export const finishOrder = (id) => async (dispatch, getState) => {
  //console.log("Fininshing order");
  return await axios
    .post(`${ip}/order_uploaded/`, id, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_IMAGES_FINSHED,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

// SEE DETAILED ORDER and its images
export const getOrder = (id) => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/orders/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ORDER,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err.data;
    });
};

// DELETE ORDER
export const deleteOrder = (id) => async (dispatch, getState) => {
  return await axios
    .delete(`${ip}/orders/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_ORDER,
        payload: id,
      });
      return res;
    })
    .catch((err) => {
      // console.log(err)
      // return err;
    });
};

// NEW ADD ORDER that only creates a new order
export const addNewOrder =
  (
    forestID,
    analysisName,
    area,
    orderType,
    exceed,
    shapeType = null,
    shapefile = null
  ) =>
  async (dispatch, getState) => {
    //console.log("Add new order");
    let formData = new FormData();
    formData.append("forest", forestID);
    formData.append("analysis_name", analysisName);
    formData.append("hectars", area);
    formData.append("order_type", parseInt(orderType));
    formData.append("exceed", exceed);
    formData.append("shapeType", shapeType);
    return await axios
      .post(`${ip}/api/v1/orders/`, formData, tokenConfig(getState))
      .then((res) => {
        // console.log(res.data)
        if (res.status === 402) {
          dispatch({
            type: ADD_NEW_ORDER_FAILED,
          });
          return res;
        } else {
          if (res.data["url"] !== null) {
            let uploadUrl = res.data["url"];
            axios.put(uploadUrl, shapefile);
          }
          dispatch({
            type: ADD_NEW_ORDER,
            payload: res.data["serializer_data"].id,
          });
          return res;
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: ADD_NEW_ORDER_FAILED,
        });
        return error;
      });
  };

// OLD ADD ORDER for debugging
// export const addOrder = (order, num_of_images) => (dispatch, getState) => {
//   //console.log("add Order");
//   //console.log(order);
//   axios
//     .post(`${ip}/orders/`, order, tokenConfig(getState))
//     .then((res) => {
//       dispatch({
//         type: ADD_IMAGES,
//         payload: [res.data.id, num_of_images],
//       });
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

// ADD ORDER and return order id
export const addImages =
  (order_id, num_of_images) => async (dispatch, getState) => {
    //console.log('Add Images')
    dispatch({
      type: ADD_IMAGES,
      payload: [order_id, num_of_images],
    });
    return true;
  };

// ADD Image to order
export const addOrderImage =
  (order, image, uploadRef, progressRef, barRef, uploadModalRef) =>
  async (dispatch, getState) => {
    //console.log("Add order image");
    return await axios
      .post(`${ip}/photo_upload/`, order, tokenConfig(getState))
      .then(async (res) => {
        // console.log(res)
        let s3Url = res.data;
        return await axios.put(s3Url, image).then((res) => {
          // console.log(res);
          dispatch({
            type: UPLOADED_IMAGE,
          });
          const uploadPercentage = Math.floor(
            ((getState().orders.tot_images -
              getState().orders.images_to_upload) /
              getState().orders.tot_images) *
              100
          );
          progressRef.current.innerHTML = `${uploadPercentage}%`;
          barRef.current.style.width = `${uploadPercentage}%`;
          uploadRef.current.innerHTML = `File Uploaded: ${
            getState().orders.tot_images - getState().orders.images_to_upload
          }/${getState().orders.tot_images}`;
          if (getState().orders.images_to_upload === 0) {
            uploadRef.current.innerHTML = "File Upload Completed!";
            uploadModalRef.current.style.display = "none";
            barRef.current.style.display = "none";
            progressRef.current.style.display = "none";
            dispatch({
              type: FINISH_IMAGE_UPLOAD,
            });
          }
        });
      })
      .catch((err) => {
        // If error, display a message on the upload modal
        uploadRef.current.innerHTML = `<span class="error">Error Uploading File(s)</span>`;
        // set progress bar background color to red
        progressRef.current.style.backgroundColor = "red";
        return err;
      });
  };

export const addOrderImages =
  (formData, images, uploadRef, progressRef, barRef, uploadModalRef) =>
  async (dispatch, getState) => {
    //console.log("Add order image");
    return await axios
      .post(`${ip}/photo_upload/`, formData, tokenConfig(getState))
      .then(async (res) => {
        // console.log(res)
        let s3Urls = res.data;
        for (const index in s3Urls) {
          let s3Url = s3Urls[index];
          let image = images[index];
          axios.put(s3Url, image).then((res) => {
            // console.log(res);
            dispatch({
              type: UPLOADED_IMAGE,
            });
            const uploadPercentage = Math.floor(
              ((getState().orders.tot_images -
                getState().orders.images_to_upload) /
                getState().orders.tot_images) *
                100
            );
            progressRef.current.innerHTML = `${uploadPercentage}%`;
            barRef.current.style.width = `${uploadPercentage}%`;
            uploadRef.current.innerHTML = `File Uploaded: ${
              getState().orders.tot_images - getState().orders.images_to_upload
            }/${getState().orders.tot_images}`;
            if (getState().orders.images_to_upload === 0) {
              uploadRef.current.innerHTML = "File Upload Completed!";
              uploadModalRef.current.style.display = "none";
              barRef.current.style.display = "none";
              progressRef.current.style.display = "none";
              dispatch({
                type: FINISH_IMAGE_UPLOAD,
              });
            }
          });
        }
      })
      .catch((err) => {
        // If error, display a message on the upload modal
        uploadRef.current.innerHTML = `<span class="error">Error Uploading File(s)</span>`;
        // set progress bar background color to red
        progressRef.current.style.backgroundColor = "red";
        return err;
      });
  };

// Create sharing link for a forest or a result
export const createSharingLink = (formData) => async (dispatch, getState) => {
  return await axios
    .post(`${ip}/link/`, formData, tokenConfig(getState))
    .then((res) => {
      //console.log(res.data);
      dispatch({
        type: SHARING_LINK,
        payload: [res.data.id, res.data.object_type],
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const sendEmailShare =
  (uuid, formData) => async (dispatch, getState) => {
    return await axios
      .post(`${ip}/link/${uuid}/`, formData, tokenConfig(getState))
      .then((res) => {
        console.log(res);
        dispatch({
          type: EMAIL_NOT_VALID,
          payload: null,
        });
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: EMAIL_NOT_VALID,
          payload: err.payload,
        });
      });
  };

// Create sharing link for a forest or a result
export const removeSharingLink = () => (dispatch) => {
  dispatch({
    type: REMOVE_SHARING_LINK,
  });
};

// Activate the given link
export const activateLink = (objectID) => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/link/${objectID}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: LINK_ACTIVATED,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: err["type"],
      });
      return null;
    });
};

// CHANGE page in order list
export const changePage =
  (pageNum, method, acs, opp, skey, urlFilterParams) =>
  async (dispatch, getState) => {
    // console.log(pageNum, method, acs, opp, skey, urlFilterParams)
    return await axios
      .get(
        `${ip}/orders/?method=${method}&acs=${acs}&page=${
          pageNum + 1
        }&opp=${opp}&skey=${skey}&${urlFilterParams}`,
        tokenConfig(getState)
      )
      .then((res) => {
        let pageNumber = pageNum >= res.data["num_pages"] ? 0 : pageNum;
        dispatch({
          type: CHANGE_PAGE,
          payload: [
            pageNumber,
            res.data["orders"],
            res.data["num_pages"],
            opp,
            method,
            acs,
            skey,
          ],
        });
        return res;
      })
      .catch((err) => {
        console.log("Failed to fetch a page");
        console.log(err);
        return err;
      });
  };

export const getSupportedDrones = () => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/supported_drones/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: SUPPORTED_DRONES,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const addUserInputToUpload =
  (orderID, inputData) => async (getState) => {
    return await axios
      .put(`${ip}/api/v1/orders/${orderID}/`, inputData, tokenConfig(getState))
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

export const debugPOST = (formData) => async (dispatch, getState) => {
  return await axios
    .post(`${ip}/debug/`, formData, tokenConfig(getState))
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: DEBUG,
        // payload: [res.data.id, res.data.object_type],
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const debugGET = () => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/debug/`, tokenConfig(getState))
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: DEBUG,
        // payload: [res.data.id, res.data.object_type],
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};
