// Packages imports
import React, { Component, useState } from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Local components imports
//import WebMapView from "./LeafletMapResults";
import LeafletMapResults from "./LeafletMapResults";

// Store imports
import {
  sendErrorNotification,
  sendNotification,
} from "../../actions/notifications";
import { getOrder } from "../../actions/orders";
import {
  getKMLLink,
  getMosaicLink,
  getReportLink,
  getShpLink,
  getTreeCountLink,
} from "../../actions/resultsdetailed";

// css imports
import "react-tabs/style/react-tabs.css";
import css from "../../styles/MapSubPage.module.css";

//icons
import * as BiIcons from "react-icons/bi";
import * as FaIcons from "react-icons/fa";

const ResultsDetailed = withRouter((props) => (
  <ResultsDetailedHelper {...props} />
));

class ResultsDetailedHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia("(min-width: 768px)").matches,
      analysisID: this.props.match.params.id,
      order: "",
      shp_options: [
        { name: "All layers", id: 1, opt: null },
        { name: "Ground (Mark)", id: 2, opt: "_ground" },
        { name: "Spruce (Gran)", id: 3, opt: "_spruce" },
        { name: "Dead Spruce (Död Gran)", id: 4, opt: "_dead" },
        { name: "Leaf Tree (Lövträd)", id: 5, opt: "_leaf" },
        { name: "Pine (Tall)", id: 6, opt: "_pine" },
        { name: "Stressed Spruce (Stressad gran)", id: 7, opt: "_stressed" },
        { name: "Damaged Spruce (Skadad gran)", id: 8, opt: "_damaged" },
      ],
    };
  }
  goBack = () => {
    this.props.history.goBack();
  };

  componentDidMount = async () => {
    // this.state.order = this.props.getOrder(this.state.analysisID);
    let orderDetail = await this.props.getOrder(this.state.analysisID);
    if (orderDetail.status === 200) {
      this.setState({
        order: orderDetail,
      });
    } else {
      this.setState({
        order: orderDetail.response,
      });
    }
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
  };

  render() {
    return (
      <>
        {this.state.order.status === 200 ? (
          <div className={css.mainFrame}>
            <MapWrapper
              {...this.props}
              state={this.state}
              order={this.state.analysisID}
            >
              <NavBar {...this.props} state={this.state} goBack={this.goBack} />
            </MapWrapper>
          </div>
        ) : (
          <>
            {this.state.order !== undefined &&
            this.state.order !== null &&
            this.state.order !== "" ? (
              <div className={css.mainFrame}>
                <h2 style={{ margin: "0 auto" }}>
                  You do not have access to this order, please contact the owner
                  for a sharing link!
                  <br />
                  Owners contact email:{" "}
                  <a href={"mailto:" + this.state.order.data.email}>
                    {this.state.order.data.email}
                  </a>
                </h2>
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </>
    );
  }
}

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file_options: [
        { name: "Mosaic", id: 2 },
        // { name: "KML", id: 3 },
        // { name: "PDF Report", id: 4 },
        { name: "Tree Count", id: 5 },
      ],
      selectedValue: "",
    };
  }

  funcArray = {
    1: [this.props.getShpLink, 0],
    2: [this.props.getMosaicLink, 0],
    3: [this.props.getKMLLink, 0],
    4: [this.props.getReportLink, 0],
    5: [this.props.getTreeCountLink, 0],
  };

  fileDownloader = (orderDetail, type, opt = null) => {
    var func = this.funcArray[type];
    let orderID = orderDetail.id;
    let name = "";
    if (orderDetail.analysis_name !== "") {
      name = orderDetail.analysis_name + "_";
    }
    let today = new Date();
    let date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    name = name + date + "_";
    // console.log(type, opt)
    func[0](orderID, name, opt).then((res) => {
      if (res.isAxiosError) {
        console.log(res, res.status);
        this.props.sendErrorNotification("File could not be found");
      }
    });
  };

  render() {
    return (
      <>
        {this.props.order_detailed !== undefined &&
        this.props.order_detailed !== null ? (
          <>
            <div style={{ zIndex: "2" }}>{this.props.children}</div>
            <MapSettingSidenav
              {...this.props}
              orderDetail={this.props.order_detailed}
              fileDownloader={this.fileDownloader}
              fileOptions={this.state.file_options}
              shpOptions={this.props.state.shp_options}
            />
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

class MapWrapper extends Component {
  render() {
    return (
      <LeafletMapResults point_type="shape" orderID={this.props.order}>
        {this.props.children}
      </LeafletMapResults>
    );
  }
}

const MapSettingSidenav = ({
  orderDetail,
  fileDownloader,
  fileOptions,
  shpOptions,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const settingButtons = {
    arrowOpen: <FaIcons.FaChevronRight />,
    arrowClose: <FaIcons.FaChevronLeft />,
  };

  const toggleSidebar = () => {
    setIsOpened(!isOpened);
  };

  if (orderDetail != null) {
    // console.log(orderDetail)
    var hectars = orderDetail.hectars;
    hectars = +hectars.toFixed(2);
  }

  const renderedFileOptions = () => {
    // console.log(orderDetail)
    if (orderDetail !== null) {
      //FIX THIS IN FUTURE
      let returnDivs = fileOptions.map((item) => {
        let orderType = orderDetail.type_of_order;
        let damages_species =
          (orderType >= 2 && orderType <= 7) ||
          (orderType >= 10 && orderType <= 15);
        let tree_count = orderType >= 8 && orderType <= 15;
        let render = true;
        if (item.id !== 2) {
          if ((item.id === 3 || item.id === 4) && !damages_species) {
            render = false;
          } else if (item.id === 5 && !tree_count) {
            render = false;
          }
        }
        if (item.id === 31) {
          render = true;
        }
        return render ? (
          <div
            className={css.toolContainerResults}
            key={item.id}
            onClick={() => fileDownloader(orderDetail, item.id)}
          >
            <BiIcons.BiCloudUpload className={css.icons} />
            <h6>{item.name}</h6>
          </div>
        ) : (
          ""
        );
      });
      return returnDivs;
    }
  };

  const renderedShapeFileOptions = () => {
    if (orderDetail !== null) {
      //FIX THIS IN FUTURE
      let returnDivs = shpOptions.map((item) => {
        let orderType = orderDetail.type_of_order;
        let damagesSpecies =
          (orderType >= 2 && orderType <= 7) ||
          (orderType >= 10 && orderType <= 15);
        let specieTypes =
          (orderType >= 4 && orderType <= 7) ||
          (orderType >= 12 && orderType <= 15);
        let render = true;
        if (item.id === 1 && !(damagesSpecies && specieTypes)) {
          render = false;
        } else if ((item.id === 4 || item.id === 7) && !damagesSpecies) {
          render = false;
        } else if (
          (item.id === 2 || item.id === 3 || item.id === 5 || item.id === 6) &&
          !specieTypes
        ) {
          render = false;
        }
        if (item.id === 31) {
          render = true;
        }
        return render ? (
          <div
            className={css.toolContainerResults}
            key={item.id}
            onClick={() => fileDownloader(orderDetail, 1, item.opt)}
          >
            <BiIcons.BiCloudUpload className={css.icons} />
            <h6>{item.name}</h6>
          </div>
        ) : (
          ""
        );
      });
      return returnDivs;
    }
  };

  return (
    <>
      <div
        className={css.contentRight}
        style={{ right: isOpened ? "0" : "-300px" }}
        backdrop="static"
        keyboard="false"
      >
        <div className={css.buttonRight} onClick={toggleSidebar}>
          {isOpened ? settingButtons.arrowOpen : settingButtons.arrowClose}
        </div>

        <div>
          {/* File downloads */}
          <div className={css.tools}>
            <h5>Downloads</h5>
            <hr
              style={{
                margin: "0 0 8px 0",
                borderBottom: "0.5px solid rgba(231, 231, 231, 0.4)",
                opacity: 0.5,
              }}
            />
            <div className={css.toolsGroup}>{renderedFileOptions()}</div>

            <h5>Shapefiles</h5>
            <hr
              style={{
                margin: "0 0 8px 0",
                borderBottom: "0.5px solid rgba(231, 231, 231, 0.4)",
                opacity: 0.5,
              }}
            />
            <div className={css.toolsGroup}>{renderedShapeFileOptions()}</div>
          </div>
        </div>

        {/* order information: displayed after map is shaped */}
        <div className={css.tools}>
          <h5>Analysis Info</h5>
          <hr
            style={{
              margin: "0 0 5px 0",
              borderBottom: "0.5px solid rgba(231, 231, 231, 0.4)",
              opacity: 0.5,
            }}
          />
          <p>{`Your order is`}</p>
          <h4>
            {`${hectars}`}
            <span>{`hectars`} </span>
          </h4>
        </div>
      </div>

      {isOpened ? <div className={css.overlay} onClick={toggleSidebar} /> : ""}
    </>
  );
};

const mapStateToProps = (state) => ({
  order_detailed: state.orders.order_detail,
  shp_url: "Nope",
});
export default connect(mapStateToProps, {
  sendNotification,
  sendErrorNotification,
  getOrder,
  getShpLink,
  getMosaicLink,
  getReportLink,
  getKMLLink,
  getTreeCountLink,
})(ResultsDetailed);
