import React, { Component } from "react";
import css from "../../styles/About.module.css";

class About extends Component {
  render() {
    return (
      <div className={css.mainContent}>
        <Header />
        <MiddleBody />
        <Team />
      </div>
    );
  }
}
export default About;

class Header extends Component {
  render() {
    return (
      <div className={css.headerImage}>
        <div className={css.headerTextHolder}></div>
        <div className={css.headerText}>
          For sustainable and profitable biodiversity.
        </div>
      </div>
    );
  }
}

class MiddleBody extends Component {
  render() {
    return (
      <div className={css.middleBody}>
        <div className={css.visionDiv}>
          <h1 style={{ fontWeight: "500" }}>Our vision</h1>
          Arboair will become an international player that creates the
          conditions for sustainable forestry on a global level, thereby
          enabling a transition to a precision forestry for future generations.{" "}
        </div>
        <div className={css.ourStoryDiv}>
          <div className={css.ourStoryText}>
            <h1 style={{ color: "#ed8707", fontWeight: "800" }}>Our Story</h1>
            <p>
              It all began with a small sprout of an idea and a hope that this
              will grow into something better and bigger for us and for others.
              We were commissioned in an innovation project to explore the
              potential of digitization in agriculture. This led to an idea that
              we evaluated together with farmers in the region where they found
              great interest in our service connected to forestry instead of
              agriculture, as the problems occurred in the forest when they were
              most busy in the fields during the summer.
            </p>
            <p>
              We then participated in entrepreneurial days with Prince Daniels
              Fellowship on the initiative of the Royal Academy of Engineering
              Sciences. Where we were invited to sit at the round table by
              business advisers and raise our ideas. They were well received and
              the prince encouraged us to move on with this project for deeper
              research. We would have taken a selfie but we didn’t dare at the
              time, however, this meeting gave us the courage to test our
              entrepreneurial wings and verify the idea against the forest
              industry that received us with open arms.
            </p>
            <p>
              We now have several collaborations for our version 1 and version 2
              projects and are in the process of revolutionizing the
              digitization of the forest industry in some areas.
            </p>
          </div>
          <div className={css.ourStoryImage} />
        </div>
        <div className={css.aimDiv}>
          Arboair aims to become an international player that creates the
          conditions for sustainable forestry on a world-wide basis, through
          digitalization of the forest industry. Thereby promoting biological
          diversity for our generation and those to come.
        </div>
      </div>
    );
  }
}

class Team extends Component {
  render() {
    return (
      <div className={css.teamDiv}>
        <h2>Who are we</h2>
        <div className={css.foundersDiv}>
          <div className={css.profileDiv}>
            <img
              alt={"Arboair"}
              src="https://www.arboair.com/wp-content/uploads/2020/02/headshot2.jpg"
              height="150px"
              style={{ borderRadius: "50%", paddingBottom: "20px" }}
            />
            <h4 style={{ color: "#ed8707" }}>
              Markus Drugge <br />
              CEO
            </h4>
            <p>
              Markus, the visionary and strategist. Our CEO has a background as
              an engineering student at Linköping University with a M.Sc
              Industrial Engineering.
            </p>
            <p style={{ color: "#ed8707" }}>
              070 – 215 40 20
              <br />
              drugge@arboair.com
            </p>
          </div>
          <div className={css.profileDiv}>
            <img
              alt={"Arboair"}
              src="https://www.arboair.com/wp-content/uploads/2020/02/headshot1.jpg"
              height="150px"
              style={{ borderRadius: "50%", paddingBottom: "20px" }}
            />
            <h4 style={{ color: "#ed8707" }}>
              Jacob Hjalmarsson <br />
              COO
            </h4>
            <p>
              Jacob, the multitasker and idea generator. Our COO has a
              background as an engineering student at Linköping University with
              a M.Sc Industrial Engineering.
            </p>
            <p style={{ color: "#ed8707" }}>
              072 – 302 70 99 <br />
              jacob@arboair.com
            </p>
          </div>
          <div className={css.profileDiv}>
            <img
              alt={"Arboair"}
              src="https://www.arboair.com/wp-content/uploads/2020/03/DDADD8AC-D4E7-4B0B-BB68-57DD6E58F22D-scaled-e1583159531809.jpeg"
              height="150px"
              style={{ borderRadius: "50%", paddingBottom: "20px" }}
            />
            <h4 style={{ color: "#ed8707" }}>
              Adnan Avdagic <br /> Head of IT
            </h4>
            <p>
              Adnan is an IT designer and creator like no else, he is also our
              very own gamer. Adnan has a background as an engineering student
              at Linköping University with a M.Sc Industrial Engineering.
            </p>
            <p style={{ color: "#ed8707" }}>adnan@arboair.com</p>
          </div>
          <div className={css.profileDiv}>
            <img
              alt={"Arboair"}
              src="https://www.arboair.com/wp-content/uploads/2020/04/Karol-Profil.jpg"
              height="150px"
              style={{ borderRadius: "50%", paddingBottom: "20px" }}
            />
            <h4 style={{ color: "#ed8707" }}>
              Karol Wojtulewicz <br />
              Head of AI and Technical planning
            </h4>
            <p>
              Karol is our genius and is always helping others. Karol has a
              background as an engineering student at Linköping University with
              a M.Sc AI – machine learning.
            </p>
            <p style={{ color: "#ed8707" }}>karol@arboair.com</p>
          </div>
          <div className={css.profileDiv}>
            <img
              alt={"Arboair"}
              src="https://www.arboair.com/wp-content/uploads/2020/03/1F76545C-98F9-418E-8785-19170172793D.jpeg"
              height="150px"
              style={{ borderRadius: "50%", paddingBottom: "20px" }}
            />
            <h4 style={{ color: "#ed8707" }}>
              Josef Carlson <br />
              Business developer and market manager
            </h4>
            <p>
              Josef is the guy with the biggest driving force and he has the
              biggest heart as well. Josef has a background as an engineering
              student at Linköping University with a M.Sc Technology for
              Sustainable Development.
            </p>
            <p style={{ color: "#ed8707" }}>
              072- 744 65 85 <br />
              josef@arboair.com
            </p>
          </div>
          <div className={css.profileDiv}>
            <img
              alt={"Arboair"}
              src="https://www.arboair.com/wp-content/uploads/2020/04/David-Profil.jpg"
              height="150px"
              style={{ borderRadius: "50%", paddingBottom: "20px" }}
            />
            <h4 style={{ color: "#ed8707" }}>
              David Nyberg <br />
              Developer and head of GIS
            </h4>
            <p>
              David, the americano. With an open mind he is curious and engaged.
              David has a background as a student in GIS/Computer Science in
              Colorado and a M.Sc Computer Science from Linköping University.
            </p>
            <p style={{ color: "#ed8707" }}>david@arboair.com</p>
          </div>
        </div>
      </div>
    );
  }
}
