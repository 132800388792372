import {
  GET_SHP_LINK,
  GET_MOSAIC_LINK,
  GET_REPORT_LINK,
  GET_KML_LINK,
  GET_TREE_COUNT_LINK,
} from "../actions/types.js";

const initialState = {
  shp_link: null,
  mosaic_link: null,
  report_link: null,
  kml_link: null,
  tree_count_link: null,
};

export default function resultsReducer (state = initialState, action) {
  
  //console.log("Action payload");
  //console.log(action.payload);
  switch (action.type) {
    case GET_SHP_LINK:
      return {
        ...state,
        shp_link: action.payload,
      };
    case GET_MOSAIC_LINK:
      return {
        ...state,
        mosaic_link: action.payload,
      };
    case GET_REPORT_LINK:
      return {
        ...state,
        report_link: action.payload,
      };
    case GET_KML_LINK:
      return {
        ...state,
        kml_link: action.payload,
      };
    case GET_TREE_COUNT_LINK:
      return {
        ...state,
        tree_count_link: action.payload,
      };
    default:
      return state;
  } 
}
