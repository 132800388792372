import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function DeleteOrderModal({
    showModal,
    handleDeleteOrderClose,
    handleDeleteOrderConfirm,
    order,
  }) {
  return (
    <Modal
      show={showModal}
      onHide={handleDeleteOrderClose}
      backdrop="static"
      centered
      scrollable
      keyboard={false}
      order={order}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete the order {order ? order.analysis_name : ''}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleDeleteOrderClose}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={handleDeleteOrderConfirm} 
          >
          Confirm and Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

