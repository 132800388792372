import axios from 'axios';

import { sendErrorNotification } from "../../actions/notifications";

import store from '../../store';
import { 
  loadUser,
  // eraseCookie, 
  goToLogin,
  // getCookie,
  logout,
} from '../../actions/auth';


import {
  LINK_ACTIVATION_EXPIRED,
  LINK_ACTIVATION_DENIED,
  NEW_PASSWORD_REQUIRED,
  EMAIL_NOT_VALID
} from "../../actions/types";

// export const axiosRequestInterceptor = axios.interceptors.request.use(request => {
//   const authUrl = request.url.includes('auth');
//   const isAuth = store.getState().auth.isAuthenticated;
//   const authHeader = request.headers.Authorization;

//   console.log(authUrl, isAuth, authHeader)

//   if ( !(authUrl) && isAuth !== true) {
//     console.log('Loading user')
//     return new Promise( async(resolve) => {
//       let res = store.dispatch(loadUser())
//       .then((res) => {
//         const token = res[0].IdToken;
//         request.headers['Authorization'] = `Bearer ${token}`
//         return request;
//       })
//       resolve(res);
//     })
//   } else if ( authHeader !== undefined){
//     const idToken = authHeader.split(' ')
//     const loggedIn = localStorage.getItem('id');
//     //Check if cookie has been tampered with
//     console.log('loggedIn', loggedIn);
//     console.log('idToken', idToken[1])
//     // if ( loggedIn !== idToken[1] ) {
//     //   goToLogin();
//     // }
//   }
//   return request;
// });

export const axiosResponseInterceptor = axios.interceptors.response.use(response => {
  return response;
  }, err => {
    return new Promise( async (resolve, dispatch) => {
        const originalReq = err.config;
        const loggedIn = localStorage.getItem('id')
        if ( err.response === undefined ) {
          console.log('Real weird stuff')
          console.log(err)
          resolve()
        } else if ( err.response.status === 401 && err.config && !err.config.__isRetryRequest && loggedIn)
        //If backend reports NotAuthorized and user has loggedIn cookie, try to autherize a new auth token
        {
            
          // console.log('Retryign with new auth')
            originalReq._retry = true;
  
            let res = store.dispatch(loadUser())
              .then((res) => {
                  // console.log(res)
                  // console.log(originalReq)

                  const token = res[0].IdToken;

                  originalReq.headers['Content-Type'] = 'application/json';
                  originalReq.headers['Authorization'] = `Bearer ${token}`
                  return axios(originalReq);
              }
              )  
            resolve(res);
        } else if ( err.response.status === 418 && err.config && !err.config.__isRetryRequest )
        //If refresh token has expired logout the user
        {
          console.log(originalReq)
          // console.log('Refresh Expired')
          // eraseCookie('loggedIn')
          store.dispatch(sendErrorNotification('Refresh Token has expired, please log in again'));
          setTimeout(function(){
            goToLogin(originalReq);
          }, 3000);
        } else if ( err.response.status === 402 && err.config && !err.config.__isRetryRequest ){
          resolve(err.response);
        } else if ( err.response.status === 403 && err.config && !err.config.__isRetryRequest ){
          resolve(err.response);
        } else {
          //General error handling for responses
            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                //If it is a AWS error, it will handle it below
                if ( err.response.data.aws_error ) {
                  var message = '';
                  console.log(err.response.data)
                  const aws_error = err.response.data.aws_error;
                  switch(true) {
                    //Login errors are handled here and sent back to login to be dispalyed via dispatch
                    case originalReq.url.includes('login'):
                      switch(aws_error) {
                        case 'InvalidParameterException':
                          message = err.response.data.error_message;
                          break;
                        case 'NotAuthorizedException':
                          message = err.response.data.error_message;
                          break;
                        case 'UserNotConfirmedException':
                          message = err.response.data.error_message;
                          break;
                        case 'NoAccessToArboairTestSuite':
                          message = err.response.data.error_message;
                          break;
                        default:
                          message = 'Unknown Exception, contact Arboair'
                        }
                        dispatch({
                          message,
                        });
                      break;

                    //Refresh errors are handled here and sent to the user via Error Notification
                    case originalReq.url.includes('refresh'):
                      switch(aws_error) {
                        case 'ResourceNotFoundException':
                          message = 'Backend Issue, contact Arboair';
                          store.dispatch(sendErrorNotification(message));
                          break;
                        case 'NotAuthorizedException':
                          message = err.response.data.error_message;
                          store.dispatch(sendErrorNotification(message));
                          // eraseCookie('loggedIn')
                          setTimeout(function(){
                            store.dispatch(logout());
                          }, 5000);
                          break;
                        case 'AccessDeniedException':
                          message = 'Backend Issue, contact Arboair';
                          store.dispatch(sendErrorNotification(message));
                          break;
                        case 'TooManyRequestsException':
                          message = 'Backend Issue, contact Arboair';
                          store.dispatch(sendErrorNotification(message));
                          break;
                        default:
                          message = 'Unknown Exception, contact Arboair';
                          store.dispatch(sendErrorNotification(message));
                        }
                      break;
                    
                    //Logout errors are handled here
                    case originalReq.url.includes('logout'):
                      switch(aws_error) {
                        case 'UnauthorizedException':
                          message = 'Backend Issue, contact Arboair';
                          store.dispatch(sendErrorNotification(message));
                          break;
                        default:
                          message = 'Unknown Exception, contact Arboair';
                          store.dispatch(sendErrorNotification(message));
                        }
                      break;
                    
                    //Register errors are handled here and sent to the user via Error Notification
                    case originalReq.url.includes('register'):
                      switch(aws_error) {
                        case 'ResourceNotFoundException':
                          message = 'Backend Issue, contact Arboair';
                          // store.dispatch(sendErrorNotification(message));
                          break;
                        case 'InvalidPasswordException':
                          message = err.response.data.error_message;
                          // store.dispatch(sendErrorNotification(message));
                          break;
                        case 'UsernameExistsException':
                          message = err.response.data.error_message;
                          // store.dispatch(sendErrorNotification(message));
                          break;
                        default:
                          message = 'Unknown Exception, contact Arboair';
                          // store.dispatch(sendErrorNotification(message));
                        }
                        dispatch({
                          message,
                        });
                      break;
                      
                    //Forgot password errors are handled here and sent to the user via Error Notification
                    case originalReq.url.includes('forgot_password'):
                      switch(aws_error) {
                        case 'ResourceNotFoundException':
                          message = 'Backend Issue, contact Arboair';
                          store.dispatch(sendErrorNotification(message));
                          break;
                        case 'LimitExceededException':
                          message = 'Number of tries exceeded! Try again later';
                          store.dispatch(sendErrorNotification(message));
                          break;
                        case 'CodeMismatchException':
                          message = 'Code does not match!';
                          store.dispatch(sendErrorNotification(message));
                          break;
                        case 'ExpiredCodeException':
                          message = 'Code has expired, please request a new one';
                          store.dispatch(sendErrorNotification(message));
                          break;
                        default:
                          message = 'Unknown Exception, contact Arboair';
                          store.dispatch(sendErrorNotification(message));
                        }
                      break;
                    default:
                      break;
                  }
                } else {
                  if (originalReq.url.includes('/link/')) {
                    if (err.response.status === 410) {
                      dispatch({
                        type: LINK_ACTIVATION_EXPIRED
                      });
                    } else {
                      let data = err.response.data;
                      if ( data !== undefined ) {
                        let reason = data['reason']
                        if ( reason === 'email_not_valid') {
                          dispatch({
                            type: EMAIL_NOT_VALID,
                            payload: data['message']
                          });
                        };
                      } else {
                        dispatch({
                          type: LINK_ACTIVATION_DENIED
                        });
                      };
                    };
                  };
                  if (originalReq.url.includes('/login')) {
                    if (err.response.status === 409){
                      let data = err.response.data
                      dispatch({
                        type: NEW_PASSWORD_REQUIRED,
                        payload: data,
                      });
                    } else {
                      store.dispatch(sendErrorNotification(err.response.data));
                    };
                  };
                  //If not AWS error, print error information
                  // console.log(err.response.data);
                  // console.log(err.response.status);
                  // console.log(err.response.headers);
                  if (typeof err.response.data === "string"){
                    store.dispatch(sendErrorNotification(err.response.data));
                  };
                };
              } else if (err.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(err.request);
              } else if (err.message) {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', err.message);
              } else {
                console.log('Error', err);
              }
        }
    });
});

// export default axiosResponseInterceptor;