// any actions are going to be here
import axios from "axios";
import { tokenConfig } from "./auth";

import {
  GET_FORESTS,
  GET_FOREST,
  DELETE_FOREST,
  ADD_FOREST,
  CHANGE_FOREST_PAGE,
  ADD_FOREST_SHP,
  CLEAR_FORESTS,
  FOREST_STATS,
  FOREST_ANALYSES,
} from "./types";

const ip = process.env.REACT_APP_BACKEND_DNS;

// GET ALL FORESTS
export const getForests = () => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/myforest/?opp=${10000}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_FORESTS,
        payload: [res.data["forests"], res.data["num_pages"]],
      });
      return res;
    })
    .catch((err) => {
      // console.log(err)
      // return err;
    });
};

// SEE DETAILED ORDER and its images
export const getForest = (id) => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/myforest/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_FOREST,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      console.log(err)
      return err;
    });
};

// DELETE ORDER
export const deleteForest = (id) => async (dispatch, getState) => {
  return await axios
    .delete(`${ip}/myforest/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_FOREST,
        payload: id,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

// ADD ORDER and return order id
export const addForest = (forest) => async (dispatch, getState) => {
  return await axios
    .post(`${ip}/myforest/`, forest, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_FOREST,
        payload: res.data,
      });
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
};

export const getZipFile =  (map, forestID, generateFeatureCollection) => async (
  dispatch,
  getState
) => {
  let form_data = new FormData();
  form_data.append("forest", forestID);
  return await axios
    .post(`${ip}/myforest_shp/`, form_data, tokenConfig(getState))
    .then((res) => {
      axios
        .get(res.data, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        })
        .then((response) => {
          const formData = new FormData();
          formData.append("file", response.data);
          generateFeatureCollection("shapefilezip", formData, map);
          dispatch({
            type: ADD_FOREST_SHP,
            payload: res.data,
          });
          return response;
        })
        .catch((e) => {
          console.log("Error getting shapefile blob");
          console.log(e);
          return e;
        });
    })
    .catch((e) => {
      console.log("Error getting shapefile link");
      console.log(e);
      return e;
    });
};

// GET all ORDERS
export const clearForests = () => (dispatch, getState) => {
  dispatch({
    type: CLEAR_FORESTS,
  });
};

export const getZipFileLeafleet = (forest, addSHPToMap, map) => async (
  dispatch,
  getState
) => {
  let form_data = new FormData();
  let forestID = forest.id;
  form_data.append("forest", forestID);
  await axios
    .post(`${ip}/myforest_shp/`, form_data, tokenConfig(getState), {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "text/html",
    })
    .then(async (res) => {
      await axios
        .get(res.data, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        })
        .then(async (res) => {
          let blob = res.data;
          // transform blom to the array buffer so that the parseZip function can get the shapefile
          blob.arrayBuffer().then(async (buffer) => {
            addSHPToMap(buffer,map).then((geo) => {
              dispatch({
                type: ADD_FOREST_SHP,
                payload: res.data,
              });
              return geo;
            });
          });
        });
    })
    .catch((e) => {
      console.log("Error getting shapefile", e);
      console.log(e);
      return e;
    });
};

// CHANGE page in order list
export const changePage = (pageNum, method, acs, opp, searchKey='', urlFilterParams) => async (
  dispatch,
  getState
) => {
  return await axios
    .get(
      `${ip}/myforest/?method=${method}&acs=${acs}&page=${
        pageNum + 1
      }&opp=${opp}&skey=${searchKey}&${urlFilterParams}`,
      tokenConfig(getState)
    )
    .then((response) => {
      dispatch({
        type: CHANGE_FOREST_PAGE,
        payload: [
          pageNum,
          response.data["forests"],
          response.data["num_pages"],
        ],
      });
      return response;
    })
    .catch(() => {
      // console.log("Failed to fetch a page");
      // console.log(error);
      // return error;
    });
};

// GET forest stats
export const getForestStats = (forest_id) => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/myforest/stats/?id=${forest_id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: FOREST_STATS,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      // console.log(err)
      // return err;
    });
};

// GET Forest analyses
export const getForestAnalyses = (forest_id) => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/myforest/forest_analyses/?id=${forest_id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: FOREST_ANALYSES,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      // console.log(err)
      // return err;
    });
};