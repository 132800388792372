// Package imports
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { DatePicker } from '@material-ui/pickers'
import React, { Component, useState } from 'react'
import {
  Form,
  FormControl,
  InputGroup,
  Overlay,
  Tooltip,
} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { connect } from 'react-redux'
import Select from 'react-select'

// Store imports
import { getGroupsForUser, getRootGroup } from '../../actions/admin'
import { changePage, deleteForest, getForests } from '../../actions/myforest'
import {
  sendErrorNotification,
  sendNotification,
} from '../../actions/notifications'
import {
  addNewOrder,
  getFailedOrders,
  getZeroOrders,
} from '../../actions/orders'
import { getUserProfile } from '../../actions/userpage'

// Local component imports
// import AreaPicker from "../subpages/AreaPicker";
import { createMessage } from '../../actions/messages'
import DeleteForestModal from '../modals/DeleteForestModal'
import NewOrderModal from '../modals/NewOrderModal'
import LeafletMapChooseExtent from '../subpages/LeafletMapChooseExtent'
import LeafletMapMyForest from '../subpages/LeafletMapMyForest'

// import CheckBox from "../subpages/Checkbox";

// css imports
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import css from '../../styles/MyForests.module.css'
import '../../styles/small_components/pagination.css'

// icons
import { AiOutlineDelete, AiOutlineEdit, AiOutlineUpload } from 'react-icons/ai'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

const objects_per_page = 10

class MyForests extends Component {
  state = {
    addingArea: false,
    forestName: null,
    choosingExtent: false,
    analysisType: '',
    exceed: false,
    analysisName: '',
    selectedOrg: this.props.rootGroup,
    currentRole: null,
  }

  constructor(props) {
    super(props)
    this.props.changePage(
      this.props.current_page,
      'date_created',
      '',
      objects_per_page
    )
    this.props.getRootGroup()
    this.props.getGroupsForUser()
    getZeroOrders()
    getFailedOrders()
  }

  switchView = (e) => {
    this.props.getForests()
    // console.log(this.state.currentRole)
    this.setState({
      addingArea: !this.state.addingArea,
    })
  }

  handleOrgGroupChange = (e) => {
    this.setState({
      selectedGroup: e,
      currentRole: e.role,
    })
  }

  chooseExtent = (analysisType, exceed, forest, analysisName, userData) => {
    this.setState({
      analysisType: analysisType,
      exceed: exceed,
      choosingExtent: !this.state.choosingExtent,
      forest: forest,
      analysisName: analysisName,
      userData: userData,
    })
  }

  doneChoosingExtent = () => {
    this.setState({
      choosingExtent: !this.state.choosingExtent,
    })
  }

  doneEdditingForest = () => {
    this.setState({
      forestName: null,
    })
    this.switchView()
  }

  editForest = (index) => {
    this.setState({
      forest: this.props.forests[index],
    })
    this.switchView()
  }

  render() {
    return (
      <div className={css.contentMF}>
        {this.state.addingArea ? (
          <LeafletMapMyForest
            goBack={this.doneEdditingForest}
            forestName={this.state.forest ? this.state.forest.forestname : ''}
            forest={this.state.forest}
            className={css.areaPicker}
            state={this.state}
            {...this.props}
          />
        ) : this.state.choosingExtent ? (
          <LeafletMapChooseExtent
            goBack={this.doneChoosingExtent}
            forestName={this.state.forest ? this.state.forest.forestname : ''}
            forest={this.state.forest}
            className={css.areaPicker}
            state={this.state}
            hectars={0}
            {...this.props}
          />
        ) : (
          <ForestList
            // forests={this.props.forests}
            switchView={this.switchView}
            chooseExtent={this.chooseExtent}
            editForest={this.editForest}
            state={this.state}
            handleOrgGroupChange={this.handleOrgGroupChange}
            // orgGroups={this.props.orgGroups}
            // rootGroup={this.props.rootGroup}
            {...this.props}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  forests: state.myforest.forests,
  user_id: state.auth.user,
  current_page: state.myforest.current_page,
  tot_num_pages: state.myforest.tot_pages,
  userProfile: state.userpage.userProfile,
  orgGroups: state.admin.groups,
  rootGroup: state.admin.root,
  zero_orders: state.orders.zero_orders,
  failed_orders: state.orders.failed_orders,
})

export default connect(mapStateToProps, {
  getForests,
  changePage,
  addNewOrder,
  createMessage,
  deleteForest,
  getUserProfile,
  sendErrorNotification,
  sendNotification,
  getRootGroup,
  getGroupsForUser,
  getZeroOrders,
  getFailedOrders,
})(MyForests)

export class ForestList extends Component {
  constructor(props) {
    super(props)
    // this.props.getRootGroup();
    // this.props.getGroupsForUser();
    this.myForestSearchTooltipRef = React.createRef()
    this.minSize = 0
    this.maxSize = 1000000
    this.customStyles = {
      menu: (provided) => ({
        ...provided,
        background: '#F9F9F9;',
        marginTop: '5px',
        padding: '2px 4px',
      }),

      input: (provided) => ({
        ...provided,
        height: '100%',
        padding: '0.4rem 0',
      }),

      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
      }),

      indicatorsContainer: (provided) => ({
        ...provided,
        color: '#131313',
      }),

      option: (provided, state) => ({
        ...provided,
        background: state.isFocused
          ? '#14ACC8 !important'
          : '#F9F9F9 !important',
        color: state.isFocused ? '#FFFFFF' : '#131313',
        cursor: 'pointer',
      }),

      control: (provided) => ({
        ...provided,
        border: '2px solid #f0f0f0 !important',
        boxShadow: 'none',
        cursor: 'pointer',
        padding: '0',
        margin: '0',
        height: '50px',
        width: '150px',
      }),
    }

    this.orgGroups = []

    // console.log(this.props)

    // this.props.orgGroups.map((groupType) => (
    //   console.log(groupType)
    // ))

    // for (const groupType in this.props.orgGroups) {
    //   for (const index in this.props.orgGroups[groupType]) {
    //     let group = this.props.orgGroups[groupType][index];
    //     // console.log(group)
    //     let groupData = {
    //       value: group,
    //       label: group
    //     }
    //     this.orgGroups.push(groupData);
    //   };
    // };

    this.state = {
      orderModalActive: false,
      forestName: null,
      forestHectars: null,
      analysisName: null,
      forest: null,
      isShowingAnalysisNameTooltip: false,
      isShowingSearchKeyTooltip: false,
      searchKey: '',
      filterArray: [
        { name: 'date', opened: true, active: false },
        { name: 'size', opened: true, active: false },
        // DISABLED SINCE WE DONT HAVE CONDITION ON THE BACKEND
        // {name: 'condition', opened: true, active: false},
        { name: 'latest_analysis', opened: true, active: false },
      ],
      conditions: [
        { name: 'Unknown', checked: false },
        { name: 'Good', checked: false },
        { name: 'Bad', checked: false },
        { name: 'Dragon infested', checked: false },
      ],
      startCreatedDate: new Date('2021-01-01').toUTCString(),
      endCreatedDate: new Date().toUTCString(),
      startAnalysisDate: new Date('2021-01-01').toUTCString(),
      endAnalysisDate: new Date().toUTCString(),
      minSizeInput: this.minSize,
      maxSizeInput: this.maxSize,
      urlFilterParams: '',
      analysisTypes: [
        { name: 'mosaic', active: true },
        { name: 'damages', active: true },
        { name: 'species_detection', active: true },
        { name: 'tree_count', active: true },
      ],
      analysisTypeChecked: false,
      orderType: 0,
      userData: null,
      maxRunsExceeded: false,
      // selectedGroup: this.props.rootGroup,
    }
  }

  placeOrder = (exceed = false) => {
    let analysisType = ''
    for (const index in this.state.analysisTypes) {
      let active = this.state.analysisTypes[index].active ? '1' : '0'
      analysisType += active
    }
    this.setState({
      analysisTypes: [
        { name: 'mosaic', active: false },
        { name: 'damages', active: false },
        { name: 'species_detection', active: false },
        { name: 'tree_count', active: false },
      ],
      analysisTypeChecked: false,
    })

    this.props.chooseExtent(
      analysisType,
      exceed,
      this.state.forest,
      this.state.analysisName,
      this.state.userData
    )

    // this.props.addNewOrder(
    //   this.state.forestName,
    //   this.state.analysisName,
    //   0,
    //   analysisType,
    //   exceed,
    // )
    // .then((res) => {
    //   if ( res.status === 201 ) {
    //     this.props.createMessage({ customSuccess: "Order has been created!" });
    //   } else if ( res.status === 402 ) {
    //     this.props.sendErrorNotification(res.data);
    //     this.setState({
    //       maxRunsExceeded: true
    //     });
    //   } else {
    //     setTimeout(function () {
    //       this.props.sendErrorNotification(res.data);
    //     }, 3000);
    //   }
    // });
  }

  onAnalysisNameChange = (input) => {
    var analysis_name = input.value
    if (analysis_name === '') {
      this.hideAnalysisNameTooltip()
    } else if (!analysis_name.match(/^[A-Za-z0-9äöå\s]+$/i)) {
      this.showAnalysisNameTooltip()
      return
    } else {
      this.hideAnalysisNameTooltip()
    }
    this.setState({
      analysisName: analysis_name,
    })
  }

  showAnalysisNameTooltip = () => {
    this.setState({
      isShowingAnalysisNameTooltip: true,
    })
  }

  changeAnalysisType = (index) => {
    let tempArray = []
    let anyChecked = 0
    let analysisType = ''
    tempArray = this.state.analysisTypes
    tempArray[index].active = !tempArray[index].active
    for (const index in tempArray) {
      if (tempArray[index].active) {
        anyChecked += 1
      }
      let active = tempArray[index].active ? '1' : '0'
      analysisType += active
    }
    let analysisValue = parseInt(analysisType.split('').reverse().join(''), 2)
    if (anyChecked > 0) {
      this.setState({
        analysisTypeChecked: true,
      })
    } else {
      this.setState({
        analysisTypeChecked: false,
      })
    }
    this.setState({
      analysisTypes: tempArray,
    })
    this.setState({
      orderType: analysisValue,
    })
  }

  hideAnalysisNameTooltip = () => {
    this.setState({
      isShowingAnalysisNameTooltip: false,
    })
  }

  onSearchKeyChange = (input) => {
    var searchKey = input.target.value
    console.log('SKEY ', searchKey)
    if (searchKey === '') {
      this.hideSearchKeyTooltip()
    } else if (!searchKey.match(/^([ ÆØÅæøåa-zA-Z0-9.-]+)$/i)) {
      this.showSearchKeyTooltip()
      return
    } else {
      this.hideSearchKeyTooltip()
    }
    let method = 'date_created'
    let acs = ''
    let page = 0
    this.setState(
      {
        searchKey: searchKey,
      },
      function () {
        this.props.changePage(
          page,
          method,
          acs,
          objects_per_page,
          this.state.searchKey,
          this.state.urlFilterParams
        )
      }
    )
  }

  showSearchKeyTooltip = () => {
    this.setState({
      isShowingSearchKeyTooltip: true,
    })
  }

  hideSearchKeyTooltip = () => {
    this.setState({
      isShowingSearchKeyTooltip: false,
    })
  }

  handlePageClick = (data) => {
    let pageNumber = data.selected
    let method = 'date_created'
    let acs = ''
    this.props.changePage(
      pageNumber,
      method,
      acs,
      objects_per_page,
      this.state.searchKey,
      this.state.urlFilterParams
    )
  }

  // Create New Order Modal
  handleOrderModalOpen = async (forest, forestName, hectars) => {
    let userProfile = await this.props.getUserProfile()
    // console.log(userProfile);
    let userData = userProfile
    this.setState({
      orderModalActive: true,
      forestName: forestName,
      forestHectars: hectars,
      forest: forest,
      userData: userData,
      analysisTypes: [
        { name: 'mosaic', active: false },
        { name: 'damages', active: false },
        { name: 'species_detection', active: false },
        { name: 'tree_count', active: false },
      ],
      analysisTypeChecked: false,
    })
  }

  handleOrderModalClose = () => {
    this.setState({
      orderModalActive: false,
      forestName: null,
      forestHectars: null,
      forest: null,
    })
  }

  handleOrderConfirm = (exceed) => {
    this.placeOrder(exceed, this.state.forest)
    this.handleOrderModalClose()
  }

  // Delete Order Modal
  handleDeleteModalOpen = (forest, forestName, hectars) => {
    this.setState({
      modalDeleteActive: true,
      forestName: forestName,
      forestHectars: hectars,
      forest: forest,
    })
  }

  handleDeleteModalClose = () => {
    this.setState({
      modalDeleteActive: false,
      forestName: null,
      forestHectars: null,
      forest: null,
    })
  }

  handleDeleteConfirm = () => {
    deleteForest(this.state.forest)
    console.log(this.state.forest)
    this.handleDeleteModalClose()
  }

  changeFilterOpened = (index) => {
    let tempArray = this.state.filterArray
    tempArray[index]['opened'] = !tempArray[index]['opened']
    this.setState({
      filterArray: tempArray,
    })
  }

  changeConditionClicked = (index) => {
    let tempArray = this.state.conditions
    tempArray[index]['checked'] = !tempArray[index]['checked']
    this.setState(
      {
        conditions: tempArray,
      },
      () => this.updateFiltering()
    )
  }

  changeInputSize = (input) => {
    let type = input.target.placeholder
    let value = input.target.value
    if (value >= this.minSize && value <= this.maxSize) {
      switch (type) {
        case 'Min Size':
          if (value <= this.state.maxSizeInput) {
            this.setState(
              {
                minSizeInput: value,
              },
              () => this.updateFiltering()
            )
          }
          break
        case 'Max Size':
          if (value >= this.state.minSizeInput) {
            this.setState(
              {
                maxSizeInput: value,
              },
              () => this.updateFiltering()
            )
          }
          break
        default:
          break
      }
    }
  }

  changeInputDate = (date, type) => {
    switch (type) {
      case 'startCreatedDate':
        this.setState(
          {
            startCreatedDate: date.toUTCString(),
          },
          () => this.updateFiltering()
        )
        break
      case 'endCreatedDate':
        this.setState(
          {
            endCreatedDate: date.toUTCString(),
          },
          () => this.updateFiltering()
        )
        break
      case 'startAnalysisDate':
        this.setState(
          {
            startAnalysisDate: date.toUTCString(),
          },
          () => this.updateFiltering()
        )
        break
      case 'endAnalysisDate':
        this.setState(
          {
            endAnalysisDate: date.toUTCString(),
          },
          () => this.updateFiltering()
        )
        break
      default:
        break
    }
  }

  changeFilterActive = (index) => {
    let tempArray = this.state.filterArray
    tempArray[index]['active'] = !tempArray[index]['active']
    this.setState(
      {
        filterArray: tempArray,
      },
      () => this.updateFiltering()
    )
  }

  updateFiltering = () => {
    let activeFilters = Object.keys(this.state.filterArray).map((key) => {
      let params = null
      let active = this.state.filterArray[key]['active']
      let name = this.state.filterArray[key]['name']
      if (active) {
        switch (name) {
          case 'date':
            let startCreatedDate = '&scd=' + this.state.startCreatedDate
            let endCreatedDate = '&ecd=' + this.state.endCreatedDate
            params = startCreatedDate + endCreatedDate
            break
          case 'size':
            let minSize = '&mins=' + this.state.minSizeInput
            let maxSize = '&maxs=' + this.state.maxSizeInput
            params = minSize + maxSize
            break
          case 'condition':
            let conditions = '&con='
            let activeConditions = Object.keys(this.state.conditions).map(
              (key) => {
                let name = this.state.conditions[key]['name']
                let active = this.state.conditions[key]['checked']
                if (active) {
                  return name
                }
                return null
              }
            )
            for (const condition in activeConditions) {
              if (activeConditions[condition]) {
                conditions = conditions + activeConditions[condition] + ','
              }
            }
            params = conditions
            break
          case 'latest_analysis':
            let startAnalysisDate = '&sad=' + this.state.startAnalysisDate
            let endAnalysisDate = '&ead=' + this.state.endAnalysisDate
            params = startAnalysisDate + endAnalysisDate
            break
          default:
            break
        }
      }
      return params
    })
    let params = '?'
    for (const filter in activeFilters) {
      if (activeFilters[filter]) {
        params = params + activeFilters[filter]
      }
    }
    let method = 'date_created'
    let acs = ''
    let page = 0
    this.setState(
      {
        urlFilterParams: params,
      },
      function () {
        this.props.changePage(
          page,
          method,
          acs,
          objects_per_page,
          this.state.searchKey,
          this.state.urlFilterParams
        )
      }
    )
  }

  // handleOrgGroupChange = (e) => {
  //   console.log(e)
  //   this.setState({
  //     selectedGroup: e
  //   })
  // };

  render() {
    return (
      <>
        <MapFilterSideNav
          {...this.props}
          filterArray={this.state.filterArray}
          changeFilterOpened={this.changeFilterOpened}
          changeConditionClicked={this.changeConditionClicked}
          conditions={this.state.conditions}
          startCreatedDate={this.state.startCreatedDate}
          endCreatedDate={this.state.endCreatedDate}
          startAnalysisDate={this.state.startAnalysisDate}
          endAnalysisDate={this.state.endAnalysisDate}
          minSizeInput={this.state.minSizeInput}
          maxSizeInput={this.state.maxSizeInput}
          changeSize={this.changeInputSize}
          changeDate={this.changeInputDate}
          changeFilterActive={this.changeFilterActive}
        />
        <div className={css.containerContentMF}>
          <div className={css.containerOneMF}>
            <div className={css.boxOneMF}>
              <h2 id='pageTitle'>My Forest</h2>

              {this.props.userProfile !== null ? (
                <>
                  {console.log(this.props.orgGroups)}
                  {this.props.userProfile.is_org_member &&
                  this.props.orgGroups !== undefined ? (
                    <>
                      {Object.keys(this.props.orgGroups).map((groupType) => {
                        this.props.orgGroups[groupType].map((group) => {
                          if (
                            !group.includes('Order') &&
                            !group.includes('Forest')
                          ) {
                            let groupData = {
                              value: group.toString(),
                              label: group.toString(),
                              role: groupType.toString(),
                            }
                            const found = this.orgGroups.find(
                              (element) => element.value === group.toString()
                            )
                            if (found === undefined) {
                              this.orgGroups.push(groupData)
                            }
                          }
                          return ''
                        })
                      })}

                      {/* {console.log(this.orgGroups)} */}
                      {this.orgGroups.length > 0 ? (
                        <>
                          {this.props.state.selectedGroup === undefined
                            ? this.props.handleOrgGroupChange(this.orgGroups[0])
                            : ' '}
                          <div className='dropdown-container'>
                            <Select
                              options={this.orgGroups}
                              // placeholder={this.props.state.selectedGroup}
                              value={this.props.state.selectedGroup}
                              onChange={this.props.handleOrgGroupChange}
                              styles={this.customStyles}
                            />
                          </div>
                        </>
                      ) : (
                        ' '
                      )}
                    </>
                  ) : (
                    ' '
                  )}
                </>
              ) : (
                ' '
              )}
            </div>
            {/* Add new forest */}
            <div className={css.boxTwoMF}>
              <div className={css.buttonHeader}>
                <div onClick={this.props.switchView} className={css.btnMainMF}>
                  <i className='fa fa-plus' aria-hidden='true'></i> Add new
                  forest
                </div>

                {this.props.zero_orders || this.props.failed_orders ? (
                  <a href={'/upload'}>
                    <div className={css.btnMainMF}>
                      <AiOutlineUpload as='button' />
                      Upload Images
                    </div>
                  </a>
                ) : (
                  ' '
                )}
              </div>
            </div>
          </div>

          {/* -- Search -- */}
          <div className={css.searchBarWrapper}>
            <div className={css.searchBar}>
              <InputGroup size='lg'>
                <InputGroup.Text
                  style={{ height: '48px' }}
                  id='inputGroup-sizing-sm'
                >
                  <i className='fas fa-search'></i>
                </InputGroup.Text>
                <FormControl
                  ref={this.myForestSearchTooltipRef}
                  aria-describedby='inputGroup-sizing-sm'
                  placeholder='Search Forest...'
                  aria-label='Search Forest...'
                  onChange={this.onSearchKeyChange}
                />
              </InputGroup>
              {
                <Overlay
                  target={this.myForestSearchTooltipRef}
                  show={this.isShowingSearchKeyTooltip}
                  placement='right'
                >
                  {(props) => (
                    <Tooltip id='overlay-example' {...props}>
                      Only letters and numbers
                    </Tooltip>
                  )}
                </Overlay>
              }
            </div>
          </div>

          {/* Pagination */}
          {this.props.tot_num_pages > 1 ? (
            <div className={css.paginationA}>
              <ReactPaginate
                previousLabel={
                  <ArrowBackIosIcon style={{ fontSize: 18, width: 150 }} />
                }
                nextLabel={
                  <ArrowForwardIosIcon style={{ fontSize: 18, width: 150 }} />
                }
                className={css.pag}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.props.tot_num_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                initialPage={this.props.current_page}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                disabledClassName={'disabled-page'}
                activeClassName={'item active '}
                nextClassName={'arrow next '}
                pageClassName={'item pagination-page '}
                previousClassName={'arrow previous'}
              />
            </div>
          ) : (
            ''
          )}

          {/* Buttons */}
          {/*TODO 
        Add multi uplaod functionality */}
          {/* <div className={css.containerTwoMF}>
          <div className={css.boxFourSelected}>
            <button className={css.btnTwoMF}>Delete <br></br>Selected</button>
            <button className={css.btnTwoMF}>Order <br></br>Selected</button>
          </div>
        </div> */}

          {/* Headers */}
          <div className={css.contFirstA}>
            <div className={css.elementCheck}>{/* <CheckBox /> */}</div>
            <div className={css.elementThumbnail} />
            <div className={css.elementForestName}>
              <p>Forest Name</p>
            </div>
            <div className={css.elementLatestAn}>
              <p>Latest Analysed</p>
            </div>
            <div className={css.elementCondition}>
              <p>Forest Condition</p>
            </div>
            <div className={css.elementSize}>
              <p>Area Size</p>
            </div>
            <div className={css.elementBtnRow} />
          </div>

          {/* Forest List */}
          {/* {console.log(this.props.forests, this.props.orgGroups)} */}
          {this.props.forests.map((forest, index, onDelete) => (
            <>
              <MenuComponent
                key={index}
                forest={forest}
                index={index}
                editForest={this.props.editForest}
                handleForestPressed={this.handleModalOpen}
                handleDeleteForestPressed={this.handleDeleteModalOpen}
                onDelete={onDelete}
                handleOrderPressed={this.handleOrderModalOpen}
              />
            </>
          ))}

          {/* Pagination */}
          {this.props.tot_num_pages > 1 ? (
            <div className={css.paginationMF}>
              <ReactPaginate
                previousLabel={
                  <ArrowBackIosIcon style={{ fontSize: 18, width: 150 }} />
                }
                nextLabel={
                  <ArrowForwardIosIcon style={{ fontSize: 18, width: 150 }} />
                }
                className={css.pag}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.props.tot_num_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                initialPage={this.props.current_page}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                disabledClassName={'disabled-page'}
                activeClassName={'item active '}
                nextClassName={'arrow next '}
                pageClassName={'item pagination-page '}
                previousClassName={'arrow previous'}
              />
            </div>
          ) : (
            ''
          )}
          {/* <NewOrderModal
          showModal={this.state.modalActive}
          handleClose={this.handleModalClose}
          handleConfirm={this.handleConfirm}
          amountHectars={this.state.forestHectars}
          forestName={this.state.forestName}
        /> */}
          <NewOrderModal
            showModal={this.state.orderModalActive}
            handleClose={this.handleOrderModalClose}
            handleConfirm={this.handleOrderConfirm}
            onAnalysisNameChange={this.onAnalysisNameChange}
            amountHectars={this.state.forestHectars}
            forestName={this.state.forestName}
            pricePerHectar={this.state.pricePerHectar}
            analysisName={this.state.analysisName}
            isShowingAnalysisNameTooltip={
              this.state.isShowingAnalysisNameTooltip
            }
            analysisTypes={this.state.analysisTypes}
            changeAnalysisType={this.changeAnalysisType}
            analysisTypeChecked={this.state.analysisTypeChecked}
            userData={this.state.userData}
            orderType={this.state.orderType}
            maxRunsExceeded={this.state.maxRunsExceeded}
          />
          <DeleteForestModal
            showDeleteForestModal={this.state.modalDeleteActive}
            handleDeleteForestClose={this.handleDeleteModalClose}
            handleDeleteForestConfirm={this.handleDeleteConfirm}
            forestName={this.state.forestName}
            onDelete={this.deleteForest}
          />
        </div>
      </>
    )
  }
}

class MenuComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      matches: window.matchMedia('(min-width: 768px)').matches,
    }
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches })
    window.matchMedia('(min-width: 768px)').addListener(handler)
  }
  render() {
    return (
      <div className={css.contSecondMF}>
        {/* Checkbox */}
        <div className={css.elementCheck} key={this.props.index + 'check'}>
          <div>{/* <CheckBox /> */}</div>
        </div>

        {/* Image Placeholder */}
        <div className={css.elementThumbnail} key={this.props.index + 'image'}>
          {this.props.forest.thumbnail ? (
            <img
              alt={'Arboair'}
              src={this.props.forest.thumbnail}
              className={css.mosaicForestMF}
              onClick={() => this.props.editForest(this.props.index)}
            />
          ) : (
            ''
          )}
        </div>
        {/* Forest Name, Button Order Analysis */}
        <div className={css.elementForestName} key={this.props.index + 'name'}>
          Name: {this.props.forest.forestname}
          <br />
          ID: {this.props.forest.id}
          <br />
        </div>

        <div
          className={css.elementBtnA}
          key={this.props.index + 'order-analysis'}
        >
          <button
            className={css.btnThreeMF}
            variant='primary'
            onClick={() =>
              this.props.handleOrderPressed(
                this.props.forest,
                this.props.forest.forestname,
                this.props.forest.size
              )
            }
          >
            {this.state.matches ? ' Order analysis' : 'Order analysis'}
          </button>
        </div>

        {/* Latest Analysed */}
        <div
          className={css.elementLatestAn}
          key={this.props.index + 'latest_analysis'}
        >
          {this.props.forest.latest_analysis}
        </div>

        {/* Forest Condition */}
        <div
          className={css.elementCondition}
          key={this.props.index + 'condition'}
        >
          {this.props.forest.condition}
        </div>

        {/* Forest Size */}
        <div className={css.elementSize} key={this.props.index + 'size'}>
          <p
            style={
              this.state.matches ? { fontSize: '18px' } : { fontSize: '18px' }
            }
          >
            {this.props.forest.size.toFixed(1)} hectars
          </p>
        </div>

        {/* Icons */}
        <div className={css.elementBtnRow} key={this.props.index + 'buttons'}>
          {' '}
          <a href={'/upload?forestid=' + this.props.forest.id}>
            <AiOutlineUpload className={css.btnSecondaryA} as='button' />
          </a>
          <AiOutlineEdit
            className={css.btnSecondaryA}
            as='button'
            onClick={() => this.props.editForest(this.props.index)}
          />
          <AiOutlineDelete
            as='button'
            className={css.btnDelA}
            onClick={() =>
              this.props.handleDeleteForestPressed(
                this.props.forest.forestname,
                this.props.forest.size,
                this.props.forest.id
              )
            }
          />
        </div>
      </div>
    )
  }
}

const MapFilterSideNav = ({
  filterArray,
  changeFilterOpened,
  changeConditionClicked,
  conditions,
  startCreatedDate,
  endCreatedDate,
  startAnalysisDate,
  endAnalysisDate,
  minSizeInput,
  maxSizeInput,
  changeSize,
  changeDate,
  changeFilterActive,
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const settingButtons = {
    arrowOpen: <FaChevronRight />,
    arrowClose: <FaChevronLeft />,
  }

  const toggleSidebar = () => {
    setIsOpened(!isOpened)
  }

  const filters = filterArray.map(function (key, index) {
    let filterKey = key['name']
    let opened = key['opened']
    let filterName
    let filterOptions
    switch (filterKey) {
      case 'date':
        filterName = 'Date of forest creation'
        if (opened) {
          filterOptions = (
            <>
              <DatePicker
                key={filterKey + '_end'}
                maxDate={endCreatedDate}
                maxDateMessage='Start date needs to be before end date'
                label='Start Date'
                value={startCreatedDate}
                onChange={(e) => changeDate(e, 'startCreatedDate')}
                animateYearScrolling
              />
              <DatePicker
                key={filterKey + '_start'}
                maxDate={new Date()}
                minDate={startCreatedDate}
                maxDateMessage='End date cannot be after today'
                minDateMessage='End date cannot be before start date'
                label='End Date'
                value={endCreatedDate}
                onChange={(e) => changeDate(e, 'endCreatedDate')}
                animateYearScrolling
              />
            </>
          )
        }
        break
      case 'size':
        filterName = 'Size in ha'
        if (opened) {
          filterOptions = (
            <>
              <Form.Group className='mb-3' controlId='formGroupMinSize'>
                <Form.Label>Min Size</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Min Size'
                  onChange={(e) => changeSize(e)}
                  value={minSizeInput}
                  key={filterKey + '_min'}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='formGroupMaxSize'>
                <Form.Label>Max Size</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Max Size'
                  onChange={(e) => changeSize(e)}
                  value={maxSizeInput}
                  key={filterKey + '_max'}
                />
              </Form.Group>
            </>
          )
        }
        break
      case 'condition':
        filterName = 'Condition of forest'
        if (opened) {
          filterOptions = (
            <>
              {Object.keys(conditions).map((key) => (
                <Form.Check
                  type='checkbox'
                  label={conditions[key]['name']}
                  id={conditions[key]['name']}
                  key={conditions[key]['name']}
                  onChange={() => changeConditionClicked(key)}
                />
              ))}
            </>
          )
        }
        break
      case 'latest_analysis':
        filterName = 'Latest Analysis'
        if (opened) {
          filterOptions = (
            <>
              <DatePicker
                maxDate={endAnalysisDate}
                maxDateMessage='Start date needs to be before end date'
                label='Start Date'
                value={startAnalysisDate}
                onChange={(e) => changeDate(e, 'startAnalysisDate')}
                animateYearScrolling
                key={filterKey + '_end'}
              />
              <DatePicker
                maxDate={new Date()}
                minDate={startAnalysisDate}
                maxDateMessage='End date cannot be after today'
                minDateMessage='End date cannot be before start date'
                label='End Date'
                value={endAnalysisDate}
                onChange={(e) => changeDate(e, 'endAnalysisDate')}
                animateYearScrolling
                key={filterKey + '_start'}
              />
            </>
          )
        }
        break
      default:
        filterName = filterKey
        if (opened) {
          filterOptions = 'Dragons'
        }
        break
    }
    return (
      <div key={index} className={css.filterText} style={{ marginTop: '5px' }}>
        <Form.Check
          type='checkbox'
          // label={<h4 onClick={() => changeFilterOpened(index)}>{filterName}</h4>}
          id={filterName}
          key={filterName}
          onChange={() => changeFilterActive(index)}
        />
        <h4 onClick={() => changeFilterOpened(index)}>{filterName}</h4>
        {filterOptions}
      </div>
    )
  })

  return (
    <>
      <div
        className={css.contentSideBar}
        style={{ right: isOpened ? '0' : '-300px' }}
        backdrop='static'
        keyboard='false'
      >
        <div className={css.button} onClick={toggleSidebar}>
          {isOpened ? settingButtons.arrowOpen : settingButtons.arrowClose}
        </div>

        <div className={css.filters}>
          <h4>Filters</h4>
          <hr
            style={{
              margin: '0 0 5px 0',
              borderBottom: '0.5px solid rgba(231, 231, 231, 0.4)',
              opacity: 0.5,
            }}
          />
          {filters}
        </div>
      </div>

      {isOpened ? <div className={css.overlay} onClick={toggleSidebar} /> : ''}
    </>
  )
}
