// import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import React from 'react'
import { Button, Form, Modal, Overlay, Tooltip } from 'react-bootstrap'

function humanize(str) {
  var i,
    frags = str.split('_')
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(' ')
}

export default function ChecklistModal({
  showModal,
  handleClose,
  handleUpload,
  hectars,
  onAnalysisNameChange,
  failed,
  failedOrders,
  zeroOrders,
  showFailedOrders,
  onChangeSelectedOrder,
  isShowingAnalysisNameTooltip,
  analysisName,
  orderID,
  onChangeSelectedForest,
  forests,
  selectedForestTooltipRef,
  selectedForest,
  isDronePilot,
  showZeroOrders,
  picToFar,
  checklistModalRef,
  givenForestId,
  analysisTypes,
  onChangeAnalysisType,
  analysisTypeChecked,
  maxRunsExceeded,
  userType,
  isPremium,
  orderType,
  onAddExtraOrderData,
  extraOrderData,
}) {
  if (givenForestId) {
    const forest = forests.find((forest) => forest['id'] === givenForestId)
    var forestName = ''
    if (forest) {
      forestName = forest['forestname']
      onChangeSelectedForest(givenForestId)
    }
  }

  const maxHeight = 200
  const minHeight = 50

  // eslint-disable-next-line no-unused-vars
  const checkFlightHeight = (e) => {
    let value = e.target.value
    if (value < minHeight) e.target.value = minHeight
    if (value > maxHeight) e.target.value = maxHeight
    onAddExtraOrderData(e)
  }

  // let maxExceeded = false;
  // if (userData !== null) {
  //   let maxNumOfOrders = userData.max_runs;
  //   let userNumOfOrders = userData.num_of_orders;
  //   if ( userNumOfOrders >= maxNumOfOrders ) {
  //     maxExceeded = true
  //   };
  // };
  // console.log(analysisTypes)

  const analysisList = Object.keys(analysisTypes).map(function (key) {
    let statName = humanize(analysisTypes[key].name)
    return (
      <div key={key} className='mb-3'>
        <Form.Check
          type={'checkbox'}
          id={key}
          label={`${statName}`}
          checked={analysisTypes[key].active}
          onChange={() => onChangeAnalysisType(key)}
        />
      </div>
    )
  })

  const checkPrice = (userType, orderType, ha, maxExceeded) => {
    // console.log('User type', (userType));
    // console.log('Order Type', (orderType));
    let pricePerHektar = 10
    let price = ''
    let diff = orderType - userType
    // console.log(diff, maxExceeded)
    if (diff > 0 || maxExceeded) {
      let userBinary = userType.toString(2)
      let orderBinary = orderType.toString(2)
      while (userBinary.length < orderBinary.length) {
        // console.log('Fixing length', userBinary)
        userBinary = '0' + userBinary
      }
      // console.log('User', userBinary)
      // console.log('Order', orderBinary)
      let numOfDiffTypes = 0
      for (const index in userBinary) {
        let userChar = userBinary[index]
        let orderChar = orderBinary[index]
        if (maxExceeded) {
          if (orderChar === '1') {
            numOfDiffTypes += 1
          }
        } else if (userChar !== orderChar && userChar !== '1') {
          numOfDiffTypes += 1
        }
      }
      // console.log('Num of differences between userType and orderType', numOfDiffTypes);
      let orderPrice = pricePerHektar * ha * numOfDiffTypes
      if (maxExceeded) {
        price = (
          <>
            <h5 style={{ color: 'red' }}>Max number of runs exceeded</h5>
            <p>
              <b>
                Uploading this order will exceed the maximum alloted orders in
                your subscription and you will be debitted {pricePerHektar}{' '}
                kr/ha per type of order you choose
              </b>
            </p>
            <p>
              Based on the hectar size your final price will be around{' '}
              {orderPrice} kr and debitted after the order has completed.
            </p>
          </>
        )
      } else if (diff > 0) {
        price = (
          <>
            <h5 style={{ color: 'red' }}>
              Scope of order falls outside of usertype
            </h5>
            <p>
              <b>
                Since some of the selcted order types are outside the scope of
                your subscription you will be debitted {pricePerHektar} kr/ha
                per type difference between your subscription and the order
                types you have selected
              </b>
            </p>
            <p>
              Based on the hectar size your final price will be around{' '}
              {orderPrice} kr and debitted after the order has completed.
            </p>
          </>
        )
      }
    }
    return price
  }

  // const checkUserOrders = (user) => {
  //   // console.log('User type', (userType));
  //   // console.log('Order Type', (orderType));
  //   // console.log(user.num_of_orders);
  //   console.log(user)
  //   let maxExceeded = false;
  //   if (user !== null) {
  //     let maxNumOfOrders = user.max_runs;
  //     let userNumOfOrders = user.num_of_orders;

  //     if ( userNumOfOrders >= maxNumOfOrders ) {
  //       maxExceeded = true
  //     };
  //   };
  //   return maxExceeded
  // };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop='static'
      centered
      scrollable
      keyboard={false}
      ref={checklistModalRef}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload checklist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {picToFar ? (
          <p>
            <b>
              The pictures uploaded for this order do not have an overlap which
              will result in a correct mosaic.
              <br />
              If this is acceptable you can continue with the upload, other wise
              look over the circles on the map and remove the pictures not
              connected to the other ones.
            </b>
          </p>
        ) : (
          ''
        )}
        {failedOrders.length !== 0 && !isDronePilot ? (
          <p>
            Show Failed Orders
            <input
              className='checkbox'
              type='checkbox'
              name='showFailedOrders'
              onClick={showFailedOrders}
              value='true'
            />
          </p>
        ) : (
          ''
        )}

        {failed ? (
          <>
            <p>Failed Orders:</p>
            <Form>
              <Form.Group controlId='failedForm'>
                <Form.Control
                  as='select'
                  custom
                  onChange={onChangeSelectedOrder}
                >
                  <option></option>
                  {failedOrders.map((id) => (
                    <option key={id}>
                      {id[1]} - {id[2]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </>
        ) : (
          <>
            {zeroOrders.length !== 0 && (
              <p>
                Uploading for someone else?
                <input
                  className='checkbox ml-2'
                  type='checkbox'
                  name='dronePilot'
                  onClick={showZeroOrders}
                  value='true'
                />
              </p>
            )}

            {isDronePilot ? (
              <>
                <p>Pick the order:</p>
                <Form>
                  <Form.Group controlId='failedForm'>
                    <Form.Control
                      as='select'
                      custom
                      onChange={onChangeSelectedOrder}
                    >
                      <option></option>
                      {zeroOrders.map((id) => (
                        <option key={id}>
                          {id[1]} - {id[2]}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>
              </>
            ) : (
              <>
                <p>
                  The size of your order is approximately {hectars.toFixed(2)}
                  ha.{' '}
                </p>

                {!isPremium && orderType > 0
                  ? checkPrice(
                      userType,
                      orderType,
                      hectars.toFixed(2),
                      maxRunsExceeded
                    )
                  : ''}

                {/* {((isPremium)) ? 
      (
      checkUserOrders(userData)
      ) : 
      ('')} */}
                <div
                  ref={selectedForestTooltipRef}
                  style={{
                    display: 'flex',
                    float: 'center',
                    position: 'center',
                  }}
                >
                  <p>Forestname:&nbsp;</p>
                  {givenForestId ? (
                    forestName ? (
                      <b>{forestName}</b>
                    ) : (
                      <b>No forest with that ID could be found</b>
                    )
                  ) : (
                    <Form>
                      <Form.Group controlId='forestForm'>
                        <Form.Control
                          as='select'
                          placeholder={
                            selectedForest !== 0 ? selectedForest : ''
                          }
                          custom
                          onChange={onChangeSelectedForest}
                          ref={selectedForestTooltipRef}
                        >
                          <option></option>
                          {forests.map((forest) => (
                            <option key={forest['id']}>
                              {forest['forestname']}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  )}
                </div>
                <input
                  type='analysis_name'
                  id='analysis_name'
                  className='form-control validate'
                  placeholder={
                    analysisName !== '' ? analysisName : 'Analysis Name'
                  }
                  onChange={(e) =>
                    onAnalysisNameChange(
                      document.getElementById('analysis_name')
                    )
                  }
                />
                {false && (
                  <>
                    <p>What type of order do you want to create?</p>
                    {/* Hiding for now */}
                    <Form>{analysisList}</Form>
                  </>
                )}
                <Overlay
                  target={selectedForestTooltipRef.current}
                  show={
                    !(selectedForest !== 0 || selectedForest !== '') && !failed
                  }
                  placement='right'
                >
                  {(props) => (
                    <Tooltip id='overlay-example' {...props}>
                      Forest must be selected
                    </Tooltip>
                  )}
                </Overlay>
                <Overlay
                  target={document.getElementById('analysis_name')}
                  show={isShowingAnalysisNameTooltip}
                  placement='bottom'
                >
                  {(props) => (
                    <Tooltip id='overlay-example' {...props}>
                      Only letters and numbers
                    </Tooltip>
                  )}
                </Overlay>
              </>
            )}
          </>
        )}
        {/* <legend>Was it foggy?</legend>
        <div>
          <input
            type='radio'
            id='foggy'
            name='foggy'
            value='false'
            onClick={onAddExtraOrderData}
            // style={{ marginLeft: '10px' }}
          />
          <label for='foggy'>Yes</label>
          <input
            type='radio'
            id='notFoggy'
            name='foggy'
            value='true'
            onClick={onAddExtraOrderData}
            style={{ marginLeft: '10px' }}
          />
          <label for='notFoggy'>No</label>
        </div> */}
        {/* <legend>What height did you fly on?</legend>
        <em>Minimum {minHeight}m</em>
        <br></br>
        <em>Maximum {maxHeight}m</em>
        <div>
          <input
            type='number'
            id='flightHeight'
            name='flightHeight'
            placeholder={110}
            min={minHeight}
            max={maxHeight}
            onBlur={checkFlightHeight}
            value={extraOrderData['flightHeight']}
            onChange={onAddExtraOrderData}
            style={{ marginLeft: '10px' }}
          />
          <label for='flightHeight'>m</label>
        </div> */}
        <div style={{ marginTop: '20px' }} className='pl-3'>
          <p>
            Before uploading the images we need to know that you, as an uploader
            have made sure of some things:
          </p>
          <ul>
            <li>
              The images are collected according to regulation and all swedish
              law. E.g. Skyddslagen 2010:305 and Lagen om skydd för geografisk
              information.
            </li>
            <li>
              All images are excluded from what require circulation permission
              (Spridningstillstånd). E.g. areas from where you do not have the
              owners permission, images showing the sky, non-public man-made
              objects like electric grid and more.{' '}
              <a href='https://www.lantmateriet.se/sv/webb/spridningstillstand/undantag-fran-spridningstillstand/'>
                See exceptions at Lantmäteriet
              </a>{' '}
            </li>
            <li>
              All images are collected via instructions from the Arboair drone
              guide.
            </li>
            <li>The images uploaded do not contain anything illegal.</li>
            <li>No images contain identifiable persons. </li>
            <li>No images uploaded are blurry.</li>
          </ul>
          <p></p>
          <p>
            <b>
              By clicking "I confirm, Upload!" you confirm, that you've read and
              understood the above points.
            </b>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Cancel
        </Button>

        {(failed && orderID !== 0) ||
        //
        ((selectedForest !== 0 || Boolean(forestName)) &&
          !failed &&
          analysisName !== null &&
          orderType > 0) ||
        //
        (isDronePilot && orderID !== 0) ? (
          //
          <Button
            variant='primary'
            onClick={() => handleUpload(maxRunsExceeded ? true : false)}
          >
            I confirm, Upload!
          </Button>
        ) : (
          ' '
        )}
      </Modal.Footer>
    </Modal>
  )
}
