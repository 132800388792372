import React, { 
  // useState 
} from "react";
import { 
  Modal, 
  Button, 
  // Spinner 
} from "react-bootstrap";

export default function TermsModal({ showModal, handleClose, handleDone }) {
  // const [isSpinner, setIsSpinner] = useState(false);

  // const handleRegister = () => {
  //   // setIsSpinner(true);
  //   handleDone();
  // };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      centered
      scrollable
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Terms and Conditions Arboair AB</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <p>
        1. Application<br/> 
        1.1. Terms and Conditions apply to Services provided by Arboair to a Customer – unless otherwise expressly agreed separately in writing.
      </p>
      <p>
        1.2. Before Customer signs up for an account and user license on Arboair’s website, Customer agrees to read Terms and Conditions carefully. If Customer completes the registration, Customer thereby agrees to be bound by Terms and Conditions.
      </p>
      
      <p>
        2. Services<br/> 
        Description of Services<br/> 
        2.1. Customers gain access to Services by signing up for an account and user license at Arboair’s website and then receiving order confirmation and login details for the Platform which can be used either through a website application or an iOS application. By the Platform, Arboair offers certain analysis services on forest photographs either (1) uploaded by Customer or Arboair, or (2) that comes from open satellite data. What is included in Services depends on which license and additional services Customer has ordered at the registration or after the registration. Arboair offers three kinds of licenses: freemium (free version), premium (paid version) and enterprise (paid version). As an additional service, Arboair offers to collect aerial photographs of the part of the forest that Customer wants to have analysed by using drones. More information on what is included in the licenses and additional services Arboair offers are published on https://arboair.com/pricing/.
      </p>
      <p>
        Prices<br/> 
        2.2. The prices for the licenses and additional services are listed in the current price list at https://arboair.com/pricing/. Arboair reserves the right to change these prices. The new prices will take effect at the next contract period. Customer has a right to terminate its user license to the next contract period if Customer does not accept the new prices.
      </p>
      <p>
        Terms of payment<br/> 
        2.3. Payment is always due 30 days after the invoice is issued if nothing else has been agreed upon separately.
      </p>
      <p>
        2.4. Arboair charges an administrative fee of 60 SEK for reminders on late payments. Arboair reserves the right to charge late payment interest with 10 % and compensation for the cost of collection of payment.
      </p>
      
      <p>
        3. Customer’s obligations<br/> 
        3.1. Customer must not upload anything other than photographs – and notices as per 9.7 – to the Platform.
      </p>
      <p>
        3.2. If Customer uploads photographs to the Platform, Customer must comply with the following:<br/>
         1. Customer may only upload photographs collected in compliance with all applicable laws.<br/>
          2. The photograph must not contain anything that is illegal.<br/>
           3. Customer must either (1) own all intellectual property rights to the uploaded photographs or (2) have the right to sublicense the photographs in accordance with 5.6.<br/>
            4. The photograph must not contain any personal data, where personal data has the same definition as in regulation (EU) 2016/679.<br/>
             5. The photograph must be collected in accordance with <a href="https://www.arboair.com/drone-guide/">Arboair’s instructions for data collection</a>.
      </p>
      <p>
        3.3. Customer ensures that photographs uploaded to the Platform do not contain any malware.
      </p>

      <p>
        4. Indemnification<br/> 
        4.1. Customer undertakes to indemnify and hold Arboair harmless from and against all losses, damages and costs of any kind (including reasonable attorney fees and costs) arising out of or related to Customer’s breach of 3.1, 3.2 and 3.3.
      </p>
      
      <p>
        5. Intellectual property rights and licenses<br/> 
        No transfer or waiver<br/> 
        5.1. Nothing in Terms and Conditions will create, imply or be construed as a transfer or waiver of any kind of any intellectual property rights.
      </p>
      <p>Platform and its content<br/> 
        5.2. Arboair, or its licensors, owns all intellectual property rights – including, without limitation, copyright in source code, object code and other computer software and databases – to the Platform and its contents.
      </p>
      <p>
        5.3. It is not permitted to copy, reproduce or otherwise use the Platform or its contents for anything other than for the purposes of Services and what is prescribed in Terms and Conditions.
      </p>
      <p>
        User license<br/> 
        5.4. By sending order confirmation and login details, Arboair grants to Customer a personal non-exclusive limited user license to gain access to Services and use the Platform in accordance with Terms and Conditions. The license is valid until Terms and Conditions have been terminated. For the avoidance of doubt, Customer must not assign, sub-license, lend, transfer its license or in any other way give a third party access to the Platform and contents without Arboair’s prior written consent. Notwithstanding the foregoing, Customer may give a third party viewing rights to Customer’s account if that third party has a user license and is bound by Terms and Conditions.
      </p>
      <p>Photographs<br/>
        5.5. Arboair owns all intellectual property rights to photographs provided by Arboair.
      </p>
      <p>
        5.6. Customer owns all intellectual property rights to photographs that Customer uploads to the Platform or has the right to sublicense in accordance with this 5.6. By uploading photographs Customer grants to Arboair a worldwide, irrevocable, perpetual and royalty-free license to use the photographs uploaded in the purposes of (1) performing Services, (2) for Arboair’s development of Services (e.g. machine learning) and (3) to sublicense the photographs on the same terms as Arboair’s license under this 5.6.
      </p>
      <p>Results<br/> 
        5.7. In Terms and Conditions “Results” means the result generated in the performance of Services, such as, but not limited to, analysed photographs by Arboair. Arboair owns all intellectual property rights to the Results. By making the Results available for Customer, Arboair grants Customer a right to use the Results in Customer’s business.
      </p>

      <p>
        6. Confidentiality<br/>
        Confidential Information<br/>
        6.1. For the purposes of Terms and Conditions “Confidential Information” means all information, irrespective of form (e.g. oral, written, visual) and irrespective of wheter marked “Confidential” or not, disclosed by Arboair or Customer (“Disclosing Party”) to the other party (the “Receiving Party”) and relating to the Disclosing Party, for example – but not limited to – information regarding its intellectual property rights, software and technology.
      </p>
      <p>
        6.2. Confidential Information does not include information which, as shown by reference to written records:<br/>
         • is or becomes publicly available without any breach of Terms and Conditions,<br/> 
         • was known to the Receiving Party at the time of disclosure by the Disclosing Party,<br/>
          • is received from a third party without a restriction on further disclosure.
      </p>
      <p>
        Obligation not to disclose<br/>
         6.3. The Receiving Party may not disclose Confidential Information to anyone without the Disclosing Party’s prior written consent. The Receiving Party must take all reasonable measures to avoid disclosure, dissemination or unauthorized use of Confidential Information.
      </p>
      <p>
        6.4. The Receiving Party may only disclose Confidential Information to such employees of the Receiving Party that has a direct need to know it. The Receiving Party must ensure that the aforementioned employees are made aware of and that they for their own part adhere to these contractual obligations regarding the confidentiality of Confidential Information.
      </p>
      <p>
        6.5. The obligation to not disclose Confidential Information will not prevent the Receiving Party to disclose information which is required to disclose by law or by a governmental or administrative agency or body or decision by a court of law, but then only after first notifying the Disclosing Party of the required disclosure.
      </p>
      <p>
        Use of Confidential Information<br/>
        6.6. The Receiving Party may only use Confidential Information in accordance with Terms and Conditions.
      </p>
      <p>
        Ownership of information<br/>
        6.7. All information disclosed pursuant to Terms and Conditions will remain the property of the Disclosing Party.
      </p>
      
      <p>
        7. Liability, remedies and limitation of liability<br/>
        Expectations on Services<br/>
        7.1. Services, including all software, are provided “as is” and “as available”. Arboair makes no expressed or implied warranties. Customer is aware that Services is not faultless, Customer therefore accepts that Services may contain certain minor inaccuracies and errors.
      </p>
      <p>
        Defective Services<br/>
        7.2. If Arboair is liable for any defective services, Arboair may at Arboair’s sole discretion choose to rectify the defect or offer Customer a reduction of the price. The remedies contained in Terms and Conditions regarding remedies for defective services are the only remedies available and are to the exclusion of any other remedy contained in any law, statute, and regulation or similar.
      </p>
      <p>
        7.3. The accuracy and quality of Services is directly dependent on the photographs provided and used. Arboair must not be held liable for defective services if the defective service is a consequence of that Customer has provided photographs that do not follow Arboair’s instructions for data collection or are otherwise of poor quality.
      </p>
      <p>
        7.4. Arboair will make reasonable efforts to keep the Platform available to Customer at all times. However, some interruptions, for example but not limited to planned upgrades and maintenance or unplanned operational disturbances, may occur. Customer is not entitled to compensation for any kinds of interruptions in Services.
      </p>
      <p>
        7.5. If Customer wishes to claim that Services is defective, Customer must give notice thereof to Arboair, in accordance with 9.7, within seven days after Customer has detected, or should have detected the defect. If Customer fails to give notice within the time prescribed above, Customer will forfeit the right to bring claims based upon the defect.
      </p>
      <p>
        Arboair’s delay<br/>
        7.6. Services will be deemed to be delayed if Arboair has not completed Services in the agreed time.
      </p>
      <p>
        7.7. If Arboair is unable to complete Services by the agreed time Arboair must inform Customer of this without undue delay and also explain to Customer the reason for the delay and as far as possible indicate a date on which Services can be expected to be completed.
      </p>
      <p>
        7.8. If a delay in completing Services is caused by circumstances that constitutes Force Majeure (as defined in 9.1) or caused by Customer’s act or omission, the completion date will be extended to an extent that will be considered reasonable under the given circumstances.
      </p>
      <p>
        7.9. If a delay in completing Services is caused by circumstances that Arboair is liable for, Customer may terminate Services with immediate effect. If Customer has terminated Services in accordance with this 7.9, Arboair will not be entitled to payment for Services.
      </p>
      <p>
        7.10. If Customer wishes to terminate Services in accordance with 7.9, Customer must give notice thereof to Arboair, in accordance with 9.7, the day after Customer was informed of the delay or otherwise became aware of the delay. If Customer fails to give notice within the time prescribed above, Customer will forfeit the right to terminate Services.
      </p>
      <p>
        7.11. The remedies contained in Terms and Conditions regarding remedies for delay (termination) are the only remedies available and are to the exclusion of any other remedy contained in any law, statute, and regulation or similar.
      </p>
      <p>
        Customer’s delay<br/>
        7.12. If Customer does not pay for Services in accordance with 2.3 Arboair may terminate Services with immediate effect and make the Platform inaccessible to Customer until Customer has paid.
      </p>
      <p>
        Limitations of liability<br/>
        7.13. For the avoidance of doubt, Arboair must not be held liable for any damages, neither direct or indirect damages, with regards to any defective services or delay. Arboair can under no circumstance be held liable for damages for indirect loss of any kind or nature, including but not limited to loss of profit or any other indirect consequential losses.
      </p>
      <p>
        7.14. Notwithstanding anything to the contrary contained in Terms and Conditions, the total liability of Arboair with respect to all claims arising out of the performance or non-performance of obligations under Terms and Conditions must not in the aggregate exceed what Customer has paid.
      </p>

      <p>
        8. Personal data<br/>
        8.1. Personal data relating to a Customer, which Arboair receives from Customer or Customer&#8217;s employees, consisting of name, address, telephone number and e-mail address, are processed in accordance with applicable law and <a href="https://www.arboair.com/privacy-policy/">Arboair’s integrity policy.</a>
      </p>

      <p>
        9. Miscellaneous<br/>
        Force majeure<br/>
        9.1. In Terms and Conditions, the expression “Force Majeure” means each – or combinations – of the following causes affecting the performance by Arboair of its obligations according to Terms and Conditions:<br/>
        a) pandemic, explosion, fire, accident, lightning, tempest, hurricane, flood, fog or bad weather (including rain);<br/>
        b) outbreak of war, hostilities, riot, civil disturbance, act of terrorism;<br/>
        c) failure of third party suppliers;<br/>
        d) compliance with any law or governmental order; and<br/>
        e) any cause or circumstances whatsoever beyond the reasonable control of Arboair.
      </p>
      <p>
        For the avoidance of doubt, the events above are examples only and the examples includes events of Force Majeure but is not limited to the events set out above.
      </p>
      <p>
        9.2. If Arboair is prevented or delayed in the performance of any of its obligations – under Terms and Conditions – by any of the events in clause 9.1 Arboair must as soon as practicable serve notice in writing to Customer, specifying the nature and extent of the circumstances giving rise to Force Majeure, and will subject to service of such notice and to clause 9.4 have no liability in respect of the performance of such of its obligations as are prevented by the Force Majeure events during the continuation of such events, and for such time after they cease as is necessary for Arboair, using all reasonable endeavours to recommence its affected operations in order for it to perform its obligations.
      </p>
      <p>
        9.3. If Arboair is prevented from performing its obligations for a continuous period in excess of one month, either Arboair or Customer may terminate Services, in which case Arboair will have no liability to Customer.
      </p>
      <p>
        9.4. Arboair must use reasonable endeavours to bring the Force Majeure event to a close.
      </p>
      <p>
        Amendment<br/>
        9.5. The current version of Terms and Conditions is always published on www.arboair.com/terms-of-service/. Arboair may amend and add terms to Terms and Conditions from time to time. Arboair must notify Customer about the amendments in accordance with 9.7. Amendments to the Terms and Conditions enters into force three weeks after the notice is received according to 9.7. If Customer does not object to the amendments within ten days after Customer received the notice, it will be deemed that Customer has accepted the amendment for continued use of Services. If Customer objects to the amendments within the time period and if Arboair and Customer do not agree on a solution, Customer may terminate Services and its user license prematurely. For premature termination, Customer must pay for Services that Arboair performed until the premature termination.
      </p>
      <p>
        Entire agreement<br/>
        9.6. Terms and Conditions, including orders and order confirmations, represents the entire understanding and agreement between Arboair and Customer with respect to Services and supersedes all prior negotiations, understandings and agreements relating to Services – exept written agreements which expressely states that the terms in that agreement apply instead of Terms and Conditions.
      </p>
      <p>
        Notices<br/>
        9.7. All notices between Arboair and Customer regarding Services must be uploaded to the Platform. A notice will be considered to have been received by the recipient the day after it was uploaded.
      </p>
      <p>
        Severability<br/>
        9.8. If any clause of Terms and Conditions or part thereof is found by any court or administrative body of competent jurisdiction to be illegal, invalid or unenforceable, the legality, validity or enforceability of the remainder of Terms and Conditions will not be affected.
      </p>
      <p>
        Waiver<br/>
        9.9. Nothing will be construed as a waiver under Terms and Conditons unlesss a document to that effect has been signed by Arboair and Customer or notice to that effect has been given. The failure of Arboair to exercise any right under Terms and Conditions must not be deemed to constitute a waiver of the right to exercise any such right in the future.
      </p>
      <p>
        Terms and termination<br/>
        9.10. Terms and Conditions will commence in force from the time Customer has signed up for a user license and completed the registration at Arboair’s website. If Customer has chosen a monthly subscription, Terms and Condition will remain in force for one month and is then extended by one month at a time, unless – subject to 2.2 – Services is terminated no later than one week before Terms and Conditions is extended. If Customer has chosen an annual subscription, Terms and Condition will remain in force for one year and is then extended by one year at a time, unless – subject to 2.2 – Services is terminated no later than one month before Terms and Conditions is extended.
      </p>
      <p>
        9.11. The following sections and subsections will remain in force after the termination of Terms and Conditions: 4 (indemnification), 5.1 (no transfer or waiver), 5.2 (Platform and its content), 5.3 (Platform and its content), 5.5 (photographs provided by Arboair), 5.6 (Arboair’s license), 5.7 (Results and license), 6 (Confidentiality), 7.13 (Limitations of liability), 7.14 (Limitations of liability), 9.12 (Dispute resolution), 9.13 (Governing law).
      </p>
      <p>
        Dispute resolution<br/>
        9.12. Any dispute, controversy or claim arising out of or in connection with the Terms and Conditions, or the breach, termination or invalidity thereof, shall be finally settled by arbitration in accordance with the Rules for Expedited Arbitrations of the Arbitration Institute of the Stockholm Chamber of Commerce. The seat of arbitration shall be Linköping. The language to be used in the arbitral proceedings shall be Swedish.
      </p>
      <p>
        Governing law<br/>
        9.13. These terms and conditions are governed by the substantive laws of Sweden.
      </p>

      <p>
        10. Definitions<br/>
        10.1. Arboair: Arboair AB, company registration number 559231-4156.<br/>
        Customer: means a natural person or legal entity who trades for purposes in connection with its business operations (i.e. not consumers).<br/>
        Platform: Arboair’s digital platform where Services are provided.<br/>
        Services: All services provided by Arboair.<br/>
        Terms and Conditions: Arboair’s terms and conditions.
      </p>

      <p>
        Effective as of 2021-03-01
      </p>

        <p>
          <b>
            By clicking "Continue" you confirm, that you've read and understood
            the terms and conditions presented above
          </b>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
