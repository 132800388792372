// Packages imports
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Media imports
import bgvideo from "../../videos/forest_video.mov";

// css imports
import css from "../../styles/VideoBackground.module.css";

class VideoBackground extends Component {
  render() {
    return (
      <Fragment>
        {
          // play the video in the background for the logging screen
          !this.props.isAuthenticated ? (
            <Fragment>
              <div className={css.fullScreenVideoWrap}>
                <video className={css.video} src={bgvideo} autoPlay="(true)" loop="(true)" controls muted></video>
              </div>
              <div className={css.videoOverlay}></div>
            </Fragment>
          ) : (
            ""
          )
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(VideoBackground);
