import React from "react";
import {Modal, Button} from "react-bootstrap"

export default function ForestInfoModal({forestStats,showModal, handleClose}) {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Additional forest info</Modal.Title>
      </Modal.Header>
  <Modal.Body>
    {`Your forest is ${forestStats.size} hectars`} <br/> 
    {`The cost of inspection would be ${Math.round((forestStats.size*40 + Number.EPSILON) * 100) / 100} kr`} <br/> 
    {`The cost of inspection with ordered flight would be ${Math.round(((forestStats.size*80+1000) + Number.EPSILON) * 100) / 100} kr`}
  </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
