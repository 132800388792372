import React, { useState } from "react";
import { Modal } from "react-modal-overlay";
import { 
  // Modal, 
  // Button, 
  InputGroup, 
  FormControl 
  // Form 
} from "react-bootstrap";
import 'react-modal-overlay/dist/index.css'

import css from "../../styles/MyForests.module.css";
import "../../styles/AdminSubpages.css";
import Select from "react-select";


const customStyles = {
  menu: (provided) => ({
    ...provided,
    background: "#F9F9F9;",
    marginTop: "5px",
    padding: "2px 4px"
  }),

  input: (provided) => ({
    ...provided,
    height: "100%",
    padding: "0.4rem 0"
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none"
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    color: "#131313"
  }),
  multiValue: (provided) => ({
    ...provided,
    background: "#14ACC8 !important",
    width: "100px",
    color:  "#F9F9F9" ,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color:  "#F9F9F9" ,
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? "#14ACC8 !important" : "#F9F9F9 !important",
    color: state.isFocused ? "#FFFFFF" : "#131313",
    cursor: "pointer"
  }),

  control: (provided) => ({
    ...provided,
    border: "2px solid #f0f0f0 !important",
    boxShadow: "none",
    cursor: "pointer",
    padding: "0",
    margin: "0",
    // width: "150px"
  }),

};

const customStyles2 = {
  menu: (provided) => ({
    ...provided,
    background: "#F9F9F9;",
    marginTop: "5px",
    padding: "2px 4px",
    width: "150px"
  }),

  input: (provided) => ({
    ...provided,
    height: "100%",
    padding: "0.4rem 0"

  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none"
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    color: "#131313"
  }),

  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? "#14ACC8 !important" : "#F9F9F9 !important",
    color: state.isFocused ? "#FFFFFF" : "#131313",
    cursor: "pointer"
  }),

  control: (provided) => ({
    ...provided,
    border: "2px solid #f0f0f0 !important",
    boxShadow: "none",
    cursor: "pointer",
    padding: "0",
    margin: "0",
    height: "50px",
    width: "170px"
  }),
};

export default function UsersToGroup({
        usersInRoot,
        addUserToGroup,
        teamGroup,
      }) {
    // React state to manage selected options
    const [selectedOptions, setSelectedOptions] = useState();
    // const [doRender, changeDoRender] = useState(true);
    // const [group, setGroup] = useState("");
    const [role, setRole] = useState("");
    

    // Array of all options
    // const UsersList = [
    //   { value: "red", label: "Red" },
    //   { value: "green", label: "Green" },
    //   { value: "yellow", label: "Yellow" },
    //   { value: "blue", label: "Blue" },
    //   { value: "white", label: "White" }
    // ];
    
    // const Groups = [
    //   { value: '1', label: 'Group1' },
    //   { value: '2', label: 'Group2' },
    //   { value: '3', label: 'Group3' }
    // ];
    //Admin, User, External_Viewer, Internal_Viewer
    const Roles = [
      { value: 'Admin', label: 'Admin' },
      { value: 'User', label: 'User' },
      { value: 'External_Viewer', label: 'External_Viewer' },
      { value: 'Internal_Viewer', label: 'Internal_Viewer' },
    ];
  
  const [isOpen, setIsOpen] = useState(false);

  // Function triggered on selection
   function handleSelect(data) {
    setSelectedOptions(data);
    
   }
  //  console.log(usersInRoot)
  let d = []
  for (let usr in usersInRoot) {
    // console.log(usersInRoot[usr]);
    d.push(
      {value: usersInRoot[usr], label: usersInRoot[usr]}
    )
  }


  // if (typeof(data) === 'object') {
  //   console.log('Not object')
  //   changeDoRender(true);
  // };

  return (
    <div>

        <button onClick={() => setIsOpen(true)} className={css.btnAdmin} >
          Add Users
        </button>

      <Modal show={isOpen} closeModal={() => setIsOpen(false)}>
          <div className="dropdownSelect">
            <h3>Choose from existing members</h3>
            <div className="dropdown-container">
              <Select
                options={d}
                placeholder="Select Users"
                value={selectedOptions}
                onChange={(e) => {
                  handleSelect(e)
                }}
                isSearchable={true}
                isMulti
                styles={customStyles}
              />
            </div>
          </div>
          <h3>or type the emails of new ones</h3>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Recipient's email"
                aria-label="Recipient's email"
                id='recipient_emails'
                aria-describedby="basic-addon2"
              />
            </InputGroup>
          <br></br>
          {/* <div  className="react-select-container">
            <Select
            styles={customStyles2}
            options={Groups}
            placeholder="Group"
            onChange={(e) => {
              setGroup(e)
            }}
            value={group}
            />
          </div> */}
          <div  className="react-select-container">
            <Select
            styles={customStyles2}
            options={Roles}
            placeholder="Role"
            onChange={(e) => {
              setRole(e)
            }}
            value={role}
            />
          </div>
        { role !== '' ? (
          <button style={{ marginTop: 50 }} className={css.btnThreeMF} 
            onClick={() => {
            console.log("send users,", selectedOptions);
            console.log(document.getElementById("recipient_emails").value)
            let emails = document.getElementById("recipient_emails").value
            emails = emails.replace(/\s/g, '');
            emails = emails.split(',');
            
            let usersToAdd = []
            for (let idx in selectedOptions) {
                usersToAdd.push(selectedOptions[idx].value.split("--").pop())
            }
            usersToAdd.push(...emails)


            console.log(teamGroup, usersToAdd, role.value);
            
            addUserToGroup(teamGroup, usersToAdd, role.value).then(setIsOpen(false))
            
            }}> 
            Invite 
          </button>
        ) : ( ' ' )}
      </Modal>
    </div>
  );
  
}
