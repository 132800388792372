// Package import
import axios from "axios";
import { tokenConfig } from "./auth";
import { 
  USER_PROFILE_LOADED, 
  USER_PROFILE_FAILED, 
  USER_PROFILE_UPDATED,
  USER_STATS,
} from "../actions/types";

const ip = process.env.REACT_APP_BACKEND_DNS;

// Get User Profile
export const getUserProfile = () => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/user_profile/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USER_PROFILE_LOADED,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      dispatch({
          type: USER_PROFILE_FAILED,
      })
    });
};

// NEW -- update user info
export const updateUserProfile = (data) => async(dispatch, getState) => {
  return await axios
    //push/update new data 
    .put(`${ip}/user_profile/`, data, tokenConfig(getState))
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: USER_PROFILE_UPDATED,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      dispatch({
          type: USER_PROFILE_FAILED,
      })
    });
};

export const getUserStats = () => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/user_stats/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USER_STATS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      dispatch({
          type: USER_PROFILE_FAILED,
      })
    });
};