import {
  GET_ORDERS,
  GET_ORDER,
  DELETE_ORDER,
  ADD_IMAGES,
  FINISH_IMAGE_UPLOAD,
  ADD_IMAGES_FINSHED,
  UPLOADED_IMAGE,
  ADD_NEW_ORDER,
  ADD_NEW_ORDER_FAILED,
  CHANGE_PAGE,
  GET_ZERO_ORDERS,
  SHARING_LINK,
  REMOVE_SHARING_LINK,
  LINK_ACTIVATED,
  LINK_ACTIVATION_DENIED,
  LINK_ACTIVATION_EXPIRED,
  GET_FAILED_ORDERS,
  DELETE_FAILED_ORDERIMAGE,
  DEBUG,
  SUPPORTED_DRONES,
  EMAIL_NOT_VALID,
} from "../actions/types.js";

const initialState = {
  orders: [],
  zero_orders: [],
  failed_orders: [],
  order_detail: null,
  current_order_id: null,
  upload_running: false,
  images_to_upload: 0,
  tot_images: 0,
  current_page: 0,
  tot_pages: 0,
  sharing_link: null,
  link_activated: null,
  objects_per_page: 10,
  sort_method: "date_modified",
  sort_acs: "-",
  search_key: '',
  supported_drones: [],
  all_orders: [],
  email_not_valid: null
};

export default function orderReducer (state = initialState, action) {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        all_orders: action.payload,
      };
    case GET_ZERO_ORDERS:
      return {
        ...state,
        zero_orders: action.payload,
      };
    case GET_FAILED_ORDERS:
      return {
        ...state,
        failed_orders: action.payload,
      };
    case DELETE_FAILED_ORDERIMAGE:
      return {
        ...state,
      };
    case GET_ORDER:
      return {
        ...state,
        order_detail: action.payload,
      };
    case DELETE_ORDER:
      return {
        ...state,
        orders: state.orders.filter((order) => order.id !== action.payload),
      };
    case UPLOADED_IMAGE:
      return {
        ...state,
        images_to_upload: state.images_to_upload - 1,
      };
    case ADD_NEW_ORDER:
      return {
        ...state,
        current_order_id: action.payload,
      };
    case ADD_NEW_ORDER_FAILED:
      return {
        ...state,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        current_page: action.payload[0],
        orders: action.payload[1],
        tot_pages: action.payload[2],
        objects_per_page: action.payload[3],
        sort_method: action.payload[4],
        sort_acs: action.payload[5],
        search_key: action.payload[6],
      };
    case SHARING_LINK:
      return {
        ...state,
        sharing_link: action.payload,
      };
    case EMAIL_NOT_VALID:
      return {
        ...state,
        email_not_valid: action.payload,
      };
    case REMOVE_SHARING_LINK:
      return {
        ...state,
        sharing_link: null,
      };
    case LINK_ACTIVATED:
      return {
        ...state,
        link_activated: action.payload,
      };
    case LINK_ACTIVATION_DENIED:
      return {
        ...state,
        link_activated: false,
      };
    case LINK_ACTIVATION_EXPIRED:
      return {
        ...state,
        link_activated: 'expired',
      };
    case ADD_IMAGES:
      return {
        ...state,
        current_order_id: action.payload[0],
        upload_running: true,
        images_to_upload: action.payload[1],
        tot_images: action.payload[1],
      };
    case ADD_IMAGES_FINSHED:
      return {
        ...state,
        current_order_id: null,
      };
    case FINISH_IMAGE_UPLOAD:
      return {
        ...state,
        upload_running: false,
        tot_images: 0,
      };
    case SUPPORTED_DRONES:
      return{
        ...state,
        supported_drones: action.payload,
      }
    case DEBUG:
      return {...state};
    default:
      return state;
  }
}
