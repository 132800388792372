import React, { Fragment, Component } from "react";
import ReactDOM from 'react-dom'
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap"
import PropTypes from "prop-types";
import { 
  point, 
  buffer, 
  union, 
  area, 
  getType, 
  getCoords,
  polygon,
} from "@turf/turf";
import L from "leaflet";

// css imports
import css from "../../styles/MapSubPage.module.css";
import { connect } from "react-redux";
import { maxHectars } from "../../GLOBAR_PARAMETERS";
// import { getElevation } from "../../actions/dropzone";

var map = null;

export class LeafletMapView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 12.550343,
      lat: 55.70651,
      zoom: 16,
      showLoadingModal: true,
    };
    this.mapContainer = React.createRef();
  }
  static propTypes = {
    points: PropTypes.array,
    point_type: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
    areaUpdate: PropTypes.func.isRequired,
    pictureToFar: PropTypes.func.isRequired,
    pictureOutOfBounds: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.createMap();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.points !== this.props.points) {
      this.createMap();
    }
  }

  createMap = async () => {
    //Only import whats needed from turf
    this.props.pictureToFar(false);
    this.props.pictureOutOfBounds(false);
    const { lng, lat, zoom } = this.state;
    if ( map !== null ) { map.remove(); }
    // map.remove();
    map = L.map(this.mapContainer.current, {
      center: [lng, lat],
      zoom: zoom
    });


    L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    }).addTo(map);

    // ADD POINTS TO MAP IF THERE ARE ANY
    //Declare buffer union and then create a buffer around every point, adding the new buffer to the union    
    let elevationData = [];
    let maxLocationsPerRequest = 512;
    let loops = Math.ceil(this.props.points.length / maxLocationsPerRequest);

    for (let loop = 0; loop < loops; loop++) {
      // console.log(elevationData)
      // console.log(loop)
      let min = loop * maxLocationsPerRequest;
      let max = (loop + 1) * maxLocationsPerRequest;
      // console.log(min, max)
      let tempArray = this.props.points.slice(min, max);
      // console.log(temp)
      let res = await this.props.getElevation(tempArray);
      elevationData = elevationData.concat(res);
    };

    if (this.props.points) {
      this.addPointsToMap(map);
      var {bufferUnion, polyArea} = this.createBufferUnion(this.props.points, elevationData);
    };

    //Check type of hull & calculate area
    try {
      var typeOfHull = getType(bufferUnion);

      //Area calculation and reporting
      var bufferArea = area(bufferUnion) / 10000;
      this.props.areaUpdate(bufferArea);
      // console.log(bufferArea, polyArea);
      if ( polyArea > bufferArea && polyArea > maxHectars) {
        this.props.pictureOutOfBounds(true);
      };
    } catch(e) {
      // console.log(e)
    };

    //Draw map
    if ( typeOfHull ) {
    if ( typeOfHull !== 'Polygon' ) {
      this.props.pictureToFar(true);
      bufferUnion['geometry']['coordinates'].forEach((buffer) => {
        var randomColor = '#'+(Math.random() * 0xfffff * 1000000).toString(16).slice(0, 6);
        this.addBufferToMap(map, buffer, randomColor);
      });
    } else {
      this.addBufferToMap(map, bufferUnion, '#0080ff');
    }};
  };

  //Draws the circles on the map
  addBufferToMap = (map, buffer, color) => {
    var bufferStyle = {
      'fillColor': color,
      'fillOpacity': 0.5,
      'color': '#000'
    };
    var bufferUnionLayer = [{
      'type': 'Feature',
      'geometry': {
        'type': 'Polygon',
        'coordinates': getCoords(buffer)
      }
    }]
    L.geoJSON(bufferUnionLayer, {
      style: bufferStyle
    }).addTo(map);
  };

  //Creates a buffer union for each picture point
  //Also create a polygon and calculates its area
  createBufferUnion = (points, elevationData) => {
    let bufferUnion = null;
    let fieldOfView = 0;
    let bufferDistance = 0;
    let turfPoint = null;
    let coordArray = [];
    points.forEach((picPoint, index) => {
      let alt = picPoint['alt'];
      let sensorWidth = picPoint['sensorWidth'];
      let focalLength = picPoint['focalLength'];
      let height = 120;
      if (elevationData[index] !== undefined) {
        let elevation = elevationData[index]['elevation'];
        height = alt - elevation;
      };
      let angleOfDistance = 2 * Math.atan(sensorWidth / (2 * focalLength));
      fieldOfView = 2 * (Math.tan(angleOfDistance / 2 ) * height);
      picPoint.fov = fieldOfView
      // console.log(alt, elevation, sensorWidth, focalLength, fieldOfView)
      // console.log(alt, height)
      turfPoint = point([picPoint['long'], picPoint['lat']], {name: picPoint.name});
      bufferDistance = (fieldOfView / 2) / 1000;
      if ( bufferDistance === 0 ) {
        bufferDistance = 0.001
      }
      if ( index === 0 ) {
        bufferUnion = buffer(turfPoint, bufferDistance);
      } else {
        bufferUnion = union(bufferUnion, buffer(turfPoint, bufferDistance));
      }
      coordArray.push([picPoint['long'], picPoint['lat']]);
      // coordArray.push(turfPoint);
    })
    coordArray.push([points[0]['long'], points[0]['lat']]);
    try {
      let turfPolygon = polygon([coordArray]);
      var polyArea = area(turfPolygon) / 10000;
      // this.addBufferToMap(map, turfPolygon, '#00FF00');
    } catch {
      //Reason for try catch is that if it is only a few pics 
      //or on a straight line no polygon can be created from the coordinates
    }
    return {bufferUnion, polyArea};
  };

  //Adds markers to map
  addPointsToMap = (map) => {
    // start values to find the mean coordinates
    var min_lat = 1000;
    var min_long = 1000;
    var max_lat = -1;
    var max_long = -1;

    // Position the gps coordinates
    // and find the mean point to centerize the map
    var markers = [];
    this.props.points.forEach((point, index) => {
      if (point.lat > max_lat) {
        max_lat = point.lat;
      }
      if (point.lat < min_lat) {
        min_lat = point.lat;
      }
      if (point.long > max_long) {
        max_long = point.long;
      }
      if (point.long < min_long) {
        min_long = point.long;
      }
      const placeholder = document.createElement('div');
      ReactDOM.render(<MapPopup onDelete={this.props.onDelete} point={point} index={index} />, placeholder);
      var marker = new L.Marker(
          [point.lat, point.long],
          { color: "black" }
        )
        .bindPopup(placeholder).openPopup()
        .addTo(map);
      markers.push(marker._latlng);
    });
    map.panTo([(max_lat + min_lat) / 2, (max_long + min_long) / 2]);
    return markers;
  };

  render() {
    return (
      <MapWrapper
        mapRef={this.mapContainer}
        {...this.props}
      />
    );
  }
}

function MapWrapper(props) {
  return (
    <Fragment>
      {props.children}
      <div className={css.webmap} ref={props.mapRef} />
      {props.showLoadingModal ? (
        <div className={css.loadingModalWrapper}>
          <div className={css.loadingModal}>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading the shape, please wait ...
          </div>
        </div>
      ) : (
          <div className={css.loadingModalWrapperExit}>
            <div className={css.loadingModalExit}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            Loading the shape, please wait ...
          </div>
          </div>
        )}
    </Fragment>
  );
}

export default connect()

const MapPopup = ({ onDelete, point, index }) => {
  return (
    <div className={css.popupStyle}>
      <p>{point.name}</p>
      {/* {console.log(point, index)} */}
      <Button variant="danger" onClick={() => onDelete(point.name, index)}>Delete</Button>
      {/* Need to solve how to lower RAM usage of this
      <img src={point.imageURL} alt={point.name} width='200' /> */}
    </div>
  );
}