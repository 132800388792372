import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function DeleteGroupModal({
    showDeleteGroupModal,
    handleDeleteGroupClose,
    handleDeleteGroupConfirm,
    groupName,
  }) {
  return (
    <Modal
      show={showDeleteGroupModal}
      onHide={handleDeleteGroupClose}
      backdrop="static"
      centered
      scrollable
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete the group {groupName}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleDeleteGroupClose}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={handleDeleteGroupConfirm} 
          >
          Confirm and Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

