import React, { 
  // Component,
  useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { 
  Alert,
  Button,
  } from "react-bootstrap";
// import PropTypes from "prop-types";
import {
  newPasswordRequired as registerNewUser,
  login
} from "../../actions/auth";
import { createMessage } from "../../actions/messages";
import { Fragment } from "react";

// New imports
import { useForm } from "react-hook-form";

// Local imports
import TermsModal from "../modals/TermsModal";

function NewPassword({
  isAuthenticated,
  registerNewUser,
  session,
  newUserEmail,
  login
  // createMessage,
}) {
  const [showModal, setShowModal] = useState(false);
  const searchParams =  new URLSearchParams(window.location.search);
  // const [givenName, setGivenName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [userName, setUserName] = useState("");
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [password2, setPassword2] = useState("");
  const [commonError, setCommonError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    let password = data.password;
    let password2 = data.password2;
    if (password !== password2) {
      setCommonError(true);
      setErrorMessage("Passwords do not match");
    } else {
      const output = {
        ...data,
        email: newUserEmail,
        session: session,
      }
      onRegister(output);
    }
  };

  const showErrorMessage = (message) => {
    console.log("Show error message");
    console.log(message);
    setCommonError(true);
    if (message.toLowerCase().includes("password")) {
      setErrorMessage(
        "Password must include special caracters (ex. =?.,), capital letters (ex. ABC) and numbers"
      );
    } else {
      setErrorMessage(message);
    }
  };

  // const onShowModal = () => {
  //   setShowModal(true);
  // };

  const onHideModal = () => {
    setShowModal(false);
  };

  const onRegister = (data) => {
    console.log(data)
    console.log(newUserEmail)
    console.log(session)
    let newUser = new FormData();
    newUser.append('username', data.email);
    newUser.append('password', data.password);
    newUser.append('givenName', data.givenName);
    newUser.append('lastName', data.lastName);
    newUser.append('session', data.session)
    registerNewUser(newUser, showErrorMessage);
  };

  const redirect = (login) => {
    let urlParam = searchParams.get('returnUrl')
    console.log(urlParam)
    let redirectUrl = ''
    if (urlParam) {
      redirectUrl = urlParam
    } else {
      redirectUrl = `/my-forests/`
    };
    return <Redirect to={redirectUrl} />;
  }

  return (
    <div className="col-md-6 m-auto" style={{ maxWidth: "500px" }}>
      {/* {test(session, newUserEmail)} */}
      <div className="card card-body mt-5">
      { isAuthenticated ? (
        redirect(login)
       ) : ( 
      <Fragment>
            <h2 className="text-center">Sign Up</h2>
            {commonError ? (
              <Alert variant="danger" style={{ textAlign: "center" }}>
                <b>{errorMessage}</b>
              </Alert>
            ) : (
              ""
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="givenName"
                  {...register("givenName", {
                    required: "Required",
                    maxLength: {
                      value: 50,
                      message: "You exeeded the max length",
                    },
                  })}
                />
                {errors.givenName && (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  >
                    {errors.givenName.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  {...register("lastName", {
                    required: "Required",
                    maxLength: {
                      value: 50,
                      message: "You exeeded the max length",
                    },
                  })}
                />
                {errors.lastName && (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  >
                    {errors.lastName.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  autoComplete="new-password"
                  {...register("password", {
                    required: "Required",
                    maxLength: {
                      value: 50,
                      message: "You exeeded the max length",
                    },
                    minLength: {
                      value: 6,
                      message: "At least 6 required",
                    },
                  })}
                />
                {errors.password2 && (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  >
                    {errors.password2.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password2"
                  autoComplete="new-password"
                  {...register("password2", {
                    required: "Required",
                    maxLength: {
                      value: 50,
                      message: "You exeeded the max length",
                    },
                    minLength: {
                      value: 6,
                      message: "At least 6 required",
                    },
                  })}
                />
                {errors.password2 && (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  >
                    {errors.password2.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  value="terms of service"
                  {...register("terms", {
                    required: "Required",
                  })}
                />
                <label for="terms">
                  {" I have read and agree to "}

                  <Button
                    variant="primary"
                    onClick={() => setShowModal(true)}
                  >
                    the terms of service
                  </Button>
                </label>
                {errors.terms && (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  >
                    {errors.terms.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    float: "right",
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#36737e",
                  }}
                >
                  <b>Register</b>
                </button>
              </div>
            </form>
          </Fragment>
     ) }
       
      </div>
      {/* <p style={{ marginTop: "5px", color: "white", textAlign: "center" }}>
        Already have an account?{" "}
        <Link to="/login">
          <b>Login</b>
        </Link>
      </p> */}
      <TermsModal
        showModal={showModal}
        handleClose={onHideModal}
        handleDone={onRegister}
      />
    </div>   
  );
}

const mapStateToProps = (state) => ({
  emailSend: state.auth.emailSend,
  session: state.auth.session,
  isAuthenticated: state.auth.isAuthenticated,
  newUserEmail: state.auth.newUserEmail
});

export default connect(mapStateToProps, {
  registerNewUser,
  createMessage,
  login,
})(NewPassword);
