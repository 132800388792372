import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function DeleteForestModal({
    showDeleteForestModal,
    handleDeleteForestClose,
    handleDeleteForestConfirm,
  }) {
  return (
    <Modal
      show={showDeleteForestModal}
      onHide={handleDeleteForestClose}
      backdrop="static"
      centered
      scrollable
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Forest</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete this forest?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleDeleteForestClose}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={handleDeleteForestConfirm} 
          >
          Confirm and Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

