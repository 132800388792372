// Packages imports
import React, { Component } from "react";
import PropTypes from "prop-types";

// css imports
import css from "../../styles/MyForests.module.css";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-modal-overlay/dist/index.css'
// Local imports
import { connect } from "react-redux";
// Store imports

export class UserDetails extends Component {
  static propTypes = {
    orders: PropTypes.array,
    modalIsShowing: PropTypes.string,
    
  };

  state = {
    showModal: false
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

 
  render() {
    return (
      <div className={css.contentSubAdmin}>
        {Object.keys(this.props.users).length ? (
          <TableList
            {...this.props}
            state={this.state}
            user={this.props.user}
          />
        ) : (
          <div className={css.contentSubAdmin}>
            <div className={css.containerSubAdmin}>
              <h2>There is noone here</h2>
            </div>
          </div>
        )}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  allOrders: state.orders.all_orders,
});

export default connect(mapStateToProps, {
})(UserDetails);

class TableList extends Component {

  constructor (props) {
    super(props);
    this.ordersOwned = [];
   
  };

  render() {

    return (
      <>
      <div className={css.contentSubAdmin}>
        <div className={css.containerSubAdmin}>
          <div className={css.containerOneMF}>
            <div className={css.boxOneMF}>
              <h1>User Managment</h1>
            </div>
            <div className={css.boxAdmin}> </div>
            
          </div>
  
          <TableBody {...this.props} />
        </div>
      </div>
    </>
    );
  }
}

class TableBody extends Component {


  
  groupDetails = (curr_group) => {

    //console.log(this.props.users[curr_group])
    // let users = this.props.users[curr_group].users
    // for (let index in users){
      //console.log(users[index])
      // let user = users[index];
      // let djangoUser = user.user;
      // let role = user.role;

     // console.log(djangoUser, role)
    // };

    // return (
    //   <div className={css.contSecondAdmin} key={djangoUser.family_name + 'main'}>
  
    //     <div className={css.elementForestName}>
    //       {djangoUser.given_name}  {djangoUser.family_name}
    //     </div>
    //     <div className={css.elementDate}>
    //       {getDate(djangoUser.created_at)}
    //     </div>
    //     <div className={css.elementEmail}>
    //       {djangoUser.contact_email}
    //     </div>
    //     <div className={css.elementEmail}>
    //       <select name="role" id="role">
    //         <option value="admin">Admin</option>
    //         <option value="worker">Worker</option>
    //         <option value="viewer" selected>Viewer</option>
    //       </select>
    //     </div>
    //   </div>
    // )
  };



  
  render() {
    return ( 
      <div>
        
        {Object.keys(this.props.users).map((group) => (
          <>
          {this.groupDetails(group)}          
          </>
        ))}
      </div>
    );
  }
}


   

