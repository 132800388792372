import axios from "axios";
import { tokenConfig } from "./auth";

import {
  USERS_IN_GROUP_AND_SUBGROUP,
  USER_GROUPS,
  ROOT_GROUP,
  USERS_IN_ROOT,
} from "./types";

const ip = process.env.REACT_APP_BACKEND_DNS;
export const getUsers =
  (group, sub_groups = 0) =>
  async (dispatch, getState) => {
    return await axios
      .get(
        `${ip}/api/v1/groups/get_users_in_group/?group=${group}&sub_groups=${sub_groups}`,
        tokenConfig(getState)
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: USERS_IN_GROUP_AND_SUBGROUP,
            payload: res.data,
          });
          // console.log(3)
          return res.data;
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

export const getUsersInRoot =
  (group, sub_groups = 1, only_users = 1) =>
  async (dispatch, getState) => {
    return await axios
      .get(
        `${ip}/api/v1/groups/get_users_in_group/?group=${group}&sub_groups=${sub_groups}&only_users=${only_users}`,
        tokenConfig(getState)
      )
      .then((res) => {
        // console.log(res.data)
        if (res.status === 200) {
          dispatch({
            type: USERS_IN_ROOT,
            payload: res.data,
          });
          // console.log(3)
          return res.data;
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

export const getRootGroup = () => async (dispatch, getState) => {
  return await axios
    .get(`${ip}/api/v1/groups/get_root_group/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ROOT_GROUP,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const createGroup =
  (groupName, parentGroup) => async (dispatch, getState) => {
    let formData = new FormData();
    formData.append("name", groupName);
    formData.append("parent_group", parentGroup);
    return await axios
      .post(
        `${ip}/api/v1/groups/create_org_group/`,
        formData,
        tokenConfig(getState)
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

export const deleteGroup = (groupName) => async (dispatch, getState) => {
  return await axios
    .delete(
      `${ip}/api/v1/groups/delete_org_group/?group=${groupName}`,
      tokenConfig(getState)
    )
    .then((res) => {
      console.log(1);
      // dispatch({
      //   type: USERS_IN_GROUP_AND_SUBGROUP,
      //   payload: res.data,
      // });
      console.log(3);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getGroupsForUser = (user) => async (dispatch, getState) => {
  let url = "/api/v1/groups/get_user_groups/";
  if (user !== undefined) {
    url = url + `?user=${user}`;
  }
  return await axios
    .get(`${ip}${url}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USER_GROUPS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const addUserToGroup =
  (groupName, emails, role) => async (dispatch, getState) => {
    let formData = new FormData();
    formData.append("group", groupName);
    formData.append("emails", emails);
    formData.append("role", role);
    return await axios
      .put(
        `${ip}/api/v1/groups/add_user_to_group/`,
        formData,
        tokenConfig(getState)
      )
      .then((res) => {
        console.log(1);
        // dispatch({
        //   type: USER_GROUPS,
        //   payload: res.data,
        // });
        console.log(3);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

export const changeUserRole =
  (groupName, users, role) => async (dispatch, getState) => {
    let formData = new FormData();
    formData.append("group", groupName);
    formData.append("emails", users);
    formData.append("role", role);
    return await axios
      .put(
        `${ip}/api/v1/groups/add_user_to_group/`,
        formData,
        tokenConfig(getState)
      )
      .then((res) => {
        console.log(1);
        // dispatch({
        //   type: USER_GROUPS,
        //   payload: res.data,
        // });
        console.log(3);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

export const removeUserFromGroup =
  (groupName, users) => async (dispatch, getState) => {
    return await axios
      .delete(
        `${ip}/api/v1/groups/remove_user_from_group/?users=${users}&group=${groupName}`,
        tokenConfig(getState)
      )
      .then((res) => {
        console.log(1);
        // dispatch({
        //   type: USER_GROUPS,
        //   payload: res.data,
        // });
        console.log(3);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };
