// Just constants
export const GET_ORDERS = "GET_ORDERS";
export const DELETE_ORDER = "DELETE_ORDER";
export const ADD_IMAGES = "ADD_ORDER";
export const ADD_IMAGES_FINSHED = "ADD_ORDER_FINISHED";
export const GET_ORDER = "GET_ORDER";
export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";
export const NEW_PASSWORD_SUCCESS = "NEW_PASSWORD_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const CLEAR_ORDERS = "CLEAR_ORDERS";
export const ADD_ORDER_ID = "ADD_ORDER_ID";
export const FINISH_IMAGE_UPLOAD = "FINISH_IMAGE_UPLOAD";
export const UPLOADED_IMAGE = "UPLOADED_IMAGE";
export const ADD_NEW_ORDER = "ADD_NEW_ORDER";
export const ADD_NEW_ORDER_FAILED = "ADD_NEW_ORDER_FAILED";
export const GET_ZERO_ORDERS = "GET_ZERO_ORDERS";
export const SHARING_LINK = "SHARING_LINK";
export const REMOVE_SHARING_LINK = "REMOVE_SHARING_LINK"; 
export const LINK_ACTIVATED = "LINK_ACTIVATED";
export const LINK_ACTIVATION_DENIED = "LINK_ACTIVATION_DENIED";
export const LINK_ACTIVATION_EXPIRED = "LINK_ACTIVATION_EXPIRED";
export const GET_FAILED_ORDERS = "GET_FAILED_ORDERS";
export const DELETE_FAILED_ORDERIMAGE = "DELETE_FAILED_ORDERIMAGE";
export const DEBUG = "DEBUG";
export const SUPPORTED_DRONES = "SUPPORTED_DRONES";

//DROPZONE constants
export const DELETE_IMAGE = "DELETE_IMAGE";
export const UPDATE_IMAGES = "UPDATE_IMAGES";
export const DELETE_ALL_IMAGES = "DELETE_ALL_IMAGES";
export const GET_ALL_IMAGES = "GET_ALL_IMAGES";
export const ADD_IMAGE = "ADD_IMAGE";
export const ADD_COORDS = "ADD_COORDS";

//RESULTS DETAILED constants
export const GET_SHP_LINK = "GET_SHP_LINK";
export const GET_MOSAIC_LINK = "GET_MOSAIC_LINK";
export const GET_REPORT_LINK = "GET_REPORT_LINK";
export const GET_KML_LINK = "GET_KML_LINK";
export const GET_TREE_COUNT_LINK = "GET_TREE_COUNT_LINK";
export const EMAIL_NOT_VALID = "EMAIL_NOT_VALID";

// MYFOREST constants
export const GET_FORESTS = "GET_FORESTS";
export const GET_FOREST = "GET_FOREST";
export const DELETE_FOREST = "DELETE_FOREST";
export const ADD_FOREST = "ADD_FOREST";
export const CHANGE_FOREST_PAGE = "CHANGE_FOREST_PAGE";
export const ADD_FOREST_SHP = "ADD_FOREST_SHP";
export const CLEAR_FORESTS = "CLEAR_FORESTS";
export const FOREST_STATS = "FOREST_STATS";
export const FOREST_ANALYSES = 'FOREST_ANALYSES';

//WEBMAPVIEW constants
export const SHAPEFILE_LINK = "GET_SHP_LINK";
export const TILESET_LINK = "TILESET_LINK"

//RESULTSLIST constants
export const CHANGE_PAGE = "CHANGE_PAGE";

// User profile page
export const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";
export const USER_PROFILE_UPDATED = "USER_PROFILE_UPDATED";
export const USER_PROFILE_FAILED = "USER_PROFILE_FAILED";
export const USER_STATS = "USER_STATS";

//NEW
export const DISPLAY_DELETE_CONFIRM  = "DISPLAY_DELETE_CONFIRM";
export const CLOSE_DELETE_CONFIRM = "CLOSE_DELETE_CONFIRM";

//GROUP ADMIN
export const USERS_IN_GROUP_AND_SUBGROUP = "USERS_IN_GROUP_AND_SUBGROUP";
export const USER_GROUPS = "USER_GROUPS"
export const ROOT_GROUP = "ROOT_GROUP"
export const USERS_IN_ROOT = "USERS_IN_ROOT"