import React, { Component } from "react";
import { Link, Redirect} from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { Alert, Spinner, Nav } from "react-bootstrap";

export class Login extends Component {
  state = {
    username: "",
    password: "",
    error: false,
    errorMessage: "",
    loggingIn: false,
    queryParams: new URLSearchParams(window.location.search),
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    newPasswordRequired: PropTypes.bool,
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      loggingIn: true
    })
    this.props.login(this.state.username, this.state.password, this.showErrorMessage)
  };

  showErrorMessage = (message) => {
    this.setState({
      error: true,
      errorMessage: message,
      loggingIn: false,
    })
  }

  hideErrorMessage = () => {
    this.setState({
      error: false,
      errorMessage: ""
    })
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.props.isAuthenticated) {
      let redirectUrl = ''
      if (this.state.queryParams.get('returnUrl')) {
        let urlParam = this.state.queryParams.get('returnUrl')
        redirectUrl = urlParam
      } else {
        redirectUrl = `/my-forests/`
      };
      return <Redirect to={redirectUrl} />;
    }
    if (this.props.newPasswordRequired) {
      let redirectUrl = ''
      if (this.state.queryParams.get('returnUrl')) {
        let urlParam = this.state.queryParams.get('returnUrl')
        redirectUrl = `/new-password/?returnUrl=${urlParam}`
      } else {
        redirectUrl = `/new-password/`
      };
      return <Redirect to={redirectUrl} />;
    }
    const { username, password } = this.state;
    return (
      <div className="col-md-6 m-auto" style={{ maxWidth: "500px" }}>
        <div className="card card-body mt-5">
          {
            !this.state.loggingIn ?
              <h2 className="text-center">Login</h2>
              :
              <div style={{display:"flex", justifyContent:"center"}}><h2 className="text-center">Logging in </h2><Spinner animation="grow" /></div>}
          {this.state.error ?
            <Alert id='loginError' variant="danger" style={{ textAlign: "center" }}>
              <b style={{whiteSpace: "pre-wrap"}}>{this.state.errorMessage}</b>
            </Alert> : ""}
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                name="username"
                onChange={this.onChange}
                value={username}
                autoComplete="username"
              />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                onChange={this.onChange}
                value={password}
                autoComplete="current-password"
              />
            </div>
            <p style={{ marginTop: "5px", color: "black", textAlign: "center" }}>
              Forgot the password?{" "}
              <Link to="/forgot-password">
                <b>Reset password</b>
              </Link>
            </p>

            <div className="form-group">
              <button
                type="submit"
                name='login'
                className="btn btn-primary"
                style={{
                  float: "right",
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#36737e"
                }}
              >
                <b>Log in</b>
              </button>
            </div>
          </form>
        </div>
        <p style={{ marginTop: "5px", color: "white", textAlign: "center" }}>
          Don't have an account?{" "}
          <Nav.Link href="https://arboair.com/register" target="_blank">
            <b>Sign Up</b>
          </Nav.Link>
        </p>
      </div>
    );
  };
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  newPasswordRequired: state.auth.newPasswordRequired,
});

export default connect(mapStateToProps, { login })(Login);
