// Package imports
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { 
  // connect, 
  Provider } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// Jacob testar
// Local components imports
import MyForests from './components/pages/MyForests';
import VideoBackground from './components/layout/VideoBackground';
// import SlideNav from './components/layout/SlideNav';

import DropZone from './components/subpages/DropZone';
import LinkActivation from './components/subpages/LinkActivation';
import LandingPage from "./components/pages/LandingPage";
import HereBeDragons from './components/subpages/HereBeDragons';
import Header from './components/layout/Header';
import About from './components/pages/About';
import NotFoundPage from './components/pages/NotFoundPage';
import Results from './components/pages/Results';
import Admin from './components/pages/Admin';
import ResultsDetailed from './components/subpages/ResultsDetailed';
import Login from './components/accounts/Login';
import ForgotPassword from './components/accounts/ForgotPassword';
import SignUp from './components/accounts/Register';
import NewPassword from './components/accounts/NewPasswordRequired';
import Profile from './components/pages/Profile';
import PrivateRoute from './components/common/PrivateRoute';
import Alerts from './components/layout/Alerts';
import SupportedDrones from './components/pages/SupportedDrones';
/* eslint-disable no-unused-vars */
import { axiosRequestInterceptor, axiosResponseInterceptor } from './components/common/AxiosInterceptors';
/* eslint-enable no-unused-vars */

// **************** JUST FOR DEBUG **********************
// import LeafletMapMyForest from './components/subpages/LeafletMapMyForest';

// Store imports
import store from './store';


// Css imports
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';



const alertOptions = {
  timeout: 3000,
  position: 'top center',
};


class App extends Component {
  constructor(props) {
    super(props);
    this.state = store.getState().auth;
  };

  componentDidMount() {
    const handler = e => this.setState({matches: e.matches});
    window.matchMedia("(min-width: 768px)").addEventListener('change', handler);
  }
  
  render() {
    
    return (
      <Provider store={store}>
        <Router>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className='main' id='outer-container'>
              <div className='main-overlay'>

              {/* <div >
      {this.state.matches && (<h1>Big Screen</h1>)}
      {!this.state.matches && (<h3>Small Screen</h3>)}
      </div> */}

                <AlertProvider template={AlertTemplate} {...alertOptions}>
                  <div className='body' id='page-wrap'>
                    <div className='content-wrapper'>
                      <Alerts />
                      <Header />
                      <Switch>
                        <Route exact path='/'>
                          <Redirect to='/login' />
                        </Route>
                        <Route exact path='/signup'>
                          <VideoBackground />
                          <SignUp />
                        </Route>
                        <Route exact path='/login'>
                          <VideoBackground />
                          <Login />
                        </Route>
                        <Route exact path='/forgot-password'>
                          <VideoBackground />
                          <ForgotPassword />
                        </Route>
                        <Route exact path='/new-password'>
                          <VideoBackground />
                          <NewPassword />
                        </Route>
                        <Route
                          exact
                          path='/Here_be_dragons'
                          component={HereBeDragons}
                        />
                        <PrivateRoute exact path="/start" component={LandingPage} />
                        <PrivateRoute exact path='/upload' component={DropZone} />
                        <PrivateRoute exact path='/about' component={About} />
                        <PrivateRoute exact path='/results' component={Results} />
                        <PrivateRoute exact path='/admin' component={Admin} notFoundPage={NotFoundPage}/>
                        <PrivateRoute
                          exact
                          path='/analysis/:id'
                          component={ResultsDetailed}
                        />
                        <PrivateRoute
                          exact
                          path='/my-forests'
                          component={MyForests}
                        />
                        
                        <PrivateRoute exact path='/profile' component={Profile} />
                        <PrivateRoute
                          exact
                          path='/link/:id/:object_type'
                          children={<LinkActivation />}
                        />
                        <PrivateRoute
                          exact
                          path='/sup_drones'
                          component={SupportedDrones}
                        />
                        <Route>
                        <NotFoundPage></NotFoundPage>
                        </Route>
                      </Switch>
                    </div>
                  </div>
                </AlertProvider>
              </div>
            </div>
          </MuiPickersUtilsProvider>  
        </Router>
      </Provider>
    );
  }
}


export default App;