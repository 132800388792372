import React, { useState } from "react";
import { 
  Modal, 
  Button, 
  InputGroup, 
  FormControl 
  // Form 
} from "react-bootstrap";
const ip = process.env.REACT_APP_FRONTEND_DNS;

export default function ShareModal(props) {
  const [ copyButtonClicked, setCopyButtonClicked ] = useState(false);
  // const [ errorInEmails, setErrorInEmails ] = useState('');

  const copyToClipboard = (text) => {
    const ta = document.createElement("textarea");
    ta.innerText = text;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand("copy");
    ta.remove();
  };

  const onCopyButtonClicked = () => {
    copyToClipboard(ip + "/link/" + props.linkid[0]+"/"+props.linkid[1]);
    setCopyButtonClicked(true);
  };

  const onSendEmailButtonClicked = (input) => {
    let emails = input.value
    // const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
    emails = emails.replace(/\s/g, '');
    emails = emails.split(',');
    props.sendEmailShare(props.linkid[0], emails)
    // let error = null;
    // console.log(emails, error)
    // for ( const index in emails ) {
    //   let email = emails[index];
    //   console.log(email, regexExp.test(email))
    //   console.log(regexExp.test(email) === false)
    //   if ( !regexExp.test(email) ) {
    //     error = email + ' is not a valid email'
    //     console.log(error)
    //     break
    //   };
    // };
    // console.log(emails, error)
    // if ( error ) {
    //   setErrorInEmails(error);
    // } else {
    //   setErrorInEmails('');
    // };

  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Share link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>{props.emailStatus}</h2>
        <p>Copy the link to share the results with someone...</p>
        <InputGroup className="mb-3">
          <FormControl
            defaultValue={props.linkid ? ip + "/link/" + props.linkid[0]+"/"+props.linkid[1] : ""}
            aria-describedby="basic-addon2"
          />
          <InputGroup.Append>
            <Button variant="primary" onClick={() => onCopyButtonClicked()}>
              {copyButtonClicked ? (
                <div>Link copied!</div>
              ) : (
                <div>
                  Copy link <i className="far fa-clipboard"></i>
                </div>
              )}
            </Button>
          </InputGroup.Append>
        </InputGroup>
        <p>
          ... or let us send an email to them by typing comma seperated emails
        </p>
        <p>{props.errorInEmail}</p>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Recipient's email"
            aria-label="Recipient's email"
            id='recipient_emails'
            aria-describedby="basic-addon2"
          />
          <InputGroup.Append>
            <Button variant="success" onClick={() => onSendEmailButtonClicked(
              document.getElementById("recipient_emails")
              )}>
              Send link <i className="fas fa-paper-plane"></i> 
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
