// Packages imports
import React, { Component, useState } from 'react'
import { Form } from 'react-bootstrap'
import * as FiIcons from 'react-icons/fi'
import * as IoIcons from 'react-icons/io'

// css imports
import css from '../../styles/Profile.module.css'

// Media imports

import { connect } from 'react-redux'
import {
  getUserProfile,
  getUserStats,
  updateUserProfile,
} from '../../actions/userpage'

class Profile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSaved: false,
    }
  }

  componentDidMount = () => {
    this.props.getUserProfile()
    this.props.getUserStats()
  }

  //update new value in input
  addChange = (e) => {
    this.props.updateUserProfile(e.target.value)
    console.log(updateUserProfile(e.target.value))
  }

  //Submit form function
  handleOnSubmit = async (e) => {
    e.preventDefault()

    try {
      await updateUserProfile().then((res) => {
        alert('Your profile is updated')
      })
    } catch (e) {
      alert(`Error ${e.message}`)
    }
  }

  saveChanges = () => {
    this.setState({
      isSaved: !this.state.isSaved,
    })
  }

  render() {
    const { userProfile } = this.props

    return (
      <div className={css.content}>
        {/* background */}
        <div className={css.mainCard}>
          <div className={css.bg}>
            <h2 className={css.rubrik}>Profile Settings</h2>
            <StatsButton stats={this.props.userStats} />
            <div className={css.profileImageWrapper}>
              {/* <img
                alt={"Profile"}
                src={profilepicture}
                style={{
                  height: "180px",
                  color: "f4f4f4",
                  verticalAlign: "middle",
                  borderRadius: "50%",
                  marginBottom: "40px"
                }}
              /> */}
            </div>
          </div>

          <div className={css.formWrapper}>
            {/* Firstname */}
            <Form onSubmit={this.handleOnSubmit}>
              <h4 className={css.label}>Firstname</h4>
              <Form.Group controlId='givenName'>
                <Form.Control
                  type='text'
                  placeholder={userProfile ? userProfile.given_name : 'John'}
                  autoComplete='given-name'
                />

                {/* Surname */}
              </Form.Group>
              <h4 className={css.label}>Surname</h4>
              <Form.Group controlId='lastName'>
                <Form.Control
                  type='text'
                  placeholder={userProfile ? userProfile.family_name : 'Doe'}
                  autoComplete='family-name'
                />
              </Form.Group>

              {/* Email */}
              <h4 className={css.label}>Email</h4>
              <Form.Group controlId='email'>
                <Form.Control
                  type='email'
                  placeholder={
                    userProfile ? userProfile.email : 'JohnDoe@email.com'
                  }
                />
                <Form.Text className='text-muted'>
                  We do not use your email for other things than billing
                </Form.Text>
              </Form.Group>
              <Form.Group controlId='formBasicEmail'>
                <Form.Control
                  type='email'
                  placeholder='Retype email'
                  autoComplete='username'
                />
                <Form.Text className='text-muted'>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId='formBasicPassword'>
                <h4 className={css.label}>Password</h4>
                <Form.Control
                  type='password'
                  placeholder='Change password'
                  autoComplete='new-password'
                />
                <Form.Text className='text-muted'>
                  Your password is never seen by us and is stored using Amazon
                  Cognito.
                </Form.Text>
              </Form.Group>

              <button
                type='submit'
                // active={isChanging}
                // onClick={this.addChange}
                className={css.formInputSubmit}
              >
                {this.state.isSaved ? (
                  <div className={css.overlay}>
                    <div className={css.statisticModal}>
                      <h2>Saved changes!</h2>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                Save
              </button>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

//NEW : show statistic information
function StatsButton(props) {
  const [statistic, showStatistic] = useState(false)

  const setShowStatistic = () => {
    showStatistic(!statistic)
  }

  if (props.stats) {
    var forestHectars =
      Math.round((props.stats.forestHectars + Number.EPSILON) * 100) / 100
    var orderHectars =
      Math.round((props.stats.orderHectars + Number.EPSILON) * 100) / 100
  }

  return (
    <div>
      <div className={css.statsButton} onClick={setShowStatistic}>
        <i className='fas fa-chart-line' style={{ marginRight: '5px' }}></i>
        {'Statistics'}
      </div>

      {statistic ? (
        <div className={css.overlay}>
          <div className={css.statisticModal}>
            <FiIcons.FiAlertCircle
              style={{
                fontSize: '45px',
                paddingBottom: '1rem',
              }}
            />

            <IoIcons.IoIosCloseCircle
              className={css.closeButton}
              onClick={setShowStatistic}
            />

            {/* placeholder */}
            <h6 style={{ fontSize: '14px' }}>
              This is your subscription quota
            </h6>
            <hr></hr>
            <h4>Total hectars of forests</h4>
            <p>
              {forestHectars}
              <span>hectars</span>
            </p>

            <h4>Total hectars of orders</h4>
            <p>
              {orderHectars}
              <span>hectars</span>
            </p>
            {/* 
            <h4>Total drone flights</h4>
            <p>500<span>km</span></p>

            <h4>Total cost</h4>
            <p>5000<span>kr</span></p> */}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

//update user profile after click
// const SaveChangesButton = ({onSaveAction}) => (
//   <div>
//     {/* <button
//     className={css.formInputSubmit}
//     onClick={onCancelAction}>Cancel</button> */}
//     <button
//     className={css.formInputSubmit}
//     onClick={onSaveAction}>Save</button>
//   </div>
// );

// SaveChangesButton.propTypes = {
//   onCancelAction: PropTypes.func,
//   active: PropTypes.bool.isRequired,
//   onSaveAction: PropTypes.func,
// };

// SaveChangesButton.defaultProps = {
//   onCancelAction: () => console.info('Cancelled'),
//   onSaveAction:() => console.info('Saved'),
// };

const mapStateToProps = (state) => ({
  userProfile: state.userpage.userProfile,
  userStats: state.userpage.userStats,
})

export default connect(mapStateToProps, {
  getUserProfile,
  updateUserProfile,
  getUserStats,
})(Profile)
