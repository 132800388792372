// Packages imports
import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { Spinner, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { DatePicker } from "@material-ui/pickers";
import { FormControl, InputGroup, Overlay } from "react-bootstrap";
// css imports
import css from "../../styles/MyForests.module.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// Local imports
import { stageNames } from "../../GLOBAR_PARAMETERS";
import { connect } from "react-redux";
import ShareModal from "../modals/ShareModal";
import DeleteOrderModal from "../modals/DeleteOrderModal";

// Store imports
import {
  changePage,
  createSharingLink,
  removeSharingLink,
  deleteOrder,
  sendEmailShare,
} from "../../actions/orders";

// icons
import {
  AiOutlineDelete,
  AiOutlineShareAlt,
  AiOutlineSearch,
} from "react-icons/ai";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
// import store from "../../store";

function convertUTCDateToLocalDate(date) {
  var dateLocal = new Date(date);
  var newDate = new Date(
    dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000
  );
  return newDate;
}

function getDate(date) {
  var fullLocalDate = convertUTCDateToLocalDate(date);
  var localDate = fullLocalDate.toISOString().split("T")[0];
  return localDate;
}

// function getTime(date) {
//   var fullLocalDate = convertUTCDateToLocalDate(date);
//   var localTime = fullLocalDate.toISOString().split("T")[1];
//   localTime = localTime.split(":")[0] + ":" + localTime.split(":")[1];
//   return localTime;
// };

const objects_per_page = 10;

export class ResultsList extends Component {
  static propTypes = {
    orders: PropTypes.array,
    deleteOrder: PropTypes.func.isRequired,
    selectOrder: PropTypes.func.isRequired,
    acs: PropTypes.string,
    modalIsShowing: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.myForestSearchTooltipRef = React.createRef();
    this.minSize = 0;
    this.maxSize = 1000000;
    this.props.changePage(
      this.props.current_page,
      "date_modified",
      "-",
      objects_per_page,
      ""
    );
    this.state = {
      matches: window.matchMedia("(min-width: 768px)").matches,
      infoData: null,
      userType: "editor",
      addingArea: false,
      orderDeleteActive: false,
      orderForDeletion: null,
    };

    this.state = {
      isShowingSearchKeyTooltip: false,
      searchKey: "",
    };
  }

  onSearchKeyChange = (input) => {
    var searchKey = input.target.value;
    // console.log('SKEY', searchKey)
    if (searchKey === "") {
      this.hideSearchKeyTooltip();
    } else if (!searchKey.match(/^([ ÆØÅæøåa-zA-Z0-9.-]+)$/i)) {
      this.showSearchKeyTooltip();
      return;
    } else {
      this.hideSearchKeyTooltip();
    }
    let method = "date_modified";
    let acs = "";
    let page = 0;
    // this.setState({
    //   searchKey: searchKey
    // },
    this.props.changePage(
      page,
      method,
      acs,
      objects_per_page,
      searchKey,
      this.state.urlFilterParams
    );
  };

  showSearchKeyTooltip = () => {
    this.setState({
      isShowingSearchKeyTooltip: true,
    });
  };

  hideSearchKeyTooltip = () => {
    this.setState({
      isShowingSearchKeyTooltip: false,
    });
  };

  handlePageClick = (data) => {
    let pageNumber = data.selected;
    let method = "date_modified";
    let acs = "";
    let skey = "";
    this.props.changePage(
      pageNumber,
      method,
      acs,
      skey,
      this.state.urlFilterParams
    );
  };

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
  }

  showShareModal = async (orderID, user_type) => {
    let formData = new FormData();
    formData.append("object_id", orderID);
    formData.append("object_type", "Order");
    formData.append("user_type", user_type);
    this.props.createSharingLink(formData);
  };

  onSendEmailShare = async (uuid, emails) => {
    let formData = new FormData();
    formData.append("emails", emails);
    // let status = null;
    this.props.sendEmailShare(uuid, formData).then((res) => {
      // console.log(res);
      this.setState({
        status: res,
      });
    });
  };

  hideShareModal = () => {
    this.setState({
      status: null,
    });
    this.props.removeSharingLink();
  };

  showDeleteModal = (order) => {
    this.setState({
      orderDeleteActive: true,
      orderForDeletion: order,
    });
  };

  deleteOrderConfirmed = () => {
    this.props.deleteOrder(this.state.orderForDeletion.id);
    this.setState({
      orderDeleteActive: false,
      orderForDeletion: null,
    });
  };

  hideDeleteModal = () => {
    this.setState({
      orderDeleteActive: false,
      orderForDeletion: null,
    });
  };

  switchView = () => {
    this.props.getForests();
    this.setState({
      addingArea: !this.state.addingArea,
    });
  };

  render() {
    return (
      <div className={css.contentMF}>
        <TableList
          {...this.props}
          state={this.state}
          showShareModal={this.showShareModal}
          showDeleteModal={this.showDeleteModal}
          editForest={this.editForest}
          user={this.props.user}
          onSearchKeyChange={this.onSearchKeyChange}
        />
        {/* {this.props.orders.length ? (
          <TableList
            {...this.props}
            state={this.state}
            showShareModal={this.showShareModal}
            showDeleteModal={this.showDeleteModal}
            editForest={this.editForest}
            user={this.props.user}
            onSearchKeyChange={this.onSearchKeyChange}
          />
        ) : (
          <div className={css.contentMF}>
            <div className={css.containerContentMF}>
              <h2>You have no orders yet! Go to myForests and create one!</h2>
            </div>
          </div>
        )} */}
        <ShareModal
          show={this.props.linkID ? true : false}
          onHide={() => this.hideShareModal()}
          linkid={this.props.linkID}
          sendEmailShare={this.onSendEmailShare}
          errorInEmail={this.props.emailNotValid}
          emailStatus={this.state.status}
        />
        <DeleteOrderModal
          showModal={this.state.orderDeleteActive}
          handleDeleteOrderClose={this.hideDeleteModal}
          handleDeleteOrderConfirm={this.deleteOrderConfirmed}
          order={this.state.orderForDeletion}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  current_page: state.orders.current_page,
  tot_num_pages: state.orders.tot_pages,
  linkID: state.orders.sharing_link,
  user: state.auth.user,
  allOrders: state.orders.all_orders,
  emailNotValid: state.orders.email_not_valid,
});

export default connect(mapStateToProps, {
  changePage,
  createSharingLink,
  removeSharingLink,
  deleteOrder,
  sendEmailShare,
})(ResultsList);

class TableList extends Component {
  constructor(props) {
    super(props);

    this.ordersOwned = [];

    this.minSize = 0;
    this.maxSize = 1000000;

    this.state = {
      filterArray: [
        { name: "date_created", opened: true, active: false },
        { name: "date_modified", opened: true, active: false },
        { name: "size", opened: true, active: false },
        { name: "stage", opened: true, active: false },
        { name: "owner", opened: true, active: false },
        // {name: 'forest', opened: true, active: false},
      ],
      stages: [
        { name: "Waiting for upload", checked: false },
        { name: "Uploading images", checked: false },
        { name: "Creating mosaic", checked: false },
        { name: "Analysing health", checked: false },
        { name: "Finished", checked: false },
      ],
      startCreatedDate: new Date("2021-01-01").toUTCString(),
      endCreatedDate: new Date().toUTCString(),
      startModifiedDate: new Date("2021-01-01").toUTCString(),
      endModifiedDate: new Date().toUTCString(),
      minSizeInput: this.minSize,
      maxSizeInput: this.maxSize,
      urlFilterParams: "",
      forests: [],
    };
  }

  handlePageClick = (data) => {
    let pageNumber = data.selected;
    let method = this.props.sort_method;
    let acs = this.props.sort_acs;
    let objects_per_page = this.props.objects_per_page;
    let skey = this.props.search_key;
    this.props.changePage(
      pageNumber,
      method,
      acs,
      objects_per_page,
      skey,
      this.state.urlFilterParams
    );
  };

  handleObjectsPerPageChange = (data) => {
    //console.log("handleObjectsPerPageChange");
    let pageNumber = this.props.current_page;
    let method = this.props.sort_method;
    let acs = this.props.sort_acs;
    let skey = this.props.search_key;
    this.props.changePage(
      pageNumber,
      method,
      acs,
      objects_per_page,
      skey,
      this.state.urlFilterParams
    );
  };

  refreshStatus() {
    let method = this.props.sort_method;
    let acs = this.props.sort_acs;
    let objects_per_page = this.props.objects_per_page;
    let pageNumber = this.props.current_page;
    let skey = this.props.search_key;
    this.props.changePage(
      pageNumber,
      method,
      acs,
      objects_per_page,
      skey,
      this.state.urlFilterParams
    );
  }

  componentDidMount() {
    let interval = setInterval(this.refreshStatus.bind(this), 60 * 1000);
    this.setState({ interval: interval });
    this.getForestsForOrders();
    this.getOrdersForUSer();
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  getForestsForOrders = () => {
    this.props.getOrders().then((allOrders) => {
      for (const key in allOrders) {
        let order = allOrders[key];
        let forest = order["forest"];
        if (!this.state.forests.includes(forest)) {
          let temp = this.state.forests;
          temp.push(forest);
          this.setState({
            forests: temp,
          });
        }
      }
    });
  };

  getOrdersForUSer = () => {
    // console.log(this.props.allOrders[0])
  };

  changeStageClicked = (index) => {
    let tempArray = this.state.stages;
    for (const key in tempArray) {
      tempArray[key]["checked"] = false;
    }
    tempArray[index]["checked"] = !tempArray[index]["checked"];
    this.setState(
      {
        stages: tempArray,
      },
      () => this.updateFiltering()
    );
  };

  changeIfOnlyOwner = (owner) => {
    if (owner) {
      this.setState(
        {
          owner: "true",
        },
        () => this.updateFiltering()
      );
    } else {
      this.setState(
        {
          owner: "false",
        },
        () => this.updateFiltering()
      );
    }
  };

  changeFilterOpened = (index) => {
    let tempArray = this.state.filterArray;
    tempArray[index]["opened"] = !tempArray[index]["opened"];
    this.setState({
      filterArray: tempArray,
    });
  };

  changeForestClicked = (index) => {
    // let tempArray = this.state.forests;
    // tempArray[index]['checked'] = !tempArray[index]['checked'];
    // this.setState({
    //   conditions: tempArray
    // }, () => this.updateFiltering());
    // console.log(index)
  };

  changeInputSize = (input) => {
    let type = input.target.placeholder;
    let value = input.target.value;
    if (value >= this.minSize && value <= this.maxSize) {
      switch (type) {
        case "Min Size":
          if (value <= this.state.maxSizeInput) {
            this.setState(
              {
                minSizeInput: value,
              },
              () => this.updateFiltering()
            );
          }
          break;
        case "Max Size":
          if (value >= this.state.minSizeInput) {
            this.setState(
              {
                maxSizeInput: value,
              },
              () => this.updateFiltering()
            );
          }
          break;
        default:
          break;
      }
    }
  };

  changeInputDate = (date, type) => {
    switch (type) {
      case "startCreatedDate":
        this.setState(
          {
            startCreatedDate: date.toUTCString(),
          },
          () => this.updateFiltering()
        );
        break;
      case "endCreatedDate":
        this.setState(
          {
            endCreatedDate: date.toUTCString(),
          },
          () => this.updateFiltering()
        );
        break;
      case "startAnalysisDate":
        this.setState(
          {
            startAnalysisDate: date.toUTCString(),
          },
          () => this.updateFiltering()
        );
        break;
      case "endAnalysisDate":
        this.setState(
          {
            endAnalysisDate: date.toUTCString(),
          },
          () => this.updateFiltering()
        );
        break;
      case "startModifiedDate":
        this.setState(
          {
            startModifiedDate: date.toUTCString(),
          },
          () => this.updateFiltering()
        );
        break;
      case "endModifiedDate":
        this.setState(
          {
            endModifiedDate: date.toUTCString(),
          },
          () => this.updateFiltering()
        );
        break;
      default:
        break;
    }
  };

  changeFilterActive = (index) => {
    let tempArray = this.state.filterArray;
    tempArray[index]["active"] = !tempArray[index]["active"];
    this.setState(
      {
        filterArray: tempArray,
      },
      () => this.updateFiltering()
    );
  };

  updateFiltering = () => {
    let activeFilters = Object.keys(this.state.filterArray).map((key) => {
      let params = null;
      let active = this.state.filterArray[key]["active"];
      let name = this.state.filterArray[key]["name"];
      if (active) {
        switch (name) {
          case "date_created":
            let startCreatedDate = "&scd=" + this.state.startCreatedDate;
            let endCreatedDate = "&ecd=" + this.state.endCreatedDate;
            params = startCreatedDate + endCreatedDate;
            break;
          case "date_modified":
            let startModifiedDate = "&schd=" + this.state.startModifiedDate;
            let endModifiedDate = "&echd=" + this.state.endModifiedDate;
            params = startModifiedDate + endModifiedDate;
            break;
          case "size":
            let minSize = "&mins=" + this.state.minSizeInput;
            let maxSize = "&maxs=" + this.state.maxSizeInput;
            params = minSize + maxSize;
            break;
          case "stage":
            let stage = "&stg=";
            let activeStages = Object.keys(this.state.stages).map((key) => {
              let name = this.state.stages[key]["name"];
              let active = this.state.stages[key]["checked"];
              if (active) {
                return name;
              }
              return null;
            });
            for (const key in activeStages) {
              if (activeStages[key]) {
                if (key > 2) {
                  let value = parseInt(key) + 1;
                  stage = stage + value;
                } else {
                  stage = stage + key;
                }
              }
            }
            params = stage;
            break;
          case "owner":
            let owner = "&own=true";
            params = owner;
            break;
          case "forest":
            // let minSize = '&mins=' + this.state.minSizeInput;
            // let maxSize = '&maxs=' + this.state.maxSizeInput;
            // params = minSize + maxSize;
            break;
          default:
            break;
        }
      }
      return params;
    });
    let params = "";
    for (const filter in activeFilters) {
      if (activeFilters[filter]) {
        params = params + activeFilters[filter];
      }
    }
    this.setState(
      {
        urlFilterParams: params,
      },
      function () {
        this.refreshStatus();
      }
    );
  };

  render() {
    return (
      <>
        <MapFilterSideNav
          {...this.props}
          filterArray={this.state.filterArray}
          changeFilterOpened={this.changeFilterOpened}
          changeForestClicked={this.changeForestClicked}
          startCreatedDate={this.state.startCreatedDate}
          endCreatedDate={this.state.endCreatedDate}
          minSizeInput={this.state.minSizeInput}
          maxSizeInput={this.state.maxSizeInput}
          changeSize={this.changeInputSize}
          changeDate={this.changeInputDate}
          changeFilterActive={this.changeFilterActive}
          state={this.state}
          changeStageClicked={this.changeStageClicked}
        />
        <div className={css.contentMF}>
          <div className={css.containerContentMF}>
            <div className={css.containerOneMF}>
              <div className={css.boxOneMF}>
                <h2 id="pageTitle">Analysis Details</h2>
              </div>
              {/* -- Search -- */}
              <div className={css.searchBarWrapper}>
                <div className={css.searchBar}>
                  <InputGroup size="lg">
                    <InputGroup.Text
                      style={{ height: "48px" }}
                      id="inputGroup-sizing-sm"
                    >
                      <i className="fas fa-search"></i>
                    </InputGroup.Text>
                    <FormControl
                      ref={this.myForestSearchTooltipRef}
                      aria-describedby="inputGroup-sizing-sm"
                      placeholder="Search Analyses..."
                      aria-label="Search Analyses..."
                      onChange={this.props.onSearchKeyChange}
                    />
                  </InputGroup>
                  <Overlay
                    target={this.myForestSearchTooltipRef}
                    show={this.isShowingSearchKeyTooltip}
                    placement="right"
                  ></Overlay>
                </div>
              </div>
            </div>
            {this.props.tot_num_pages > 1 ? (
              <div className={css.paginationA} key={"pagination1"}>
                <ReactPaginate
                  previousLabel={
                    <ArrowBackIosIcon style={{ fontSize: 18, width: 150 }} />
                  }
                  nextLabel={
                    <ArrowForwardIosIcon style={{ fontSize: 18, width: 150 }} />
                  }
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.props.tot_num_pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={10}
                  initialPage={this.props.current_page}
                  onPageChange={(page) => this.handlePageClick(page)}
                  containerClassName={"pagination"}
                  disabledClassName={"disabled-page"}
                  activeClassName={"item active "}
                  nextClassName={"arrow next "}
                  pageClassName={"item pagination-page "}
                  previousClassName={"arrow previous"}
                />
              </div>
            ) : (
              ""
            )}
            <TableHeader {...this.props} />
            {this.props.orders.length ? (
              " "
            ) : (
              <div className={css.containerContentMF}>
                <h2>You have no orders yet! Go to myForests and create one!</h2>
              </div>
            )}
            <TableBody {...this.props} />
            {this.props.tot_num_pages > 1 ? (
              <div className={css.paginationA} key={"pagination2"}>
                <ReactPaginate
                  previousLabel={
                    <ArrowBackIosIcon style={{ fontSize: 18, width: 150 }} />
                  }
                  nextLabel={
                    <ArrowForwardIosIcon style={{ fontSize: 18, width: 150 }} />
                  }
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.props.tot_num_pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={10}
                  initialPage={this.props.current_page}
                  onPageChange={(page) => this.handlePageClick(page)}
                  containerClassName={"pagination"}
                  disabledClassName={"disabled-page"}
                  activeClassName={"item active "}
                  nextClassName={"arrow next "}
                  pageClassName={"item pagination-page "}
                  previousClassName={"arrow previous"}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}

function TableHeader(props) {
  // class TableHeader extends Component {

  const [sortBy, setSortBy] = useState(props.sort_method);
  const [sortACS, setSortACS] = useState(props.sort_acs === "-" ? false : true);
  // const [searchKey, setSearchKey] = useState('');

  const updatePageResults = () => {
    // console.log("updatePageResults");
    let pageNumber = props.current_page;
    let method = sortBy;
    let acs = sortACS ? "-" : "";
    // console.log(props)
    props.changePage(
      pageNumber,
      method,
      acs,
      props.objects_per_page,
      props.search_key
    );
  };

  const changeSortingMethod = (method) => {
    setSortBy(method);
    setSortACS(true);
    updatePageResults();
  };

  const changeSortingACS = (acs) => {
    setSortACS(acs);
    // update redux
    updatePageResults();
  };

  const handleHeaderClicked = (method) => {
    if (method === sortBy) {
      // Same sorting method, change direction
      changeSortingACS(!sortACS);
    } else {
      // Different sorting method, set method and reset direction
      changeSortingMethod(method);
    }
  };

  return (
    <div>
      {/* Headers */}
      <div className={css.contFirstR}>
        <div
          className={css.elementResultsName}
          onClick={() => handleHeaderClicked("forest")}
        >
          <p>Forest Name</p>
          {sortBy === "forest" ? (
            sortACS ? (
              <i className="fas fa-sort-up"></i>
            ) : (
              <i className="fas fa-sort-down"></i>
            )
          ) : (
            <i className="fas fa-sort-up"></i>
          )}
        </div>
        <div
          className={css.elementAnalysis}
          onClick={() => handleHeaderClicked("analysis_name")}
        >
          <p>Analysis Name</p>
          {sortBy === "analysis_name" ? (
            sortACS ? (
              <i className="fas fa-sort-up"></i>
            ) : (
              <i className="fas fa-sort-down"></i>
            )
          ) : (
            <i className="fas fa-sort-up"></i>
          )}
        </div>
        <div
          className={css.elementNameUser}
          onClick={() => handleHeaderClicked("owner")}
        >
          <p>Author</p>
          {sortBy === "owner" ? (
            sortACS ? (
              <i className="fas fa-sort-up"></i>
            ) : (
              <i className="fas fa-sort-down"></i>
            )
          ) : (
            <i className="fas fa-sort-up"></i>
          )}
        </div>
        <div
          className={css.elementDate}
          onClick={() => handleHeaderClicked("date_modified")}
        >
          {props.state.matches ? <p>Date Modified</p> : <p>Date</p>}
          {sortBy === "date_modified" ? (
            sortACS ? (
              <i className="fas fa-sort-up"></i>
            ) : (
              <i className="fas fa-sort-down"></i>
            )
          ) : (
            <i className="fas fa-sort-up"></i>
          )}
        </div>
        {/* <div className={css.elementTime}>
            {props.state.matches ? <p>Time Modified</p> : ""}
          </div> */}
        <div className={css.elementResultsStatus}>
          {props.state.matches ? <p>Status</p> : <p>Status</p>}
        </div>
        <div className={css.elementEmpty} />
      </div>
    </div>
  );
}

class TableBody extends Component {
  render() {
    return (
      <div>
        {this.props.orders.map((order) => (
          <div className={css.contSecondR} key={order.id + "main"}>
            <div
              className={css.elementForestName}
              key={order.id + "forest_name"}
            >
              <p>{order.forest_name}</p>
              <br />
              <p className={css.elementIDA}>Order ID: {order.id}</p>
            </div>
            <div
              className={css.elementAnalysis}
              key={order.id + "analysis_name"}
            >
              {order.analysis_name !== "Empty" &&
              order.analysis_name !== "" &&
              order.analysis_name !== "None"
                ? order.analysis_name
                : "No given name"}
            </div>
            <div className={css.elementEmail} key={order.id + "user"}>
              {order.username ? order.username : "Undefined"}
            </div>
            <div className={css.elementDate} key={order.id + "date"}>
              {getDate(order.date_modified)}
            </div>
            {/* <div className={css.elementTime} key={order.id + 'time'}>
              {getTime(order.date_modified)}
            </div> */}
            <div
              className={css.elementResultsStatus}
              key={order.id + "finished"}
            >
              {order.finished === true ? (
                <p>Finished</p>
              ) : order.stage === 0 ? (
                <p>{stageNames[order.stage]}</p>
              ) : (
                <>
                  {stageNames[order.stage]}
                  <Spinner animation="border" variant="primary"></Spinner>
                </>
              )}
            </div>
            {/* Icons */}
            <div className={css.elementBtnRowR} key={order.id + "owner"}>
              {order.owner === this.props.user.sub ? (
                <>
                  {order.finished ? (
                    <>
                      <AiOutlineShareAlt
                        className={css.btnSecondaryA}
                        as="button"
                        onClick={() =>
                          this.props.showShareModal(order.id, "viewer")
                        }
                      />
                    </>
                  ) : (
                    <>
                      <AiOutlineShareAlt
                        className={css.btnSecondaryA}
                        as="button"
                        onClick={() =>
                          this.props.showShareModal(order.id, "editor")
                        }
                      />
                    </>
                  )}
                </>
              ) : (
                ""
              )}
              {order.finished === true ? (
                <>
                  <AiOutlineSearch
                    className={css.btnSecondaryA}
                    as="button"
                    onClick={this.props.selectOrder.bind(this, order)}
                  />
                </>
              ) : (
                ""
              )}
              {order.stage === 1 || order.stage === 0 ? (
                <>
                  <AiOutlineDelete
                    className={css.btnDelA}
                    as="button"
                    onClick={() => this.props.showDeleteModal(order)}
                  />
                </>
              ) : (
                " "
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const MapFilterSideNav = ({
  changeFilterOpened,
  state,
  changeSize,
  changeDate,
  changeFilterActive,
  changeForestClicked,
  changeStageClicked,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  const settingButtons = {
    arrowOpen: <FaChevronRight />,
    arrowClose: <FaChevronLeft />,
  };

  const toggleSidebar = () => {
    setIsOpened(!isOpened);
  };

  const filters = state.filterArray.map(function (key, index) {
    let filterKey = key["name"];
    let opened = key["opened"];
    let filterName;
    let filterOptions;
    switch (filterKey) {
      case "date_modified":
        filterName = "Date of analysis modification";
        if (opened) {
          filterOptions = (
            <>
              <DatePicker
                maxDate={state.endModifiedDate}
                maxDateMessage="Start date needs to be before end date"
                label="Start Date"
                value={state.startModifiedDate}
                onChange={(e) => changeDate(e, "startModifiedDate")}
                animateYearScrolling
              />
              <DatePicker
                maxDate={new Date()}
                minDate={state.startModifiedDate}
                maxDateMessage="End date cannot be after today"
                minDateMessage="End date cannot be before start date"
                label="End Date"
                value={state.endModifiedDate}
                onChange={(e) => changeDate(e, "endModifiedDate")}
                animateYearScrolling
              />
            </>
          );
        }
        break;
      case "date_created":
        filterName = "Date of analysis creation";
        if (opened) {
          filterOptions = (
            <>
              <DatePicker
                maxDate={state.endCreatedDate}
                maxDateMessage="Start date needs to be before end date"
                label="Start Date"
                value={state.startCreatedDate}
                onChange={(e) => changeDate(e, "startCreatedDate")}
                animateYearScrolling
              />
              <DatePicker
                maxDate={new Date()}
                minDate={state.startCreatedDate}
                maxDateMessage="End date cannot be after today"
                minDateMessage="End date cannot be before start date"
                label="End Date"
                value={state.endCreatedDate}
                onChange={(e) => changeDate(e, "endCreatedDate")}
                animateYearScrolling
              />
            </>
          );
        }
        break;
      case "size":
        filterName = "Size in ha";
        if (opened) {
          filterOptions = (
            <>
              <Form>
                <Form.Group className="mb-3" controlId="formGroupMinSize">
                  <Form.Label>Min Size</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Min Size"
                    onChange={(e) => changeSize(e)}
                    value={state.minSizeInput}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupMaxSize">
                  <Form.Label>Max Size</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Max Size"
                    onChange={(e) => changeSize(e)}
                    value={state.maxSizeInput}
                  />
                </Form.Group>
              </Form>
            </>
          );
        }
        break;
      case "forest":
        filterName = "Filter only orders for the given forests";
        if (opened) {
          filterOptions = (
            <Form>
              {state.forests.map((element, index) => (
                <Form.Check
                  type="checkbox"
                  label={element}
                  id={element}
                  key={element}
                  onChange={() => changeForestClicked(index)}
                />
              ))}
            </Form>
          );
        }
        break;
      case "stage":
        filterName = "Filter only orders in a given state";
        if (opened) {
          filterOptions = (
            <Form>
              {Object.keys(state.stages).map((key) => (
                <Form.Check
                  type="radio"
                  name="stage-radio"
                  label={state.stages[key]["name"]}
                  id={state.stages[key]["name"]}
                  key={state.stages[key]["name"]}
                  onChange={() => changeStageClicked(key)}
                />
              ))}
            </Form>
          );
        }
        break;
      case "owner":
        filterName = "Only your own orders";
        break;
      default:
        filterName = filterKey;
        if (opened) {
          filterOptions = "";
        }
        break;
    }
    return (
      <div key={index} className={css.filterText} style={{ marginTop: "5px" }}>
        <Form.Check
          type="checkbox"
          // label={<h4 onClick={() => changeFilterOpened(index)}>{filterName}</h4>}
          id={filterName}
          key={filterName}
          onChange={() => changeFilterActive(index)}
        />
        <h4 onClick={() => changeFilterOpened(index)}>{filterName}</h4>
        {filterOptions}
      </div>
    );
  });

  return (
    <>
      <div
        className={css.contentSideBar}
        style={{ right: isOpened ? "0" : "-300px" }}
        backdrop="static"
        keyboard="false"
      >
        <div className={css.button} onClick={toggleSidebar}>
          {isOpened ? settingButtons.arrowOpen : settingButtons.arrowClose}
        </div>

        <div className={css.filters}>
          <h4>Filters</h4>
          <hr
            style={{
              margin: "0 0 5px 0",
              borderBottom: "0.5px solid rgba(231, 231, 231, 0.4)",
              opacity: 0.5,
            }}
          />
          {filters}
        </div>
      </div>

      {isOpened ? <div className={css.overlay} onClick={toggleSidebar} /> : ""}
    </>
  );
};
