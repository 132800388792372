import React, { 
  // Component,
  useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { 
  Alert,
  Button,
  } from "react-bootstrap";
// import PropTypes from "prop-types";
import {
  register as registerNewUser,
} from "../../actions/auth";
import { createMessage } from "../../actions/messages";
import { Fragment } from "react";

// New imports
import { useForm } from "react-hook-form";

// Local imports
import TermsModal from "../modals/TermsModal";

function Register({
  // isAuthenticated,
  emailSend,
  registerNewUser,
  // createMessage,
}) {
  const [showModal, setShowModal] = useState(false);
  // const [givenName, setGivenName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [userName, setUserName] = useState("");
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [password2, setPassword2] = useState("");
  const [commonError, setCommonError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // console.log(data);
    let password = data.password;
    let password2 = data.password2;
    if (password !== password2) {
      setCommonError(true);
      setErrorMessage("Passwords do not match");
    } else {
      onRegister(data);
    }
  };

  const showErrorMessage = (message) => {
    console.log("Show error message");
    console.log(message);
    setCommonError(true);
    if (message.toLowerCase().includes("password")) {
      setErrorMessage(
        "Password must include special caracters (ex. =?.,), capital letters (ex. ABC) and numbers"
      );
    } else {
      setErrorMessage(message);
    }
  };

  // const onShowModal = () => {
  //   setShowModal(true);
  // };

  const onHideModal = () => {
    setShowModal(false);
  };

  const onRegister = (data) => {
    let newUser = new FormData();
    newUser.append('username', data.email);
    newUser.append('password', data.password);
    newUser.append('givenName', data.givenName);
    newUser.append('lastName', data.lastName);
    registerNewUser(newUser, showErrorMessage);
  };

  return (
    <div className="col-md-6 m-auto" style={{ maxWidth: "500px" }}>
      <div className="card card-body mt-5">
        {emailSend ? (
          <Fragment>
            <h1>Confirm your email!</h1>
            <p>... and then </p>
            <Link to="/login">
              <u>Login</u>
            </Link>
          </Fragment>
        ) : (
          <Fragment>
            <h2 className="text-center">Sign Up</h2>
            {commonError ? (
              <Alert variant="danger" style={{ textAlign: "center" }}>
                <b>{errorMessage}</b>
              </Alert>
            ) : (
              ""
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="givenName"
                  {...register("givenName", {
                    required: "Required",
                    maxLength: {
                      value: 50,
                      message: "You exeeded the max length",
                    },
                  })}
                />
                {errors.givenName && (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  >
                    {errors.givenName.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  {...register("lastName", {
                    required: "Required",
                    maxLength: {
                      value: 50,
                      message: "You exeeded the max length",
                    },
                  })}
                />
                {errors.lastName && (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  >
                    {errors.lastName.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  {...register("email", {
                    required: "Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                {errors.email && (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  >
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="lastName"
                  {...register("password", {
                    required: "Required",
                    maxLength: {
                      value: 50,
                      message: "You exeeded the max length",
                    },
                    minLength: {
                      value: 6,
                      message: "At least 6 required",
                    },
                  })}
                />
                {errors.password && (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  >
                    {errors.password.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password2"
                  {...register("password2", {
                    required: "Required",
                    maxLength: {
                      value: 50,
                      message: "You exeeded the max length",
                    },
                    minLength: {
                      value: 6,
                      message: "At least 6 required",
                    },
                  })}
                />
                {errors.password2 && (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  >
                    {errors.password2.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  value="terms of service"
                  {...register("terms", {
                    required: "Required",
                  })}
                />
                <label for="terms">
                  {" I have read and agree to "}

                  <Button
                    // style={{
                    //   color: "blue",
                    //   fontWeight: "500",
                    //   textDecoration: "underline",
                    //   cursor: "pointer",
                    // }}
                    variant="primary"
                    onClick={() => setShowModal(true)}
                  >
                    the terms of service
                  </Button>
                </label>
                {errors.terms && (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  >
                    {errors.terms.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    float: "right",
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#36737e",
                  }}
                >
                  <b>Register</b>
                </button>
              </div>
            </form>
          </Fragment>
        )}
      </div>
      <p style={{ marginTop: "5px", color: "white", textAlign: "center" }}>
        Already have an account?{" "}
        <Link to="/login">
          <b>Login</b>
        </Link>
      </p>
      <TermsModal
        showModal={showModal}
        handleClose={onHideModal}
        handleDone={onRegister}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  emailSend: state.auth.emailSend,
});

export default connect(mapStateToProps, {
  registerNewUser,
  createMessage,
})(Register);
