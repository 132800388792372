import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteUserFromOrgModal from '../modals/DeleteUserFromOrg';
import ChangeUserRoleInOrg from '../modals/ChangeUserRoleInOrg';

// import {DeleteGroupModal} from "../modals/DeleteGroupModal"

const options = [
  'Change User Role',
  'Remove from team',
];

const ITEM_HEIGHT = 48;

export default function LongMenu({
  changeUserRole,
  removeUserFromGroup,
  user,
  group,
  role,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDeleteUserModal, changeShowDeleteUserModal] = useState(false);
  const [showChangeRoleModal, changeShowChangeRoleModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteUserShow = () => {
    console.log('Delete User', user.username, group);
    changeShowDeleteUserModal(true);
  };

  const handleDeleteUserClose = () => {
    changeShowDeleteUserModal(false);
  };

  const handleDeleteUserFromOrgConfirm = () => {
    let users = [];
    users.push(user.username);
    // console.log(user.email)

    removeUserFromGroup(group, users);
    changeShowDeleteUserModal(false)
  };

  const handleChangeRoleShow = () => {
    console.log('Change Role', user.username, group);
    changeShowChangeRoleModal(true);
  };

  const handleChangeRoleClose = () => {
    // console.log(user, group);
    changeShowChangeRoleModal(false);
  };

  const handleChangeRoleConfirm = (new_role) => {
    let users = [];
    // users.push(user.username);
    user.push(user.hr_username)

    console.log(users, new_role)
    changeUserRole(group, users, role);
  };


  return (
    <>
    <div class="float-right">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} 
                    onClick={() => {
                    // Handle the option selected
                    switch(option) {
                        case options[0]: //add team member
                            console.log(options[0])
                            handleChangeRoleShow()
                            break;
                        case options[1]: //add sub-group/team
                            console.log(options[1])
                            handleDeleteUserShow()
                            break;
                        case options[2]: //delete
                          console.log(options[2])

                            break;
                            
                        default:
                            console.log('default')
                        }
                    setAnchorEl(null);
          }}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
    <DeleteUserFromOrgModal
      showDeleteUserFromOrgModal={showDeleteUserModal}
      handleDeleteUserFromOrgClose={handleDeleteUserClose}
      handleDeleteUserFromOrgConfirm={handleDeleteUserFromOrgConfirm}
      user={user}
      group={group}
    />
    <ChangeUserRoleInOrg
      showChangeUserInOrgModal={showChangeRoleModal}
      handleChangeUserInOrgClose={handleChangeRoleClose}
      handleChangeUserInOrgConfirm={handleChangeRoleConfirm}
      user={user}
      group={group}
      role={role}
    />
  </>
  );
};
