import axios from "axios";

import {
  USER_LOADED,
  USER_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  NEW_PASSWORD_REQUIRED,
  NEW_PASSWORD_SUCCESS,
} from "./types";

const ip = process.env.REACT_APP_BACKEND_DNS;

// CHECK TOKEN & LOAD USER
export const loadUser = (path) => async (dispatch, getState) => {
  // const loggedIn = getCookie('loggedIn');
  const loggedIn = localStorage.getItem('id');
  const refresh = localStorage.getItem('refresh');
  let options;
  if ( refresh ) {
    options = {headers: {"X-Refresh":refresh}}
  } else {
    options = {withCredentials:true}
  }

  dispatch({ type: USER_LOADING });
  if (!( loggedIn )) {
    // console.log('Not logged in')
    goToLogin(path);
  } else {
    return await axios
      .get(`${ip}/auth/refresh/`, options)
      .then((res) => {
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
        const idToken = res.data[0].IdToken;
        localStorage.setItem('id', idToken);
        return res.data;
      })
    }
};

// LOGIN USER
export const login = (username, password, showErrorMessage) => async (dispatch, getState) => {

  dispatch({ type: USER_LOADING });
  let formData = new FormData();
  formData.append("username", username);
  formData.append("password", password)

  await axios
    .post(`${ip}/auth/login/`, formData, {withCredentials: true})
    .then((res) => {
      // console.log(res)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      })
      const idToken = res.data[0].IdToken;
      localStorage.setItem('id', idToken);
      const host = window.location.hostname;
      if ( host !== 'portal.arboair.com' ) {
        const refreshToken = res.data[0].RefreshToken;
        localStorage.setItem('refresh', refreshToken);
      }
      return res.data;
    })
    .catch((err) => {
      if (err.type === 'NEW_PASSWORD_REQUIRED'){
        let payload = err.payload
        dispatch({
          type: NEW_PASSWORD_REQUIRED,
          payload: payload,
        });
      } else {
        console.log(err);
        showErrorMessage(err.message);
        dispatch({
          type: LOGIN_FAIL,
        });
      }
    })
};

// REGISTER USER
export const register = (formData, showErrorMessage) => async (dispatch) => {
  return await axios
    .post(`${ip}/auth/register/`, formData)
    .then((res) => {
      dispatch({
        type: REGISTER_SUCCESS,
      })
      return res.data
    })
    .catch((err) => {
      console.log(err)
      showErrorMessage(err.message)
      dispatch({
        type: REGISTER_FAIL,
      });
  });
};

export const newPasswordRequired = (formData, showErrorMessage) => async (dispatch) => {
  return await axios
    .put(`${ip}/auth/login/`, formData)
    .then((res) => {
      dispatch({
        type: NEW_PASSWORD_SUCCESS,
        payload: res.data
      })
      const idToken = res.data[0].IdToken;
      localStorage.setItem('id', idToken);
      const host = window.location.hostname;
      if ( host !== 'portal.arboair.com' ) {
        const refreshToken = res.data[0].RefreshToken;
        localStorage.setItem('refresh', refreshToken);
      }
      return res.data;
    })
    .catch((err) => {
      console.log(err)
      showErrorMessage(err.message)
      dispatch({
        type: REGISTER_FAIL,
      });
  });
};

// FORGOT PASSWORD
export const forgot_password = (username) =>async () => {

  let formData = new FormData();
  formData.append('username', username);

  return await axios
    .post(`${ip}/auth/forgot_password/`, formData);
};

// CONFIRM FORGOT PASSWORD
export const confirm_forgot_password = (username, password, code) =>async () => {

  let formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  formData.append('code', code);

  return await axios
    .put(`${ip}/auth/forgot_password/`, formData);
};


// LOGOUT USERshowErrorMessage
export const logout = () => (dispatch) => {
  const refresh = localStorage.getItem('refresh');
  let options;
  if ( refresh ) {
    options = {headers: {"X-Refresh":refresh}}
  } else {
    options = {withCredentials:true}
  }
  axios
  .get(`${ip}/auth/logout/`, options)
  .then(() => {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  });
  goToLogin();
};

// GET USER DJANGO PROFILE
export const getUserProfile = () => async (getState, dispatch) => {

  // let formData = new FormData();
  // formData.append('username', username);

  return await axios
    .get(`${ip}/user_profile/`, tokenConfig(getState));
};

// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get token from state
  // const token = getState().auth.jwtToken;
  const token = localStorage.getItem('id');
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

export function goToLogin(originalReq) {
  console.log(originalReq)
  let path = window.location.pathname;
  localStorage.removeItem('id');
  localStorage.removeItem('refresh');
  // console.log(localStorage.getItem('refresh'))
  if (!(path === '/' || path === '/login')) {
    //console.log('Redirecting')
    let origin = window.location.origin;
    let new_path = origin.concat(`/login?returnUrl=${path}`)
    window.location.replace(new_path)
  }
}