import React from "react";
import { Modal, Button, Overlay, Tooltip, Form } from "react-bootstrap";

function humanize(str) {
  var i, frags = str.split('_');
  for (i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
};

export default function NewOrderModal({
  showModal,
  handleClose,
  handleConfirm,
  onAnalysisNameChange,
  forestName,
  isShowingAnalysisNameTooltip,
  analysisTypes,
  changeAnalysisType,
  analysisTypeChecked,
  orderType,
  userData,
  maxRunsExceeded,
}) {

  // console.log(userData)

  const analysisList = Object.keys(analysisTypes).map(function(key) {
    let statName = humanize(analysisTypes[key].name);
    return (
      <div key={key} className="mb-3">
        <Form.Check 
          type={'checkbox'}
          id={key}
          label={`${statName}`}
          onChange={() => changeAnalysisType(key)}
        />
      </div>
    );
  });

  const checkPrice = (userType, orderType, maxExceeded) => {
    // console.log('User type', (userType));
    // console.log('Order Type', (orderType));
    // console.log(maxExceeded)
    let pricePerHektar = 25;
    let price = '';
    let diff = orderType - userType;
    if ( diff > 0 || maxExceeded ) {
      let userBinary = (userType).toString(2);
      let orderBinary = (orderType).toString(2);
      while (userBinary.length < orderBinary.length) {
        // console.log('Fixing length', userBinary)
        userBinary = '0' + userBinary;
      };
      // console.log('User', userBinary)
      // console.log('Order', orderBinary)
      let numOfDiffTypes = 0;
      for (const index in userBinary) {
        let userChar = userBinary[index];
        let orderChar = orderBinary[index];
        if ( maxExceeded ) {
          if ( orderChar === '1' ) {
            numOfDiffTypes += 1;
          };
        } else if ((userChar !== orderChar) && (userChar !== '1')) {
          numOfDiffTypes += 1;
        };
      };
      // console.log('Num of differences between userType and orderType', numOfDiffTypes);
      let orderPrice = pricePerHektar * numOfDiffTypes;
      if ( maxExceeded ) {
        price = (
          <>
          <p>
            <b>
              Uploading this order will exceed the maximum alloted orders in your subscription and you will be debitted {pricePerHektar} kr/ha
              per type of order you choose
            </b>
          </p>
          <p>
            Based on your selection the price will be {orderPrice} kr/ha and debitted after the order has completed.
          </p>
          </>);
      } else if ( diff > 0 ) {
        price = (
          <>
          <p>
            <b>
              Since some of the selcted order types are outside the scope of your subscription you will be debitted {pricePerHektar} kr/ha
              per type difference between your subscription and the order types you have selected
            </b>
          </p>
          <p>
            Based on your selection the price will be {orderPrice} kr/ha and debitted after the order has completed.
          </p>
          </>);
      };
    };
    return price
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      centered
      scrollable
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>New Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to make a new analysis on {forestName}? </p>
          {( userData !== null ) ? (
            (!(userData.is_premium) && (analysisTypeChecked)) ? 
            (checkPrice(userData.user_type, orderType, maxRunsExceeded)) : 
            ('')
          ) : ('')
          }
          <b>
            <p>
              By clicking "Choose Extent" you will be taken to the map where you can choose the area for your order, no area choosen means the whole forest will be the area.
            </p>
            <p>
              This order can be deleted until upload has completed & analysis has started.
            </p>
          </b>
        <input 
          type='analysis_name' 
          id='analysis_name' 
          className="form-control validate" 
          placeholder="Analysis Name"
          onChange={(e) => onAnalysisNameChange(
            document.getElementById("analysis_name")
          )}
        />
        <Overlay
              target={document.getElementById("analysis_name")}
              show={isShowingAnalysisNameTooltip}
              placement="bottom"
            >
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  Only letters and numbers
                </Tooltip>
              )}
        </Overlay>
        <p>
          What type of order do you want to create?
        </p>
        <Form>
          {analysisList}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        { ( isShowingAnalysisNameTooltip ) ? ( '' ) : (
          ( analysisTypeChecked ) ? ( 
            ( maxRunsExceeded ) ? (
              <Button variant="primary" onClick={() => handleConfirm(true)}>
              Choose Extent!
              </Button>
            ) : (
            <Button variant="primary" onClick={() => handleConfirm(false)}>
            Choose Extent!
            </Button>
          )) : ( '' ))}
      </Modal.Footer>
    </Modal>
  );
};