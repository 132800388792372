// Packages imports
import React from "react";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <div style={{ display:"flex", flexDirection:"column", height:"100%", width:"100%", textAlign: "center", alignSelf:"center",  alignItems:"center", justifyContent:"center"}}>
      <h1>Page not found!</h1>
      <p>
        <Link to="/">Go to Home </Link>
      </p>
    </div>
  );
}
