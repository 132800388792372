// Packages imports
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { Redirect } from "react-router-dom";

// Local components imports
import ResultsList from "../subpages/ResultsList";
// import ResultsDetailed from "../subpages/ResultsDetailed";

// Store imports
import { getOrders, changePage, deleteOrder } from "../../actions/orders";

// css imports
import css from "../../styles/MyForests.module.css";

class Results extends Component {
  state = {
    redirect: null,
    order: null,
  };

  constructor (props) {
    super(props);
    // this.props.getOrders();
    this.props.changePage(
      this.props.current_page,
      this.props.sort_method,
      this.props.sort_acs,
      this.props.objects_per_page,
      this.props.search_key,
      ''
    );
  };

  selectDetailedOrder = (order) => {
    this.setState({
      redirect: true,
      order: order,
    });
  };

  static propTypes = {
    orders: PropTypes.array.isRequired,
    deleteOrder: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // this.props.getOrders();
    // this.props.changePage(
    //   this.props.current_page,
    //   this.props.sort_method,
    //   this.props.sort_acs,
    //   this.props.objects_per_page,
    //   ''
    // );
    
  };

  render() {
    if (this.state.redirect) {
      this.props.history.push("/analysis/" + this.state.order.id);
    }
    return (
      <div className={css.flexColumnDiv}>
        <ResultsList
          orders={this.props.orders}
          deleteOrder={this.props.deleteOrder}
          selectOrder={this.selectDetailedOrder}
          objects_per_page={this.props.objects_per_page}
          {...this.props}
          state={this.state}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  current_page: state.orders.current_page,
  objects_per_page: state.orders.objects_per_page,
  sort_method: state.orders.sort_method,
  sort_acs: state.orders.sort_acs,
  search_key: state.orders.search_key,
});

export default connect(mapStateToProps, { getOrders, changePage, deleteOrder })(Results);
