import React, { Component} from "react";
import css from "../../styles/Drones.module.css";
import PropTypes from "prop-types";

import { getSupportedDrones } from "../../actions/orders";
import { connect } from "react-redux";
var sup = [];

class Drones extends Component {
  // constructor(props) {
  //   super(props);
  // };

  componentDidMount = async() => {
    sup = await this.props.getSupportedDrones();
  }
  
  static propTypes = {
    supportedDrones: PropTypes.any.isRequired,
  };

  render() {
    return (
      <>
      <div className={css.mainContent}>
        <Header />
        <MiddleBody 
          supportedDrones={sup}
        />
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  supportedDrones: state.orders.supported_drones
})

export default connect(mapStateToProps, {getSupportedDrones})(Drones)

class Header extends Component {
  render() {
    return (
      <div className={css.headerImage}>
        <div className={css.headerTextHolder}></div>
        <div className={css.headerText}>
          Arboair Drone Guide
        </div>
      </div>
    );
  }
}

class MiddleBody extends Component {
  
  render() {
    const testList = (this.props.supportedDrones.length === 0) ? (' ') : 
      (
        Object.keys(this.props.supportedDrones).map(drone => {
          return <div key={drone+' div'}><li key={drone}> <b>{drone}</b></li><br key={drone+' br'}></br></div>
        })
      )
    return (
      <>
      <div className={css.middleBody}>
          <p>Forest Mapper can right now analyze images from the following drone types:</p>
            <ul className={css.droneList}>
              {testList}
            </ul>
            <p>Do you not have any of these drones? Request an update for Forest Mapper.</p>
            <p></p>
            <h2 style={{ color: "#00000", fontWeight: "bold", fontSize: "50" }}>Step 1. Permits</h2>
            <p>Make sure you have all the permits and insurances to fly. Always make sure that you are allowed to fly over the area that you fly!</p>
            <p>Make sure not to capture any images of protected objects and do not upload any images that contain anything illegal (like military bases etc.).</p>
            <h2 style={{ color: "#00000", fontWeight: "bold", fontSize: "50" }}>Step 2. Flight Planning Application</h2>
            <p>Use a flight planning application like DJI Ground Station Pro, DroneDeploy, Pix4D Mapper or any other system of you preference.</p>
              <ul className={css.droneList}>
                <li>For experiences drone pilotes we recommend using DroneDeploy because it follows the elevation in the terrain, and thereby the overlap is more consistant.</li>
                <br></br><li>For those with less experience we recommend DJI GS Pro, see guide below.</li>
              </ul>
            <h2 style={{ color: "#00000", fontWeight: "bold", fontSize: "50" }}>Step 3. Mission Planning</h2>
            <p>Settings for analysis: </p>
            <ul className={css.droneList}>
              <li>Flight hight = 110m</li><br></br>
              <li>Overlapp sideways = 30%</li><br></br>
              <li>Overlapp frontways = 30%</li><br></br>
              <li>Camera angle = 90° (downwards)</li><br></br>
              <li>Camera setting auto</li><br></br>
            </ul>
            <p>If sunny we recommend to lower the exposure by a number of points to avoid to bright images.</p>
            <br></br>
            <h2 style={{ color: "#00000", fontWeight: "bold", fontSize: "50" }}>Flight Planning Application Guide DJI GS Pro</h2>
            <p>This application works best when you are not used to fly a drone or when the terrain is difficult to start and land in.</p>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 8fr)", gridGap: 20 }}>
              <div>
                <h3 style={{ color: "#0b666e", fontSize: "12" }}>Step 1 - Plan mission</h3>
              </div>
              <div>
                <h3 style={{ color: "#0b666e", fontSize: "12" }}>Step 2 - Choose mission type</h3>
              </div>
              <div>
                <img alt='Step1' style={{ height: 300, width: 400 }} src={ require('../../images/SupportedDronesImages/Step1.png') }/>
                <p>Click on the <b>+</b> to create a new mission</p>
              </div>
              <div>
              <img alt='Step2' style={{ height: 300, width: 400 }} src={ require('../../images/SupportedDronesImages/Step2.png') }/>
                <p>Choose <b>PhotoMap</b></p>
              </div>
              <div>
                <h3 style={{ color: "#0b666e", fontSize: "12" }}>Step 3 - Choose how to map area</h3>
              </div>
              <div>
                <h3 style={{ color: "#0b666e", fontSize: "12" }}>Step 4 - Map your area</h3>
              </div>
              <div>
              <img alt='Step3' style={{ height: 300, width: 400 }} src={ require('../../images/SupportedDronesImages/Step3.png') }/>
                <p>Choose <b>Tap</b></p>
              </div>
              <div>
              <img alt='Step4' style={{ height: 300, width: 400 }} src={ require('../../images/SupportedDronesImages/Step4.png') }/>
                <p>Tap to put a polygon on the map. Drage the <b>white dots</b> to your borders and press onthe small <b>+</b> to add another point <br></br>The <b>blue point</b> is your home poistion.<br></br>The <b>green point</b> with an <b>S</b> is your drone's mission starting point and is where your drone starts to take pictures.</p>
              </div>
              <div>
                <h3 style={{ color: "#0b666e", fontSize: "12" }}>Step 5 - Check Basic settings</h3>
              </div>
              <div>
                <h3 style={{ color: "#0b666e", fontSize: "12" }}>Step 6 - Check advanced settings</h3>
              </div>
              <div>
              <img alt='Step5' style={{ height: 300, width: 400 }} src={ require('../../images/SupportedDronesImages/Step5.png') }/>
                <p>Check your <b>basic settings</b> and match them towards the requirements for analysis above.</p>
              </div>
              <div>
              <img alt='Step6' style={{ height: 300, width: 400 }} src={ require('../../images/SupportedDronesImages/Step6.png') }/>
                <p>Check you <b>advanced settings</b> and match them towards the requirements for analysis above.</p>
              </div>
              <div>
                <h3 style={{ color: "#0b666e", fontSize: "12" }}>Step 7 - Save your mission</h3>
              </div>
              <div>
                <h3 style={{ color: "#0b666e", fontSize: "12" }}>Step 8 - Go fly!</h3>
              </div>
              <div>
              <img alt='Step7' style={{ height: 300, width: 400 }} src={ require('../../images/SupportedDronesImages/Step7.png') }/>
                <p><b>Save</b> and <b>name</b> your mission so you know how you did last time when you are going to fly again.</p>
              </div>
              <div>
              <img alt='Step8' style={{ height: 300, width: 400 }} src={ require('../../images/SupportedDronesImages/Step8.png') }/>
                <p>Last step. Press <b>fly</b> and good luck! <br></br>Make sure you have gone through all steps above!</p>
              </div>
            </div>
          </div>
        
        <div className={css.dronesImage} />
        {/* </div> */}
      </>
    );
  }
}

