// Package imports
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Alert,Spinner } from "react-bootstrap"
import { connect } from "react-redux";

// Local imports
import { forgot_password, confirm_forgot_password } from "../../actions/auth"
import { createMessage } from "../../actions/messages";


// var userPool = 'DEBUG'
// var AmazonCognitoIdentity = require("amazon-cognito-identity-js");

class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      emailSent: false,
      email: "",
      error: false,
      errorMessage: "",
      code: "",
      newPassword: "",
      redirect: false,
      passwordResetSuccess: false,
      confirmPassword: false,
    };
  }


  displayAlert = (message, error) => {
    if (error) {
      createMessage({ customError: message });
    } else {
      createMessage({ customSuccess: message });
    }
  };

  showErrorMessage = (message) => {
    this.setState({
      error: true,
      errorMessage: message,
      confirmPassword: false
    })
  }

  hideErrorMessage = () => {
    this.setState({
      error: false,
      errorMessage: ""
    })
  }

  findCognitoUser = () => {
    if (this.state.username !== "") {
      this.props.forgot_password(this.state.username)
      .then((res) => {
        console.log(res.data.CodeDeliveryDetails.Destination)
        this.setState({
          email: res.data.CodeDeliveryDetails.Destination,
          emailSent: true
        }, function() {
          this.inputVerificationCode();
          this.hideErrorMessage();
        })
      })
      
    } else {
      this.showErrorMessage("No username provided! Try again")
    };
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.findCognitoUser()

  };

  onCodeSubmit = (e) => {
    e.preventDefault();
    this.setState({
      confirmPassword: true  
    })
    this.inputVerificationCode();

  };

  inputVerificationCode = () => {
    // eslint-disable-next-line no-unused-vars
    let { username, code, newPassword } = this.state;
    if (code === "" || newPassword === "") {
      this.showErrorMessage("Fill all of the fields!")
    } else {
      this.props.confirm_forgot_password(username, newPassword, code)
      .then(() => {
        this.displayAlert('Password confirmed!')
        console.log("Success resetting password!")
        setTimeout(() => { this.redirectToLogin(); }, 3000);
        this.setState({
          passwordResetSuccess: true
        })
      })
    };
  };

  redirectToLogin = () => {
    this.setState({
      redirect: true
    })
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCodeChange = (e) => this.setState({ [e.target.name]: e.target.value });

  resendEmail = () =>{
    this.props.createMessage("New email sent")
    this.findCognitoUser()
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />
    }
    if (this.props.isAuthenticated) {
      return <Redirect to="/my-forests" />;
    }
    const { username, code, newPassword } = this.state;
    return (
      <div className="col-md-6 m-auto" style={{ maxWidth: "500px" }}>
        <div className="card card-body mt-5">

          {
            !this.state.emailSent ?
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <h2 className="text-center">Reset Password</h2>
                  {this.state.error ?
                    <Alert variant="danger" style={{ textAlign: "center" }}>
                      <b>{this.state.errorMessage}</b>
                    </Alert> : ""}
                  <label>Your email:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    onChange={this.onChange}
                    value={username}
                  />
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      float: "right",
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#36737e",
                    }}
                  >
                    <b>Send verification code</b>
                  </button>
                </div>
              </form>
              :
              this.state.passwordResetSuccess ?
                <>
                  <h3>Your password has been reset</h3>
                  <p>Redirecting you to login page...</p>
                </>
                :
                <form onSubmit={this.onCodeSubmit}>
                  <div className="form-group">
                    {
            !this.state.loggingIn ?
              <h2 className="text-center">Reset Password</h2>
              :
              <div style={{display:"flex", justifyContent:"center"}}><h2 className="text-center">Resetting </h2><Spinner animation="grow" /></div>}
                    {/* <p>We've sent you an email to: {this.state.email}</p> */}
                    <p>If your email exists in our userbase you will shortly receive a code</p>
                    {this.state.error ?
                      <Alert variant="danger" style={{ textAlign: "center" }}>
                        <b>{this.state.errorMessage}</b>
                      </Alert> : ""}
                    <label>Type the code here:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                      onChange={this.onCodeChange}
                      value={code}
                    />
                  </div>
                  <div className="form-group">
                    <label>Type the new password (should include a number and special sign (*_! etc)):</label>
                    <input
                      type="text"
                      className="form-control"
                      name="newPassword"
                      onChange={this.onCodeChange}
                      value={newPassword}
                    />
                  </div>
                  <div className="form-group">

                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        float: "right",
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#36737e",
                      }}
                    >
                      <b>Confirm password</b>
                    </button>
                    <Button variant="secondary" size="sm" block onClick={() => {this.resendEmail()}}>Resend email <i class="far fa-paper-plane"></i></Button>
                  </div>
                </form>
          }
        </div>
        <p style={{ marginTop: "5px", color: "white", textAlign: "center" }}>
          Go back to {" "}
          <Link to="/login">
            <b>Login</b>
          </Link>
        </p>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  forgot_password,
  confirm_forgot_password
})(ForgotPassword);
