// Packages imports
import exifr from 'exifr'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { Button, Form, Overlay, Table, Tooltip } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { connect } from 'react-redux'
// import XMP from "xmp-js";
// import Topography from 'leaflet-topography'

// Local components imports
//import WebMapView from "./WebMapView.js";
import CheckListModal from '../modals/CheckListModal'
import { LeafletMapView as WebMapView } from './LeafletMapView.js'

// Local imports
import { maxImages } from '../../GLOBAR_PARAMETERS'

// Store imports
import {
  addImages,
  // deleteOrder,
  addNewOrder,
  addOrderImage,
  addOrderImages,
  addUserInputToUpload,
  deleteFailedOrderImages,
  finishOrder,
  getFailedOrders,
  getSupportedDrones,
  getZeroOrders,
} from '../../actions/orders'

import { getUserProfile } from '../../actions/auth'

import { getForests } from '../../actions/myforest'

import { createMessage } from '../../actions/messages'
import {
  sendErrorNotification,
  sendNotification,
} from '../../actions/notifications'

import {
  addCoords,
  addImage,
  deleteAllImages,
  deleteImage,
  getElevation,
  getImages,
} from '../../actions/dropzone'

// css imports
import css from '../../styles/DropZone.module.css'

// var MapboxElevation = require('mapbox-elevation');
// var getElevation = MapboxElevation("pk.eyJ1Ijoid29qYWN6ZWsyNyIsImEiOiJja285d3k3eHowbTM5MnBxbWNnY3Q4Y28yIn0.gVuyHzOadydobluyxxHE9A")

// const options = {
//   token: 'pk.eyJ1Ijoid29qYWN6ZWsyNyIsImEiOiJja285d3k3eHowbTM5MnBxbWNnY3Q4Y28yIn0.gVuyHzOadydobluyxxHE9A'
// }

const DropZone = ({
  order_id,
  upload_running,
  redux_images,
  redux_coords,
  zero_orders,
  failed_orders,
  addImages,
  deleteFailedOrderImages,
  addOrderImage,
  addOrderImages,
  finishOrder,
  getZeroOrders,
  getFailedOrders,
  createMessage,
  addImage,
  addCoords,
  deleteImage,
  deleteAllImages,
  addNewOrder,
  getForests,
  forests,
  sendErrorNotification,
  getSupportedDrones,
  getElevation,
  user,
  getUserProfile,
  addUserInputToUpload,
}) => {
  const [validCoords, setValidCoords] = useState([...redux_coords])

  const [showCheckListModal, setShowCheckListModal] = useState(false)
  const [selectedOrder, changeSelectedOrder] = useState(0)

  const [isDronePilot, changeIsDronePilot] = useState(false)
  const [userType, changeUserType] = useState(0)
  const [maxRunsExceeded, changeMaxRunsExceeded] = useState(false)
  const [isPremium, changeIsPremium] = useState(false)

  const [area, updateArea] = useState(0)
  const [analysisName, changeAnalysisName] = useState('')
  const [picToFar, changePicToFar] = useState(false)
  const [outOfBounds, changeOutOfBounds] = useState(false)

  const [selectedForest, changeSelectedForest] = useState(0)
  const [isShowingAnalysisNameTooltip, showAnalysisNameTooltip] =
    useState(false)

  const [uploadFinished, changeUploadFinished] = useState(true)

  const [failed, changeShowFailedOrders] = useState(false)
  // const [dragging, changeDraggingState] = useState(false);
  const [isGallery, changeGalleryState] = useState(false)

  const modalImageRef = useRef()
  const modalRef = useRef()

  const fileInputRef = useRef()

  const numOfFiles = useRef()

  const [analysisTypeChecked, changeAnalysisChecked] = useState(false)

  const [analysisTypes, changeAnalysisType] = useState([
    { name: 'mosaic', active: true },
    { name: 'damages', active: true },
    { name: 'species_detection', active: true },
    { name: 'tree_count', active: true },
  ])

  const [orderType, changeOrderType] = useState(15)

  const [extraOrderData, changeExtraOrderData] = useState({
    foggy: false,
    flightHeight: 110,
  })

  // const user = localStorage.getItem('id');

  // const selectedOrderTooltipRef = useRef();
  const selectedForestTooltipRef = useRef()

  // TODO: Uncomment for release
  useBeforeunload((event) => {
    console.log('Unload')
    if (uploadFinished !== true) {
      sendErrorNotification('There is an upload running')
      event.preventDefault()
    }
  })

  useEffect(() => {
    // console.log(
    //   "This only happens ONCE.  But it happens AFTER the initial render."
    // );
    getForests()
    getZeroOrders()
    getFailedOrders()
    if (typeof window.Beacon !== 'undefined') {
      window.Beacon('suggest', ['61d56b30d268f00e10385829'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDrop = useCallback(async (files) => {
    // Do something with the files
    // console.log(user);
    let userProfile = await getUserProfile()
    // console.log(userProfile);
    changeIsPremium(userProfile.data.is_premium)
    changeUserType(userProfile.data.user_type)
    if (userProfile.data.is_org_member) {
      console.log(userProfile.data)
      let tempArray = [
        { name: 'mosaic', active: true },
        { name: 'damages', active: true },
        { name: 'species_detection', active: true },
        { name: 'tree_count', active: false },
      ]
      let anyChecked = 0
      let analysisType = ''
      for (const index in tempArray) {
        if (tempArray[index].active) {
          anyChecked += 1
        }
        let active = tempArray[index].active ? '1' : '0'
        analysisType += active
      }
      let analysisValue = parseInt(analysisType.split('').reverse().join(''), 2)
      if (anyChecked > 0) {
        changeAnalysisChecked(true)
      } else {
        changeAnalysisChecked(false)
      }
      changeAnalysisType(tempArray)
      changeOrderType(analysisValue)
      console.log(anyChecked, tempArray, analysisValue)
    }

    if (files.length) {
      if (
        files.length > maxImages ||
        numOfFiles.current + files.length > maxImages
      ) {
        displayAlert(`Max ${maxImages} images in an order!`)
        displayAlert(
          `You have ${numOfFiles.current} and are trying to add ${files.length}`
        )
        return
      }

      var invalidFiles = 0
      var progressCounter = 0

      // function elevationFinder(file) {
      //   return new Promise(resolve => {
      //     var reader = new FileReader();
      //     reader.onload = e => {
      //       let xmp = new XMP(e.target.result),
      //           raw = xmp.find();
      //       // do what you want with `raw` or `parsed` XMP
      //       // console.log(xmp)
      //       // console.log(raw)
      //       // console.log(parsed)
      //       let parser = new DOMParser();
      //       let xmlDoc = parser.parseFromString(raw,"text/xml").getElementsByTagName('rdf:RDF')[0].childNodes[1].outerHTML;
      //       console.log(xmlDoc)
      //       let regex = /drone-dji:RelativeAltitude="\+\d+(\.\d+)*"/g;
      //       let elevationString = xmlDoc.match(regex)[0];
      //       regex = /\d+(\.\d+)*/g;
      //       let elevation = elevationString.match(regex)[0];
      //       resolve(elevation)
      //     };
      //     reader.readAsDataURL(file);
      //   })
      // }

      async function getGPSData(files) {
        var unsupported = false

        //HERE FOR DEBUG
        // let maxEle = 0;
        // let minEle = 1000;
        // let maxAlt = 0;
        // let minAlt = 1000;
        // let maxFOV = 0;
        // let minFOV = 1000;

        let supported_drones
        await getSupportedDrones().then((res) => {
          // console.log(res)
          supported_drones = res
        })
        // console.log(supported_drones)

        Promise.all(
          files.map(async (current, index) => {
            // Check the metadata
            // console.log(index, current.name)
            if (validateFile(current)) {
              try {
                await exifr
                  .parse(current)
                  .then((metaData) => {
                    let [make, model] = [metaData.Make, metaData.Model]
                    // console.log('Camera:', make + model);
                    let checkTest = make + ' ' + model in supported_drones
                    if (!checkTest)
                      throw new Error(
                        `${make} ${model} Drone sensor data unkown`
                      )
                  })
                  .catch((e) => {
                    console.log(e)
                    console.log('File', current.name)
                    unsupported = true
                  })
                if (unsupported)
                  throw new Error('DroneTypeNotSupportedException')
                //Get constants
                let { Make, Model, FocalLength, GPSAltitude } =
                  await exifr.parse(current)
                let sensorWidth = supported_drones[Make + ' ' + Model]
                let { latitude, longitude } = await exifr.gps(current)

                //Calculate values based on constants
                let elevation
                // let latLng = {
                //   'lat': latitude,
                //   'lng': longitude
                // }
                // console.log(latLng)
                // await getElevation(latLng).then((res) => elevation = res);
                elevation = 130
                let height = GPSAltitude - elevation
                // console.log(GPSAltitude, elevation, height);
                let angleOfDistance =
                  2 * Math.atan(sensorWidth / (2 * FocalLength))
                let fieldOfView = 2 * (Math.tan(angleOfDistance / 2) * height)

                //HERE FOR DEBUG
                // if ( elevation > maxEle ) {
                //   maxEle = Math.round(elevation);
                // }
                // if ( elevation < minEle ) {
                //   minEle = Math.round(elevation);
                // }
                // if ( GPSAltitude > maxAlt ) {
                //   maxAlt = Math.round(GPSAltitude);
                // }
                // if ( GPSAltitude < minAlt ) {
                //   minAlt = Math.round(GPSAltitude);
                // }
                // if ( fieldOfView > maxFOV ) {
                //   maxFOV = Math.round(fieldOfView);
                // }
                // if ( fieldOfView < minFOV ) {
                //   minFOV = Math.round(fieldOfView);
                // }

                addImage(current)
                progressCounter += 1
                onFilesChanged(numOfFiles.current + 1)
                showReadingFilesModal(files.length, progressCounter)
                return {
                  long: longitude,
                  lat: latitude,
                  name: current.name,
                  alt: GPSAltitude,
                  sensorWidth: sensorWidth,
                  focalLength: FocalLength,
                  // imageURL: window.URL.createObjectURL(current),
                  // imageUrl2: await loadImage(current),
                  fov: fieldOfView,
                }
              } catch (e) {
                // console.log(e);
                invalidFiles += 1
              }
            } else {
              invalidFiles += 1
            }
          }, [])
        ).then(async (imagesWithGPS) => {
          // HERE FOR DEBUG
          // console.log(
          //   'Elevation',
          //   'Diff', maxEle - minEle,
          //   'Max', maxEle,
          //   'Min', minEle
          // )
          // console.log(
          //   'Altitude',
          //   'Diff', maxAlt - minAlt,
          //   'Max', maxAlt,
          //   'Min', minAlt
          //   )
          // console.log(
          //   'FOV',
          //   'Diff', maxFOV - minFOV,
          //   'Max', maxFOV,
          //   'Min', minFOV,
          //   )

          let filteredImages = imagesWithGPS.filter((current) => {
            if (current) {
              return current
            } else {
              return null
            }
          })
          if (filteredImages.length > 0) {
            setValidCoords((prevArray) => [...prevArray, ...filteredImages])
            addCoords(filteredImages)
          }
          if (invalidFiles !== 0) {
            displayAlert(
              `${invalidFiles} unsuported files have been deleted`,
              true
            )
          }
          hideReadingFilesModal()
        })
      }
      files = Array.from(files)
      await getGPSData(files)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
    }),
    [isDragActive]
  )

  const uploadModalRef = useRef()
  const uploadRef = useRef()
  const progressUploadRef = useRef()
  const progressUploadBarRef = useRef()
  const progressReadingRef = useRef()
  const progressReadingBarRef = useRef()

  const completeModalRef = useRef()
  const completeRef = useRef()

  const deleteModalRef = useRef()
  const deleteRef = useRef()

  const checklistModalRef = useRef()

  // Removes the file from the file list
  const removeFile = (name, num = null) => {
    // find the index of the item
    // remove the item from array
    var validFileIndex
    if (Number.isInteger(num)) {
      validFileIndex = num
    } else {
      validFileIndex = redux_images.findIndex((e) => e.name === name)
    }

    validCoords.splice(validFileIndex, 1)
    // update validFiles array
    setValidCoords([...validCoords])
    onFilesChanged(numOfFiles.current - 1)

    // Remove files from the REDUX
    deleteImage(validFileIndex)
  }
  const removeAllFiles = () => {
    setValidCoords([])
    deleteAllImages()
    onFilesChanged(0)
  }

  const setCheckListModal = (state) => {
    setShowCheckListModal(state)
  }

  const onUploadButtonClicked = () => {
    setCheckListModal(true)
  }

  const onFilesChanged = (state) => {
    if (isNaN(state)) {
      numOfFiles.current = 1
    } else {
      numOfFiles.current = state
    }
  }

  // Helper function to validate a file
  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/jpg']
    if (validTypes.indexOf(file.type) === -1) {
      return false
    }
    return true
  }

  // Checks the file size
  const fileSize = (size) => {
    if (size === 0) return '0 Bytes'
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(size) / Math.log(k))
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
  }

  const closeModal = () => {
    modalRef.current.style.display = 'none'
    modalImageRef.current.style.backgroundImage = 'none'
  }

  const displayAlert = (message, error) => {
    if (error) {
      createMessage({ customError: message })
    } else {
      createMessage({ customSuccess: message })
    }
  }

  const displayCompletedModal = () => {
    if ((redux_images.length = 0)) {
      completeModalRef.current.style.display = 'none'
    } else {
      completeModalRef.current.style.display = 'block'
      completeRef.current.innerHTML = 'Files succesfully uploaded'
      changeUploadFinished(true)
      setTimeout(function () {
        completeModalRef.current.style.display = 'none'
        var origin = window.location.origin
        // console.log('Yeet', uploadFinished);
        var new_path = origin.concat('/results')
        window.location.replace(new_path)
      }, 2000)
    }
  }

  const showReadingFilesModal = (totLen, progressCounter) => {
    completeModalRef.current.style.display = 'block'
    completeRef.current.innerHTML = `Reading file ${progressCounter} of ${totLen}`
    const readingPercentage = Math.floor((progressCounter / totLen) * 100)
    progressReadingRef.current.innerHTML = `${readingPercentage}%`
    progressReadingBarRef.current.style.width = `${readingPercentage}%`
  }

  const hideReadingFilesModal = () => {
    completeModalRef.current.style.display = 'none'
    progressReadingRef.current.style.innerHTML = ''
    progressReadingBarRef.current.style.width = '0%'
  }

  // uploads the images when the order id is available
  useEffect(() => {
    async function imageUpload() {
      if (upload_running === true) {
        uploadModalRef.current.style.display = 'block'
        uploadRef.current.innerHTML = 'Preparing files for upload...'
        let form_data = new FormData()
        form_data.append('order', order_id)
        let imageNames = []
        for (var i = 0; i < redux_images.length; i++) {
          let image = redux_images[i]
          // console.log(image)
          // let form_data = new FormData();
          // form_data.append("order", order_id);
          // form_data.append("imageName", image.name);
          imageNames.push(image.name)
          // await addOrderImage(
          //   form_data,
          //   image,
          //   uploadRef,
          //   progressUploadRef,
          //   progressUploadBarRef,
          //   uploadModalRef,
          //   displayCompletedModal
          // );
        }
        form_data.append('imageNames', imageNames)
        await addOrderImages(
          form_data,
          redux_images,
          uploadRef,
          progressUploadRef,
          progressUploadBarRef,
          uploadModalRef,
          displayCompletedModal
        )
      } else if ((upload_running === false) & (order_id !== null)) {
        changeUploadFinished(true)
        displayCompletedModal()
        let form_data = new FormData()
        form_data.append('order', order_id)
        setValidCoords([])
        finishOrder(form_data)
        getZeroOrders()
        getFailedOrders()
        changeSelectedForest(0)
      }
    }
    imageUpload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upload_running])

  const showDeletingModal = () => {
    deleteModalRef.current.style.display = 'block'
    deleteRef.current.innerHTML = `Please be patient while your order is being cleaned`
  }

  const hideDeletingModal = () => {
    deleteModalRef.current.style.display = 'none'
  }

  // Places the order in the backend, returns order id
  const placeOrder = async (exceed = false) => {
    changeAnalysisChecked(false)
    if (failed) {
      changeUploadFinished(false)
      showDeletingModal(selectedOrder)
      deleteFailedOrderImages(selectedOrder).then(async (res) => {
        hideDeletingModal()
        if (res.isAxiosError) {
          console.error(res.response.data, res.response.status)
          setTimeout(function () {
            sendErrorNotification(res.response.data)
            completeModalRef.current.style.display = 'none'
            var origin = window.location.origin
            var new_path = origin.concat('/upload')
            window.location.replace(new_path)
          }, 3000)
        } else {
          changeAnalysisType([
            { name: 'mosaic', active: false },
            { name: 'damages', active: false },
            { name: 'species_detection', active: false },
            { name: 'tree_count', active: false },
          ])
          changeOrderType(0)
          addUserInputToUpload(selectedOrder, extraOrderData)
          addImages(selectedOrder, redux_images.length)
          return true
        }
      })
    } else if (isDronePilot) {
      changeAnalysisType([
        { name: 'mosaic', active: false },
        { name: 'damages', active: false },
        { name: 'species_detection', active: false },
        { name: 'tree_count', active: false },
      ])
      changeOrderType(0)
      addUserInputToUpload(selectedOrder, extraOrderData)
      addImages(selectedOrder, redux_images.length)
      return true
    } else {
      // let analysisType = '';
      // for (const index in analysisTypes) {
      //   let active = analysisTypes[index].active ? '1' : '0';
      //   analysisType += active;
      // };
      setCheckListModal(false)
      // console.log('Manual Exceed', exceed)
      // console.log(analysisTypes, orderType)
      return addNewOrder(
        selectedForest,
        analysisName,
        area,
        orderType,
        exceed
      ).then(async (res) => {
        // console.log(res)
        if (res.status !== 201) {
          console.error(res.data, res.status)
          if (res.status === 402) {
            // sendErrorNotification(res.data);
            // console.log(maxRunsExceeded);
            changeMaxRunsExceeded(true)
            // removeAllFiles();
            completeModalRef.current.style.display = 'none'
            return false
          } else {
            console.log(window.location)
            setTimeout(function () {
              sendErrorNotification(res.data)
              setCheckListModal(false)
              removeAllFiles()
              completeModalRef.current.style.display = 'none'
            }, 3000)
          }
        } else {
          changeAnalysisType([
            { name: 'mosaic', active: false },
            { name: 'damages', active: false },
            { name: 'species_detection', active: false },
            { name: 'tree_count', active: false },
          ])
          changeOrderType(0)
          let order = res.data['serializer_data'].id
          addUserInputToUpload(order, extraOrderData)
          sendNotification('Your order has been created!')
          addImages(order, redux_images.length)
          return true
        }
      })
    }
  }

  // Opens image inspection modal
  const openImageModal = (file) => {
    modalRef.current.style.display = 'block'
    let imgSrc = loadImage(file)
    modalImageRef.current.style.backgroundImage = `url(${imgSrc})`
  }

  const loadImage = (file) => {
    window.URL = window.webkitURL || window.URL
    var img = document.createElement('img')
    img.src = window.URL.createObjectURL(file)
    img.onload = function () {
      window.URL.revokeObjectURL(img.src)
    }
    return img.src
  }

  const onChangeSelectedOrder = (e) => {
    let selectedIndex = e.target.selectedIndex
    if (selectedIndex !== 0) {
      if (failed) {
        selectedIndex = selectedIndex - 1
        changeSelectedOrder(failed_orders[selectedIndex][0])
      } else {
        selectedIndex = selectedIndex - 1
        changeSelectedOrder(zero_orders[selectedIndex][0])
      }
    } else {
      changeSelectedOrder(0)
    }
  }

  const onChangeSelectedForest = (e) => {
    if (givenForestId) {
      console.log(e)
      changeSelectedForest(e)
    } else {
      let selectedIndex = e.target.selectedIndex
      if (selectedIndex !== 0) {
        selectedIndex = selectedIndex - 1
        changeSelectedForest(forests[selectedIndex]['id'])
      } else {
        changeSelectedForest(0)
      }
    }
  }

  const onAnalysisNameChange = (input) => {
    var analysis_name = input.value
    if (analysis_name === '') {
      showAnalysisNameTooltip(false)
    } else if (!analysis_name.match(/^[A-Za-z0-9äöå\s]+$/i)) {
      showAnalysisNameTooltip(true)
      changeAnalysisName(null)
      return
    } else {
      showAnalysisNameTooltip(false)
    }
    changeAnalysisName(analysis_name)
  }

  const onShowAnalysisNameTooltip = (e) => {
    if (isShowingAnalysisNameTooltip) {
      showAnalysisNameTooltip(false)
    } else {
      showAnalysisNameTooltip(true)
    }
  }

  const onChangeAnalysisType = (index) => {
    let tempArray = []
    let anyChecked = 0
    let analysisType = ''
    tempArray = analysisTypes
    tempArray[index].active = !tempArray[index].active
    for (const index in tempArray) {
      if (tempArray[index].active) {
        anyChecked += 1
      }
      let active = tempArray[index].active ? '1' : '0'
      analysisType += active
    }
    let analysisValue = parseInt(analysisType.split('').reverse().join(''), 2)
    if (anyChecked > 0) {
      changeAnalysisChecked(true)
    } else {
      changeAnalysisChecked(false)
    }
    changeAnalysisType(tempArray)
    changeOrderType(analysisValue)
  }

  const onAddExtraOrderData = (e) => {
    let target = e.target
    // console.log(target)
    switch (target.type) {
      case 'checkbox':
        break
      case 'radio':
        // console.log(target.checked)
        // console.log(target.id)
        switch (target.id) {
          case 'foggy':
            changeExtraOrderData({
              ...extraOrderData,
              foggy: target.checked,
            })
            break
          case 'notFoggy':
            changeExtraOrderData({
              ...extraOrderData,
              foggy: !target.checked,
            })
            break
          default:
            break
        }
        break
      case 'number':
        // console.log(target.value)
        // console.log(target.name)
        switch (target.name) {
          case 'flightHeight':
            let value = parseInt(target.value)
            if (target.value === '') value = 110
            changeExtraOrderData({
              ...extraOrderData,
              flightHeight: value,
            })
            break
          default:
            break
        }
        break
      case 'range':
        // console.log(target.value)
        // console.log(target.name)
        switch (target.name) {
          case 'flightHeight':
            let value = parseInt(target.value)
            changeExtraOrderData({
              ...extraOrderData,
              flightHeight: value,
            })
            break
          default:
            break
        }
        break
      case 'text':
        break
      default:
        break
      // console.log(target)
    }
  }

  const onAreaUpdate = (e) => {
    updateArea(e)
  }

  const onPicToFar = (e) => {
    changePicToFar(e)
  }

  const onOutOfBounds = (e) => {
    changeOutOfBounds(e)
  }

  const showFailedOrders = (e) => {
    changeShowFailedOrders(e.target.checked)
  }

  const showZeroOrders = (e) => {
    changeIsDronePilot(e.target.checked)
  }

  const givenForestId = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  )['forestid']

  return (
    <div className={css.backgroud}>
      <div {...getRootProps({ style })}>
        {validCoords.length !== 0 && (
          <>
            <Overlay
              target={selectedForestTooltipRef.current}
              show={!(selectedForest !== 0 || selectedForest !== '')}
              placement='right'
            >
              {(props) => (
                <Tooltip id='overlay-example' {...props}>
                  Forest must be selected
                </Tooltip>
              )}
            </Overlay>
          </>
        )}
        {validCoords.length ? (
          <div className={css.mapViewDrop}>
            <WebMapView
              points={validCoords}
              point_type='points'
              onDelete={removeFile}
              areaUpdate={onAreaUpdate}
              pictureToFar={onPicToFar}
              pictureOutOfBounds={onOutOfBounds}
              getElevation={getElevation}
            />
          </div>
        ) : (
          ''
        )}

        <div className={css.wrapperGallery}>
          <div className={css.btnDropzone}>
            {/* UPLOAD IMAGES BUTTON */}
            {redux_images.length ? (
              outOfBounds ? (
                <div
                  style={{
                    marginRight: '10px',
                    fontSize: '20px',
                    color: 'red',
                    fontWeight: 'bold',
                  }}
                >
                  One or more pictures are to far from the others, <br />
                  this needs to be fixed before upload can continue
                </div>
              ) : (
                <div className={css.buttonDropzone}>
                  <Button
                    variant='primary'
                    onClick={() => onUploadButtonClicked()}
                  >
                    <i className='fas fa-cloud-upload-alt'></i> Upload Files
                  </Button>
                </div>
              )
            ) : (
              ''
            )}

            {/* DELETE ALL IMAGES BUTTON AND GALLERY CHECK*/}
            {redux_images.length ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignContent: 'center',
                  marginRight: '5px',
                  marginBottom: '15px',
                }}
              >
                <Button
                  variant='outline-danger'
                  onClick={() => removeAllFiles()}
                  style={{ marginRight: '10px' }}
                >
                  <i className='fas fa-trash'></i> Remove all{' '}
                  {redux_images.length} files
                </Button>
                <Form.Group controlId='formBasicCheckbox'>
                  <Form.Check
                    type='switch'
                    label='Show gallery'
                    onChange={() => changeGalleryState((prev) => !prev)}
                  />
                </Form.Group>
              </div>
            ) : (
              ''
            )}
          </div>
          {/* DROP DOWN BUTTON */}
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <input
              ref={fileInputRef}
              className={css.fileInput}
              type='file'
              multiple
              {...getInputProps()}
            />
            <div
              style={{
                position: 'realative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Button variant='info' onClick={open} size='lg'>
                <i className='fas fa-cloud-download-alt'></i> Click to select
                images
              </Button>
              <p style={{ lineHeight: '80px' }}>...or just drop them here!</p>
            </div>
          </div>
          {/* IMAGE GALLERY */}
          {redux_images.length !== 0 ? (
            isGallery ? (
              <ImageGallery
                {...{ redux_images, openImageModal, removeFile, fileSize }}
              />
            ) : (
              <ImageTable
                {...{ redux_images, openImageModal, removeFile, fileSize }}
              />
            )
          ) : (
            ''
          )}
        </div>
        <div className={css.modal} ref={modalRef}>
          <div className={css.overlay}></div>
          <span className={css.close} onClick={() => closeModal()}>
            X
          </span>
          <div
            className={css.modalImage}
            ref={modalImageRef}
            onClick={() => closeModal()}
          ></div>
        </div>

        <div className={css.uploadModal} ref={uploadModalRef}>
          <div className={css.overlay}></div>
          <div className={css.progressContainer}>
            <span ref={uploadRef}></span>
            <div className={css.progress}>
              <div className={css.progressBar} ref={progressUploadBarRef}></div>
              <div className={css.progressText} ref={progressUploadRef}></div>
            </div>
          </div>
        </div>

        <div className={css.uploadModal} ref={completeModalRef}>
          <div className={css.overlay}></div>
          <div className={css.progressContainer}>
            <span ref={completeRef}></span>
            <div className={css.progress}>
              <div
                className={css.progressBar}
                ref={progressReadingBarRef}
              ></div>
              <div className={css.progressText} ref={progressReadingRef}></div>
            </div>
          </div>
        </div>

        <div className={css.uploadModal} ref={deleteModalRef}>
          <div className={css.overlay}></div>
          <div className={css.progressContainer}>
            <span ref={deleteRef}></span>
            {/* <div className={css.progress}>
              <div className={css.progressBar} ref={progressReadingBarRef}></div>
              <div className={css.progressText} ref={progressReadingRef}></div>
            </div> */}
          </div>
        </div>
        {isDragActive ? (
          <div
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              display: 'flex',
              flexDirection: 'row',
              zIndex: '10000',
              justifyContent: 'center',
              alignSelf: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '50px',
              backgroundColor: 'rgba(0,0,0,0.7)',
            }}
          >
            <p>Drop files here!</p>
          </div>
        ) : (
          ''
        )}
      </div>
      <CheckListModal
        showModal={showCheckListModal}
        handleClose={() => setCheckListModal(false)}
        onAnalysisNameChange={onAnalysisNameChange}
        isShowingAnalysisNameTooltip={isShowingAnalysisNameTooltip}
        onShowAnalysisNameTooltip={onShowAnalysisNameTooltip}
        failed={failed}
        failedOrders={failed_orders}
        zeroOrders={zero_orders}
        showFailedOrders={showFailedOrders}
        hectars={area}
        analysisName={analysisName}
        onChangeSelectedOrder={onChangeSelectedOrder}
        handleUpload={(exceeded) => {
          placeOrder(exceeded).then((res) => {
            if (res) {
              setCheckListModal(false)
            }
          })
        }}
        orderID={selectedOrder}
        onChangeSelectedForest={onChangeSelectedForest}
        forests={forests}
        selectedForestTooltipRef={selectedForestTooltipRef}
        selectedForest={selectedForest}
        isDronePilot={isDronePilot}
        showZeroOrders={showZeroOrders}
        picToFar={picToFar}
        checklistModalRef={checklistModalRef}
        givenForestId={givenForestId}
        analysisTypes={analysisTypes}
        analysisTypeChecked={analysisTypeChecked}
        onChangeAnalysisType={onChangeAnalysisType}
        maxRunsExceeded={maxRunsExceeded}
        userType={userType}
        isPremium={isPremium}
        orderType={orderType}
        onAddExtraOrderData={onAddExtraOrderData}
        extraOrderData={extraOrderData}
      />
    </div>
  )
}

// Maps the state from redux to the props
const mapStateToProps = (state) => ({
  order_id: state.orders.current_order_id,
  upload_running: state.orders.upload_running,
  redux_images: state.dropzone.images,
  redux_coords: state.dropzone.coords,
  zero_orders: state.orders.zero_orders,
  failed_orders: state.orders.failed_orders,
  images_to_upload: state.orders.images_to_upload,
  forest_id: state.myforest.current_forest_id,
  forests: state.myforest.forests,
  user: state.auth.user,
})

export default connect(mapStateToProps, {
  addOrderImage,
  addOrderImages,
  finishOrder,
  getImages,
  getZeroOrders,
  createMessage,
  addImage,
  addCoords,
  deleteImage,
  deleteAllImages,
  getFailedOrders,
  addImages,
  deleteFailedOrderImages,
  getForests,
  addNewOrder,
  sendErrorNotification,
  getSupportedDrones,
  getElevation,
  getUserProfile,
  addUserInputToUpload,
})(DropZone)

function ImageGallery({ redux_images, openImageModal, removeFile, fileSize }) {
  var img = document.createElement('img')
  window.URL = window.webkitURL || window.URL
  return (
    <div className={css.fileDisplayContainer}>
      {redux_images.map((data, i) => (
        <div className={css.fileStatusBar} key={i}>
          <div className={css.objectContainer}>
            <div
              onClick={() => openImageModal(data)}
              style={{ padding: '10px' }}
            >
              <div className={css.imageContainer}>
                <div style={{ paddingBottom: '5px' }}>
                  {/* {img.src = window.URL.createObjectURL(data)} */}
                  <img
                    src={(img.src = window.URL.createObjectURL(data))}
                    className={css.imgGallery}
                    alt='Yours'
                  />
                  {
                    (img.onload = function () {
                      window.URL.revokeObjectURL(img.src)
                    })
                  }
                </div>
                <div className={css.fileTitle}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <span className={(css.fileName, css.fileError)}>
                      {data.name}
                    </span>
                    <span className={css.fileSize}>
                      ({fileSize(data.size)})
                    </span>{' '}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={css.fileRemove}
              onClick={() => removeFile(data.name)}
            >
              <div style={{ color: 'black', height: '30px' }}>
                <i className='fas fa-times-circle'></i>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

function ImageTable({ redux_images, openImageModal, removeFile, fileSize }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Table striped bordered hover style={{ maxWidth: '1000px' }}>
        <thead>
          <tr>
            <th>#</th>
            <th>Image Name</th>
            <th>Size</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {redux_images.map((data, i) => {
            return (
              <tr key={i + 1}>
                <td>{i + 1}</td>
                <td>{data.name}</td>
                <td>{fileSize(data.size)}</td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Button
                      variant='outline-primary'
                      onClick={() => openImageModal(data)}
                    >
                      <i className='fas fa-eye'></i>
                      {' Show image'}
                    </Button>
                    <Button
                      variant='outline-danger'
                      onClick={() => removeFile(data.name, i)}
                    >
                      <i className='fas fa-times-circle'></i> {' Delete image'}
                    </Button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

const baseStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  zIndex: '1',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  //borderStyle: "dashed",
  //borderColor: "#2196f3",
}

// const acceptStyle = {
//   borderColor: "#00e676",
// };

// const rejectStyle = {
//   borderColor: "#ff1744",
// };
