import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { 
  connect, 
  // useStore 
} from "react-redux";
import { activateLink } from "../../actions/orders";

function LinkActivation({ link_activated, activateLink }) {
  let { id, object_type } = useParams();

  useEffect(() => {
    async function activator() {
      await activateLink(id);
    }
    activator();
  }, [activateLink, id]);

  const linkRender = () => {
    let returnDiv;
    console.log('Link', link_activated)
    switch (link_activated) {
      case 'expired':
        returnDiv = (
          <div>
          Links are only valid for 72 hours from creation<br/>
          Your link has expired, ask for a new one!
          </div>
        )
        break;
      case false:
        returnDiv = <div>There is something wrong with your link!</div>
        break;
      case null:
        returnDiv = <div>Activating link...</div>
        break;
      default:
        returnDiv = (<div>
          Link with {id} has been activated!
          <br/>
          {object_type === "Order" ? (
            <Link to={"/analysis/"+link_activated}>Click here to view the order</Link>
          ) : (
            <Link to={"/my-forests"}>Click here to view the forest</Link>
          )}
        </div>)
        break;
    };
    return returnDiv;
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {linkRender()}
      {/* {link_activated !== null ? (
        link_activated !== false ? (
          <div>
            Link with {id} has been activated!
            <br/>
            {object_type === "Order" ? (
              <Link to={"/analysis/"+link_activated}>Click here to view the order</Link>
            ) : (
              <Link to={"/my-forests"}>Click here to view the forest</Link>
            )}
          </div>
        ) : (
          <div>There is something wrong with your link!</div>
        )
      ) : (
        <div>Activating link...</div>
      )} */}
    </div>
  );
};

// Maps the state from redux to the props
const mapStateToProps = (state) => ({
  link_activated: state.orders.link_activated,
});

export default connect(mapStateToProps, {
  activateLink,
})(LinkActivation);
