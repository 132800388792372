import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  NEW_PASSWORD_REQUIRED,
  NEW_PASSWORD_SUCCESS,
} from "../actions/types";

const initialState = {
  jwtToken: null,
  payload: null,
  isAuthenticated: null,
  emailSend: null,
  isLoading: false,
  user: null,
  newPasswordRequired: false,
  session: null,
  newUserEmail: null
};

export default function authReducer (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: null,
      };
    case USER_LOADED:
      var payload = null
      payload = action.payload
      // console.log(payload)
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: payload[1],
        payload: payload,
        jwtToken: payload[0].IdToken,
      };
    case LOGIN_SUCCESS:
      // console.log(action.payload)
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload[1],
        payload: payload,
        jwtToken: action.payload[0].IdToken,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        emailSend: true,
        isLoading: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        newPasswordRequired: true,
        session: action.payload['session'],
        newUserEmail: action.payload['email'],
        isLoading: false,
        isAuthenticated: false,
      }
    case NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload[1],
        payload: payload,
        jwtToken: action.payload[0].IdToken,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        jwtToken: null,
        payload: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    default:
      return state;
  }
}
