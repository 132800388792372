import {
  DELETE_IMAGE,
  UPDATE_IMAGES,
  DELETE_ALL_IMAGES,
  GET_ALL_IMAGES,
  ADD_IMAGE,
  ADD_COORDS,
} from "../actions/types.js";

const initialState = {
  images: [],
  coords: [],
};

export default function dropZoneReducer (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_IMAGES:
      return {
        ...state,
      };
    case DELETE_ALL_IMAGES:
      return {
        ...state,
        images: [],
        coords: [],
      };
    case DELETE_IMAGE:
      return {
        images: state.images.filter(item => item !== state.images[action.payload]),
        coords: state.coords.filter(item => item !== state.coords[action.payload]),
      };
    case UPDATE_IMAGES:
      return {
        ...state,
        images: [...action.payload],
      };
    case ADD_IMAGE:
      return {
        ...state,
        images: [...state.images, action.payload],
      };
    case ADD_COORDS:
      return {
        ...state,
        coords: [...state.coords, ...action.payload],
      };
    default:
      return state;
  }
}
