import {
  ROOT_GROUP,
  USERS_IN_GROUP_AND_SUBGROUP,
  USERS_IN_ROOT,
  USER_GROUPS,
} from "../actions/types";

const initialState = {
  users: [],
  groups: [],
  root: [],
  usersInRoot: [],
};

export default function adminReducer(state = initialState, action) {
  // console.log(2)
  switch (action.type) {
    case USERS_IN_GROUP_AND_SUBGROUP:
      return {
        ...state,
        users: state.users.concat(action.payload),
      };
    case USER_GROUPS:
      // console.log(action.payload);
      return {
        ...state,
        // groups: state.groups.concat(action.payload),
        groups: action.payload,
      };
    case ROOT_GROUP:
      return {
        ...state,
        root: action.payload,
      };
    case USERS_IN_ROOT:
      return {
        ...state,
        usersInRoot: action.payload,
      };
    default:
      return state;
  }
}
