import React, { useState } from "react";
import { Modal } from "react-modal-overlay";
import 'react-modal-overlay/dist/index.css'
// import {
  // useHistory,
  // useLocation
// } from "react-router-dom";
import css from "../../styles/MyForests.module.css";
import "../../styles/AdminSubpages.css";
import { FormControl} from 'react-bootstrap';


export default function AddGroupModal({
  // data,
  addTeamFunc,
  getUserFunc,
  rootGroup
  }) {
  const [isOpen, setIsOpen] = useState(false);
  const [teamName, setTeamName] = useState("New Team");

  // const history = useHistory();
  // const location = useLocation();

  // const goToTeams = () => history.push('teams');

  return (
    <div>
       <div>
        <button onClick={() => setIsOpen(true)} className={css.btnAdmin} >
          Create a New Team
        </button>
      </div>
      <Modal show={isOpen} closeModal={() => setIsOpen(false)}>
        <h4> Enter the team name </h4>
        <FormControl
            type="text"
            value={teamName.value}
            onChange={(e) => {
                setTeamName(e.target.value)}
            }
        />
        <br></br>
        <h6> You can add team members after the team is created </h6>
        <button style={{ marginTop: 10 }} className={css.btnThreeMF} 
                onClick={() => {
                    console.log("send new team to db", teamName); 
                    addTeamFunc(teamName, rootGroup).then(() => {
                      //goToTeams();
                      getUserFunc(rootGroup,1)
                      setIsOpen(false);

                    
                    })
                    
                }}> 
                Add Team 
                </button>
      </Modal>
    </div>
  );
}

