import React from 'react';

export default function HereBeDragons() {
    return (
        <div style={{height:"100%", justifyContent:"center", textAlign:"center"}}>
            <img src="https://media.giphy.com/media/U1eYjTgjUrlN6/giphy.gif" alt="description of gif" /> 
            <img src="https://media.giphy.com/media/Zb0asRm15HqCbgShD4/giphy.gif" alt="description of gif" /> 
            <img src="https://media.giphy.com/media/YaooEfKWBBD68/giphy.gif" alt="description of gif" /> 
            <img src="https://media.giphy.com/media/cYHS8R8MePKYYTV6L2/giphy.gif" alt="description of gif" /> 
        </div>
    )
}
