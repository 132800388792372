// store imports
import { createMessage } from "./messages";

// Send Notification
export const sendNotification = (message) => (dispatch) => {
  dispatch(createMessage({ customSuccess: message }));
};

export const sendErrorNotification = (message) => (dispatch) => {
  dispatch(createMessage({customError: message}));
};
