import { 
    USER_PROFILE_LOADED, 
    USER_PROFILE_FAILED, 
    USER_PROFILE_UPDATED,
    USER_STATS,
  } from "../actions/types";
  
  const initialState = {
    userProfile: null,
    userStats: null,
  };
  
  export default function userPageReducer (state = initialState, action) {
    switch (action.type) {
      case USER_PROFILE_LOADED:
        return {
          userProfile: action.payload,
        };
      //NEW
      case USER_PROFILE_UPDATED:
        return {
          ...state,
          userProfile: action.payload,
        }
      case USER_PROFILE_FAILED:
        return {
          userProfile: null,
        };
      case USER_STATS:
        return {
          ...state,
          userStats: action.payload,
        }
      default:
        return state;
    }
  }
  