// any actions are going to be here
import axios from "axios";

import {
    SHAPEFILE_LINK,
    TILESET_LINK
} from "./types";

import { tokenConfig } from "./auth";
const ip = process.env.REACT_APP_BACKEND_DNS;

export const getZipFile = (map, generateFeatureCollection, orderID) => async (dispatch, getState) => {
    // Created with:
    // https://developers.arcgis.com/javascript/latest/sample-code/layers-featurelayer-shapefile/

    let form_data = new FormData();
    form_data.append("order", orderID);
    form_data.append('optional', null)
    return await axios
        .post(`${ip}/shp_link/`, form_data, tokenConfig(getState), {
            headers: {
                "Content-Type": "application/octet-stream",
            },
            responseType: "text/html",
        })
        .then(async (res) => {
            console.log(res)
            return await axios
                .get(res.data, {
                    headers: {
                        "Content-Type": "application/octet-stream",
                    },
                    responseType: "blob",
                })
                .then((res) => {
                    const formData = new FormData();
                    formData.append("file", res.data);
                    generateFeatureCollection("shapefilezip", formData, map);
                    dispatch({
                        type: SHAPEFILE_LINK,
                        payload: res.data
                    });
                    return res;
                });
        })
        .catch((err) => {
            console.log("Error getting shapefile");
            console.log(err);
            return err;
        });
};

export const getZipFileLeafleet = (orderID, opt=null) => async (dispatch, getState) => {
    let form_data = new FormData();
    form_data.append("order", orderID);
    form_data.append("optional", opt);
    return await axios
        .post(`${ip}/shp_link/`, form_data, tokenConfig(getState), {
            headers: {
                "Content-Type": "application/octet-stream",
            },
            responseType: "text/html",
        })
        .then(async (res) => {
            if ( res.isAxiosError ) {
                throw res;
            };
            return await axios
                .get(res.data, {
                    headers: {
                        "Content-Type": "application/octet-stream",
                    },
                    responseType: "blob",
                })
                .then(async (res) => {
                    dispatch({
                        type: SHAPEFILE_LINK,
                        payload: res.data
                    });
                    return res;
                })
                .catch((err) => {
                    console.log("Error getting shapefile blob");
                    console.log(err);
                    return err;
                });
        })
        .catch((err) => {
            console.log("Error getting shapefile");
            console.log(err);
            return err;
        });
};

export const getTilesetLink = (orderID, z, x, y) => async (dispatch, getState) => {
    // console.log('URL', url)
    // console.log(form_data)
    return await axios
        .get(`${ip}/tileset_link/${orderID}/${z}/${x}/${y}/`, tokenConfig(getState), {
            headers: {
                "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
        })
        .then(async (res) => {
            if ( res.isAxiosError ) {
                throw res;
            };
            dispatch({
                type: TILESET_LINK,
                payload: res.data
            });
            return res.data;
        })
        .catch((err) => {
            console.log("Error getting tileset");
            console.log(err.response);
            return err;
        });
};