import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  useHistory,
  useLocation,
} from "react-router-dom";
import DeleteGroupModal from "../modals/DeleteGroupModal"

const options = [
  //'Add Team Member',
  //'Add sub-team',
  'Delete Team',
];

const ITEM_HEIGHT = 48;

export default function LongMenu({
  group,
  delTeamFunc,
  getUserFunc,
  rootGroup
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  
  const handleDeleteGroupConfirm = () => {
    delTeamFunc(group).then(() => {
      getUserFunc(rootGroup,1)

      if (location.pathname.length !== "/teams") {        
        goToTeams()
      } else {
      hideDeleteModal()
      }
    })
  };

  const history = useHistory();
  const location = useLocation();
  const goToTeams = () => {
    history.replace('/teams');
  }

  return (
    <div class="float-right">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}

        >

        <MoreVertIcon />

        </IconButton>
        
        
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '25ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} 
                    onClick={() => {
                    // Handle the option selected
                    switch(option) {
                        // case options[0]: //add team member
                        //     console.log(options[0])
                        //     break;
                        // case options[1]: //add sub-group/team
                        //     console.log(options[1])
                        //     break;

                        //TODO: select by string ; ie, not order of array
                        case options[0]: //delete
                            handleShowDeleteModal()
                            
                            // send request to data.sentData.teamsData.deleteGroup
                            break;
                            
                        default:
                            console.log('default')
                        }
                    setAnchorEl(null);
          }}>
            {option}
          </MenuItem>
        ))}
      </Menu>
      <DeleteGroupModal 
      showDeleteGroupModal={showDeleteModal}
      handleDeleteGroupClose={hideDeleteModal}
      handleDeleteGroupConfirm={handleDeleteGroupConfirm}
      groupName={group}
      />
    </div>
  );
}
