import React, { Component, useState } from "react";
import { connect } from "react-redux";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  HashRouter as Router,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  addUserToGroup,
  changeUserRole,
  createGroup,
  deleteGroup,
  getRootGroup,
  getUsers,
  getUsersInRoot,
  removeUserFromGroup,
} from "../../actions/admin";
import css from "../../styles/MyForests.module.css";
import AddNewGroupModal from "../modals/AddNewGroupModal";
import UsersToGroup from "../modals/UsersToGroupModal";
import TeamsMenu from "../small/TeamsMenu";
import UserMenu from "../small/UserMenu";
import Dragons from "../subpages/HereBeDragons";
import UserDetails from "../subpages/UserDetails";
// import GroupAddSharpIcon from '@mui/icons-material/GroupAddSharp';

function UserInfo({ userData }) {
  // const { teamId, subId } = useParams();
  const { subId } = useParams();
  let user = userData.find((tmp_user) => tmp_user.user.username === subId);
  return (
    <div class=" pt-5 bg-light ">
      <h2 class="text-center">{user.user.hr_username}</h2>
      <div class="row text-center mt-5">
        <ul class="col-md-4">
          <p>{user.user.given_name}Name</p>
          <p>{user.user.famliy_name}Surrname</p>
          <p>{user.role}Role</p>
        </ul>
        <ul class="col-md-4">
          <p>{user.user.hectars} Hectars</p>
          <p>{user.user.hectars_used} Hectars_used</p>
          <p>{user.user.hectars_in_use} Hectars_in_use</p>
        </ul>
        <ul class="col-md-4">
          <p>{user.user.num_of_orders}Num_of_orders</p>
          <p>{user.user.hectars_in_use}</p>
        </ul>
        <div className={css.fillerBox}></div>
      </div>
    </div>
  );
}

function Team({
  teamsData,
  changeUserRole,
  removeUserFromGroup,
  // getUser,
  // state,
  // allData,
  addUserToGroup,
  getUsersInRoot,
}) {
  const { teamId } = useParams();
  const { url, path } = useRouteMatch();

  const [currentGroup, changeCurrentGroup] = useState();
  const [usersInRoot, changeUsersInRoot] = useState([]);

  const team = teamsData.find(({ group }) => group === teamId);
  console.log(team);
  //fixes refreshing problems,
  //TODO: fix a better redirect back into specific user/teams?
  if (!team) {
    //https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    return <Redirect to="/teams" />;
  }

  if (team.group !== currentGroup) {
    changeCurrentGroup(team.group);
    getUsersInRoot(team.group).then((res) => {
      // console.log(res)
      changeUsersInRoot(res);
    });
  }
  const users = team.users;

  // useEffect(() => {
  //   console.log('loaded')
  //   getUsersInRoot(team.group).then((res) => {
  //     // console.log(res)
  //     changeUsersInRoot(res)
  //   });
  // }, [changeUsersInRoot]);

  //

  return (
    <div class=" pt-5 bg-light">
      <div>
        <UsersToGroup
          // usersInRoot={allData.usersInRoot}
          usersInRoot={usersInRoot}
          // usersInRoot={orgUsers}
          addUserToGroup={addUserToGroup}
          teamGroup={team.group}
        ></UsersToGroup>
        <h2 class="text-center">{team.group}</h2>
      </div>

      <ul class="list-group pt-5 m-5">
        {users.map((user) => (
          <li class="list-group-item" key={user.username}>
            <div>
              <Link to={`${url}/${user.user.username}`}>
                {user.user.hr_username}
              </Link>{" "}
              &nbsp;&nbsp; {user.role}
              <UserMenu
                changeUserRole={changeUserRole}
                removeUserFromGroup={removeUserFromGroup}
                user={user.user}
                group={team.group}
                role={user.role}
              ></UserMenu>
            </div>
          </li>
        ))}
      </ul>

      <hr />

      <Route path={`${path}/:subId`}>
        <UserInfo userData={users}></UserInfo>
      </Route>
    </div>
  );
}

function Teams({
  allData,
  teamsData,
  changeUserRole,
  removeUserFromGroup,
  deleteGroup,
  createGroup,
  getUser,
  rootGroup,
  state,
  addUserToGroup,
  getUsersInRoot,
}) {
  const { url, path } = useRouteMatch();
  return (
    <div class=" mt-5 h-100 bg-light">
      <div>
        <AddNewGroupModal
          data={teamsData}
          addTeamFunc={createGroup}
          getUserFunc={getUser}
          rootGroup={rootGroup}
        ></AddNewGroupModal>
        <h1 class="text-center">Teams</h1>
      </div>
      <ul class="list-unstyled row justify-content-center pt-5">
        {teamsData.map(({ group, users }) => (
          <>
            {!group.includes("Order") ? (
              <li class="list-group-item col-5 m-1 border " key={group}>
                <div>
                  <Link to={`${url}/${group}`}>{group}</Link>
                  <TeamsMenu
                    group={group}
                    delTeamFunc={deleteGroup}
                    getUserFunc={getUser}
                    rootGroup={rootGroup}
                  ></TeamsMenu>
                </div>
              </li>
            ) : (
              " "
            )}
          </>
        ))}
      </ul>
      <hr />
      <Route path={`${path}/:teamId`}>
        <Team
          teamsData={teamsData}
          changeUserRole={changeUserRole}
          removeUserFromGroup={removeUserFromGroup}
          getUser={getUser}
          state={state}
          allData={allData}
          addUserToGroup={addUserToGroup}
          getUsersInRoot={getUsersInRoot}
        />
      </Route>
    </div>
  );
}

class Admin extends Component {
  state = {
    redirect: null,
    rootGroup: null,
    usersInRoot: null,
  };

  componentDidMount() {
    this.props.getRootGroup().then(async (groups) => {
      console.log(groups);
      for (let index = 0; index < groups.length; index++) {
        const group = groups[index];
        await this.props.getUsersInRoot(group).then((res) => {
          this.setState({ usersInRoot: res });
        });

        this.setState({
          rootGroup: group,
        });
        this.props.getUsers(group, 1);
      }
    });
  }

  render() {
    return (
      <div className={css.contentMF}>
        <Router>
          <div class="container overflow-auto bg-light h-100 pt-5 w-100">
            <div>
              <nav>
                <ul class="list-group list-group-horizontal justify-content-center ">
                  <li
                    classname="adminLinks"
                    class="list-group-item flex-fill text-center"
                  >
                    <NavLink to="/">User Details</NavLink>
                  </li>
                  <li class="list-group-item flex-fill text-center">
                    <NavLink to="/teams">Team Managment</NavLink>
                  </li>
                  {/* <li class="list-group-item flex-fill text-center" ><NavLink to='/dragons'>Dragons</NavLink></li> */}
                </ul>
              </nav>
            </div>
            <div>
              <Route exact path="/">
                <UserDetails
                  groups={this.props.users}
                  {...this.props}
                  state={this.state}
                />
              </Route>
              <Route path="/teams">
                <Teams
                  allData={this.props}
                  teamsData={this.props.users}
                  changeUserRole={this.props.changeUserRole}
                  removeUserFromGroup={this.props.removeUserFromGroup}
                  deleteGroup={this.props.deleteGroup}
                  createGroup={this.props.createGroup}
                  getUser={this.props.getUsers}
                  rootGroup={this.props.groups}
                  state={this.state}
                  addUserToGroup={this.props.addUserToGroup}
                  getUsersInRoot={this.props.getUsersInRoot}
                />
              </Route>
              <Route path="/dragons">
                <Dragons />
              </Route>
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.admin.users,
  groups: state.admin.groups,
  // usersInRoot: state.admin.usersInRoot
});

export default connect(mapStateToProps, {
  getUsers,
  createGroup,
  deleteGroup,
  addUserToGroup,
  removeUserFromGroup,
  changeUserRole,
  getRootGroup,
  getUsersInRoot,
})(Admin);
