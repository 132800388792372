import {
  GET_FORESTS,
  GET_FOREST,
  DELETE_FOREST,
  ADD_FOREST,
  CHANGE_FOREST_PAGE,
  CLEAR_FORESTS,
  FOREST_STATS,
  FOREST_ANALYSES,
} from "../actions/types.js";

const initialState = {
  forests: [],
  forest_detail: null,
  current_forest_id: null,
  current_page: 0,
  tot_pages: 0,
  stats: {'dragons': 0},
  analyses: [],
};

export default function myForestReducer (state = initialState, action) {
  //console.log("Action payload");
  //console.log(action.payload);
  switch (action.type) {
    case GET_FORESTS:
      return {
        ...state,
        forests: action.payload[0],
        tot_pages: action.payload[1],
      };
    case CLEAR_FORESTS:
      return {
        ...state,
        forest_detail: null,
        current_forest_id: null,
      };
    case GET_FOREST:
      return {
        ...state,
        forest_detail: action.payload,
      };
    case CHANGE_FOREST_PAGE:
      return {
        ...state,
        current_page: action.payload[0],
        forests: action.payload[1],
        tot_pages: action.payload[2],
      };
    case DELETE_FOREST:
      return {
        ...state,
        FORESTS: state.forests.filter((forest) => forest.id !== action.payload),
      };
    case ADD_FOREST:
      return {
        ...state,
        forests: [...state.forests, action.payload],
        current_forest_id: action.payload.id
      };
    case FOREST_STATS:
      return {
        ...state,
        stats: action.payload,
      };
    case FOREST_ANALYSES:
      return {
        ...state,
        analyses: action.payload,
      };
    default:
      return state;
  }
}
