// any actions are going to be here
import axios from "axios";

import {
  GET_KML_LINK,
  GET_MOSAIC_LINK,
  GET_REPORT_LINK,
  GET_SHP_LINK,
  GET_TREE_COUNT_LINK,
} from "./types";

import { tokenConfig } from "./auth";
const ip = process.env.REACT_APP_BACKEND_DNS;

const download = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

// Get SHP link
export const getShpLink =
  (orderID, name, optional = null) =>
  async (dispatch, getState) => {
    let form_data = new FormData();
    form_data.append("order", orderID);
    form_data.append("optional", optional);
    // console.log(form_data)
    return await axios
      .post(`${ip}/shp_link/`, form_data, tokenConfig(getState))
      .then(async (res) => {
        if (res.status === 200) {
          let resDataSplit = res.data.split("/").pop();
          let fileName = resDataSplit.split("?")[0];
          console.log(fileName);
          return await axios
            .get(res.data, {
              headers: {
                "Content-type": "application/octet-stream",
              },
              responseType: "blob",
            })
            .then((res) => {
              const blob = new Blob([res.data]);
              download(blob, name + fileName);
              dispatch({
                type: GET_SHP_LINK,
                payload: res.data,
              });
              return res;
            });
        } else {
          let returnValue = [];
          returnValue.isAxiosError = false;
          return returnValue;
        }
      })
      .catch((err) => {
        console.log("Error getting shapefile link");
        console.log(err);
        return err;
      });
  };

// Get MOSAIC link
export const getMosaicLink = (orderID, name) => async (dispatch, getState) => {
  let form_data = new FormData();
  form_data.append("order", orderID);
  return await axios
    .post(`${ip}/mosaic_link/`, form_data, tokenConfig(getState))
    .then(async (res) => {
      console.log(res);
      if (res.status === 200) {
        return await axios
          .get(res.data, {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          })
          .then((res) => {
            const blob = new Blob([res.data]);
            download(blob, name + "mosaic.tif");
            dispatch({
              type: GET_MOSAIC_LINK,
              payload: res.data,
            });
            return res;
          });
      } else {
        let returnValue = [];
        returnValue.isAxiosError = false;
        return returnValue;
      }
    })
    .catch((err) => {
      console.log("Error getting mosaic link");
      console.log(err);
      return err;
    });
};

// Get MOSAIC Leaflet
export const getMosaicLinkLeaflet = (orderID) => async (dispatch, getState) => {
  let form_data = new FormData();
  form_data.append("order", orderID);
  return await axios
    .post(`${ip}/mosaic_link/`, form_data, tokenConfig(getState))
    .then((res) => {
      if (res.status === 200) {
        console.log("Mosaic count link gotten");
        // showRaster(res.data)
        dispatch({
          type: GET_MOSAIC_LINK,
          payload: res.data,
        });
        return res;
      } else {
        let returnValue = [];
        returnValue.isAxiosError = false;
        return returnValue;
      }
    })
    .catch((err) => {
      console.log("Error getting mosaic link");
      console.log(err);
      return err;
    });
};

export const getTreeCountLinkLeaflet =
  (orderID) => async (dispatch, getState) => {
    return await axios
      .get(
        `${ip}/tree_count_link/?order_id=${orderID}`,
        tokenConfig(getState),
        {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "text/html",
        }
      )
      .then(async (res) => {
        if (res.status === 200) {
          return await axios
            .get(res.data, {
              headers: {
                "Content-Type": "application/octet-stream",
              },
              responseType: "blob",
            })
            .then(async (res) => {
              dispatch({
                type: GET_TREE_COUNT_LINK,
                payload: res.data,
              });
              return res;
            })
            .catch((err) => {
              console.log("Error getting tileset blob");
              console.log(err);
              return err;
            });
        } else {
          let returnValue = [];
          returnValue.isAxiosError = false;
          return returnValue;
        }
      })
      .catch((err) => {
        console.log("Error getting tree count link");
        console.log(err);
        return err;
      });
  };

export const getReportLink = (orderID, name) => async (dispatch, getState) => {
  let form_data = new FormData();
  form_data.append("order", orderID);
  return await axios
    .post(`${ip}/report_link/`, form_data, tokenConfig(getState))
    .then(async (res) => {
      if (res.status === 200) {
        return await axios
          .get(res.data, {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          })
          .then((res) => {
            const blob = new Blob([res.data]);
            download(blob, name + "report.pdf");
            dispatch({
              type: GET_REPORT_LINK,
              payload: res.data,
            });
            return res;
          })
          .catch((err) => {
            console.log(err);
            return err;
          });
      } else {
        let returnValue = [];
        returnValue.isAxiosError = false;
        return returnValue;
      }
    })
    .catch((err) => {
      console.log("Error getting report link");
      console.log(err);
      return err;
    });
};

export const getKMLLink = (orderID, name) => async (dispatch, getState) => {
  let form_data = new FormData();
  form_data.append("order", orderID);
  return await axios
    .post(`${ip}/kml_link/`, form_data, tokenConfig(getState))
    .then(async (res) => {
      if (res.status === 200) {
        return await axios
          .get(res.data, {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          })
          .then((res) => {
            // saveAs(res.data, name + "damaged_spruce.kml");

            const blob = new Blob([res.data]);
            download(blob, name + "damaged_spruce.kml");
            dispatch({
              type: GET_KML_LINK,
              payload: res.data,
            });
            return res;
          });
      } else {
        let returnValue = [];
        returnValue.isAxiosError = false;
        return returnValue;
      }
    })
    .catch((err) => {
      console.log("Error getting kml link");
      console.log(err);
      return err;
    });
};

export const getTreeCountLink =
  (orderID, name) => async (dispatch, getState) => {
    return await axios
      .get(`${ip}/tree_count_link/?order_id=${orderID}`, tokenConfig(getState))
      .then(async (res) => {
        if (res.status === 200) {
          let resDataSplit = res.data.split("/").pop();
          let fileName = resDataSplit.split("?")[0];
          console.log(fileName);
          return await axios
            .get(res.data, {
              headers: {
                "Content-type": "application/octet-stream",
              },
              responseType: "blob",
            })
            .then((res) => {
              const blob = new Blob([res.data]);
              download(blob, name + fileName);
              dispatch({
                type: GET_TREE_COUNT_LINK,
                payload: res.data,
              });
              return res;
            });
        } else if (res.isAxiosError) {
          throw res;
        } else {
          let returnValue = [];
          returnValue.isAxiosError = false;
          return returnValue;
        }
      })
      .catch((err) => {
        console.log("Error getting shapefile link");
        console.log(err);
        return err;
      });
  };
